import DietInfoStore from "./dietInfoStore";
import { createContext } from "react";
import { configure } from "mobx";
import UserStore from "./userStore";
import CommonStore from "./commonStore";
import ModalStore from "./modalStore";
import ClientStore from "./clientStore";
import { EventStore } from "./eventStore";
import ProgressStore from "./progressStore";
import ResponsiveStore from "./responsiveStore";

configure({ enforceActions: 'always' });

export class RootStore {
    dietInfoStore: DietInfoStore;
    userStore: UserStore;
    commonStore: CommonStore;
    modalStore: ModalStore;
    clientStore: ClientStore;
    eventStore: EventStore;
    progressStore: ProgressStore;
    responsiveStore: ResponsiveStore;

    constructor () {
        this.dietInfoStore = new DietInfoStore(this);
        this.userStore = new UserStore(this);
        this.commonStore = new CommonStore(this);
        this.modalStore = new ModalStore(this);
        this.clientStore = new ClientStore(this);
        this.eventStore = new EventStore(this);
        this.progressStore = new ProgressStore(this);
        this.responsiveStore = new ResponsiveStore(this);
    }
}

export const RootStoreContext = createContext(new RootStore());