import React from 'react'
import { observer } from 'mobx-react-lite';
import { Segment, Label, Form } from 'semantic-ui-react';
import { Field } from 'react-final-form';
import TextInput from '../../../common/form/TextInput';


interface IProps {
    isReadOnly: boolean;
}

const GirthMeasurements: React.FC<IProps> = ({ isReadOnly }) => {

    return (
        <Segment raised color='red'>
            <Label content='Girth Measurements' ribbon color='blue' />
            <Form.Group widths='equal' style={{ marginTop: 20 }}>
                <Field
                    component={TextInput}
                    name='girthMeasurement.neck'
                    label='Neck'
                    placeholder='In cms'
                    maxlength={5}
                    readOnly={isReadOnly}
                />
                <Field
                    component={TextInput}
                    name='girthMeasurement.shoulder'
                    label='Shoulder'
                    placeholder='In cms'
                    maxlength={5}
                    readOnly={isReadOnly}
                />
                <Field
                    component={TextInput}
                    name='girthMeasurement.chest'
                    label='Chest'
                    placeholder='In cms'
                    maxlength={5}
                    readOnly={isReadOnly}
                />
                <Field
                    component={TextInput}
                    name='girthMeasurement.upperArm'
                    label='Upper Arm'
                    placeholder='In cms'
                    maxlength={5}
                    readOnly={isReadOnly}
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Field
                    component={TextInput}
                    name='girthMeasurement.waist'
                    label='Waist'
                    placeholder='In cms'
                    maxlength={5}
                    readOnly={isReadOnly}
                />
                <Field
                    component={TextInput}
                    name='girthMeasurement.hip'
                    label='Hip'
                    placeholder='In cms'
                    maxlength={5}
                    readOnly={isReadOnly}
                />
                <Field
                    component={TextInput}
                    name='girthMeasurement.thigh'
                    label='Thigh'
                    placeholder='In cms'
                    maxlength={5}
                    readOnly={isReadOnly}
                />
                <Field
                    component={TextInput}
                    name='girthMeasurement.calf'
                    label='Calf'
                    placeholder='In cms'
                    maxlength={5}
                    readOnly={isReadOnly}
                />
            </Form.Group>
        </Segment>
    )
}

export default observer(GirthMeasurements);
