import React, { Fragment, useContext, useState, useEffect } from 'react'
import { Message, Button, Item, Divider, Modal, Icon, Label, Grid } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/rootStore";
import { Link, RouteComponentProps } from 'react-router-dom';
import PhotoUploadWidget from '../../common/photoUpload/PhotoUploadWidget';
import agent from '../../api/agent';
import { IDashboard } from '../../models/dashboard.model';
import alertify from '../../common/util/alertify';
import LoadingComponent from '../../layout/LoadingComponent';
import DietStatistics from './DietStatistics';
import DietForTheDay from './DietForTheDay';
import { Desktop } from '../../layout/Responsive';
import AdminDashboard from './AdminDashboard';
import { IAdminDashboard } from '../../models/admin-dashboard.model';
import SecurityQuestionsPage from '../user/SecurityQuestionsPage';
import { useMediaQuery } from 'react-responsive';
import { MAX_DEVICE_WIDTH } from '../../common/util/util';


const DashboardPage: React.FC<RouteComponentProps> = ({ history }) => {

    const rootStore = useContext(RootStoreContext);
    const { user, uploadPhoto, uploadingPhoto, deletePhoto, deletingPhoto, isUserClient, userId } = rootStore.userStore;
    const { openModal } = rootStore.modalStore;
    const { toggleSideMenu } = rootStore.responsiveStore;

    const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: MAX_DEVICE_WIDTH });

    const initDashboard: IDashboard = {};

    const [modalState, setModalState] = useState(false);
    const [dashboardData, setDashboardData] = useState(initDashboard);
    const [loadingDashboard, setLoadingDashboard] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState(false);
    const [adminDashboard, setAdminDashboard] = useState([] as IAdminDashboard[]);

    const handleUploadImage = (photo: Blob) => {
        uploadPhoto(photo).then(() => setModalState(false));
    };

    const deleteImage = (id: string) => {
        setDeleteModalState(false);
        deletePhoto(id);
    }

    useEffect(() => {
        toggleSideMenu(false);
        setLoadingDashboard(true);

        if (isUserClient) {

            agent.Client.dashboard(new Date().toLocaleDateString('en-US'))
                .then(ddResp => {
                    setDashboardData(ddResp);
                    setLoadingDashboard(false);
                })
                .catch(err => {
                    alertify.error('Problem occurred while fetching the Dashboard');
                })
                .finally(() => setLoadingDashboard(false));
        } else {
            agent.Client.adminDashboard()
                .then(dResp => {
                    setAdminDashboard(dResp);
                }).catch(err => {
                    alertify.error('Problem occurred while fetching the Dashboard');
                })
                .finally(() => setLoadingDashboard(false));
        }
    }, [isUserClient, toggleSideMenu])

    if (loadingDashboard) return <LoadingComponent content='Please wait...' />

    return (
        <Fragment>
            <Desktop>
                <Item.Group>
                    <Item>
                        <Item.Image
                            size='small'
                            src={user!.imageUrl || '/assets/user.png'}
                            circular
                            bordered
                        ></Item.Image>
                        <Item.Content verticalAlign='middle'>
                            <Item.Header as='h1' content={`Hello ${user?.displayName}!`} style={{ fontSize: 40 }} />
                            <Item.Meta>
                                {!deletingPhoto && <Button content={user?.imageId ? 'Change Image' : 'Upload Image'} size='mini' positive onClick={() => setModalState(true)} />}
                                {user?.imageId && <Button content='Delete Image' size='mini' negative onClick={() => setDeleteModalState(true)} loading={deletingPhoto} />}
                            </Item.Meta>
                        </Item.Content>
                    </Item>
                </Item.Group>
                {isUserClient && <Divider />}
            </Desktop>
            
            {isUserClient && dashboardData.dietStatistics &&
                <Fragment>
                    <DietStatistics dietStatistics={dashboardData.dietStatistics!} />
                    {dashboardData.dietForTheDay &&
                        <DietForTheDay dietForTheDay={dashboardData.dietForTheDay!} instructions={dashboardData.dietStatistics?.instructions!} generalInstructions={dashboardData.dietStatistics?.generalInstructions!} />
                    }
                </Fragment>
            }

            <Fragment>
                <Grid>
                    {
                        !user?.isProfileCompleted &&
                        <Grid.Column width={isTabletOrMobileDevice ? 16 : 8}>
                            <Message
                                icon='user'
                                header="Looks like you haven't set your profile yet"
                                content='Click the below button and to set your profile'
                            />
                            <Button content='Set Profile' color='blue' fluid={isTabletOrMobileDevice ? true : false} as={Link} to={'/profile'} />
                        </Grid.Column>
                    }
                    {
                        !user?.isSecurityQuestionSet &&
                        <Grid.Column width={isTabletOrMobileDevice ? 16 : 8}>
                            <Message
                                icon='lock'
                                header="Password Hint"
                                content='Set your security questions for Password reset'
                            />
                            <Button 
                                content='Set Question' 
                                color='blue' 
                                fluid={isTabletOrMobileDevice ? true : false} 
                                onClick={() => isTabletOrMobileDevice ? history.push(`/security-question/${userId}`) : openModal(<SecurityQuestionsPage />, 'small')} 
                                floated='right' 
                            />
                        </Grid.Column>
                    }
                </Grid>
            </Fragment>
            <br/>
            {
                isUserClient && !dashboardData.dietStatistics && !dashboardData.dietForTheDay && user?.isProfileCompleted && user?.isSecurityQuestionSet && <Message
                    icon='food'
                    header='You do not have any Diet assigned!'
                    content='Please check with your nutritionist for more details'
                />
            }
            {
                !isUserClient && <AdminDashboard dashboardData={adminDashboard} />
            }

            <Modal closeOnDimmerClick={false} closeOnEscape={false} open={modalState}>
                <Modal.Header>
                    Select a Photo <Label content='Only .jpg or .png allowed' style={{ float: 'right' }}></Label>
                </Modal.Header>
                <Modal.Content image>
                    <Modal.Description>
                        <PhotoUploadWidget
                            uploadPhoto={handleUploadImage}
                            loading={uploadingPhoto} />
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button content='Cancel' onClick={() => setModalState(false)} secondary />
                </Modal.Actions>
            </Modal>
            <Modal closeOnDimmerClick={false} closeOnEscape={false} open={deleteModalState} size='mini'>
                <Modal.Header>
                    Confirm Deletion
                </Modal.Header>
                <Modal.Content>
                    <p><Icon name='question circle outline' size='huge' style={{ marginRight: 10 }} />Delete your Profile Image?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={() => setDeleteModalState(false)} content='No' />
                    <Button positive onClick={() => deleteImage(user!.imageId!)} content='Yes' />
                </Modal.Actions>
            </Modal>

        </Fragment>
    )
}

export default observer(DashboardPage);
