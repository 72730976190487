import { RootStore } from "./rootStore";
import { observable, action, computed, runInAction } from "mobx";
import { IResult, IProgressTracker } from "../models/progress-tracker.model";
import { differenceInYears } from "date-fns";
import agent from "../api/agent";
import alertify from "../common/util/alertify";

export default class ProgressStore {
  rootStore: RootStore;

  @observable progressDate: Date | null = null;
  @observable skinfoldSum: number = 0;
  @observable result: IResult = {
    bodyFatPercentage: "0",
    leanBodyMass: "0",
    fatBodyMass: "0",
  };
  @observable skinFoldParamsMale: string[] = ["chest", "abdominals", "thigh"];
  @observable skinFoldParamsFemale: string[] = ["tricep", "suprailiac", "thigh"];
  @observable loadingTrackProgress: boolean = false;
  @observable postingProgressData: boolean = false;
  @observable deletingProgress: boolean = false;
  @observable progressTracker: IProgressTracker = {};


  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @computed get clientAge(): number | undefined {
    const clientProfile = this.rootStore.clientStore.clientProfile;
    return clientProfile?.dateOfBirth ? differenceInYears(new Date(), new Date(clientProfile.dateOfBirth)) : 0;
  }

  @action updateSkinfoldSum = (sum: number) => {
    this.skinfoldSum = sum;
  };

  @action updateBodyFatResult = (
    bodyFat: string,
    leanBodyMass: string,
    fatBodyMass: string
  ) => {
    this.result = {
      bodyFatPercentage: bodyFat,
      leanBodyMass: leanBodyMass,
      fatBodyMass: fatBodyMass,
    };
  };

  @action updateAge = (age: number) => {
    this.progressTracker.age = age;
  };

  @action addProgress = async (assignedDietInfoId: string, trackForUserId: string, forDate: string, age: number, values: IProgressTracker) => {
    this.postingProgressData = true;
    const progressTrackId = this.rootStore.eventStore.getProgressTrackId(forDate);
    
    const progress = {
      id: progressTrackId,
      assignedDietInfoId: assignedDietInfoId,
      trackForUserId: trackForUserId,
      forDate: forDate,
      age: age,
      girthMeasurement: values.girthMeasurement,
      skinfold: {...values.skinfold, skinfoldSum: this.skinfoldSum},
      result: {
        ...values.result,
        bodyFatPercentage: this.result.bodyFatPercentage,
        leanBodyMass: this.result.leanBodyMass,
        fatBodyMass: this.result.fatBodyMass,
      },
      recoveryAssessment: values.recoveryAssessment,
      comments: values.comments,
    };

    try {
      const trackId = await agent.Client.trackProgress(progress);

      runInAction(() => {
        this.postingProgressData = false
        if (trackId && !progressTrackId) {
          this.rootStore.eventStore.addProgressEvent([{forDate: forDate, id: trackId}]);
        }

        if (values.result?.weightInKg) {
          this.rootStore.clientStore.setClientWeight(+values.result?.weightInKg);
        }
      });
    } catch (error) {
      alertify.error('Problem occurred while saving Progress data');
      runInAction(() => {
        this.postingProgressData = false
      });
    }
    
  };

  @action getProgressTrackData = async (id: string) => {
    this.loadingTrackProgress = true;

    try {
      const progress = await agent.Client.getProgressTrack(id);

      runInAction(() => {
        this.loadingTrackProgress = false;
        this.progressTracker = progress;
        this.skinfoldSum = progress.skinfold?.skinfoldSum!;
        this.result = {...progress.result};
      });
    } catch (error) {
      alertify.error('Problem occurred while getting Progress data');
      runInAction(() => {
        this.loadingTrackProgress = false;
      });
    }
  };

  @action deleteProgress = async (date: string) => {
    this.deletingProgress = true;

    try {
      const trackId = this.rootStore.eventStore.progressTrackRegistry.get(date);

      if (trackId) {
        await agent.Client.deleteProgressTrack(trackId);
      }

      runInAction(() => {
        this.deletingProgress = false;
        this.rootStore.eventStore.progressTrackRegistry.delete(date);
        this.rootStore.eventStore.deleteProgressEvent(date);
      })
    } catch (error) {
      alertify.error('Problem occurred while deleting Progress data');
      runInAction(() => {
        this.deletingProgress = false;
      })
    }
  }

  @action progressCleanup = () => {
    this.skinfoldSum = 0;
    this.result = {
      bodyFatPercentage: "0",
      leanBodyMass: "0",
      fatBodyMass: "0",
    };
    this.progressTracker = {};
  };
}
