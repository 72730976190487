import React, { Fragment } from 'react'
import { observer } from 'mobx-react-lite';
import RatingInput from '../../../common/form/RatingInput';
import { Field } from 'react-final-form';
import { Segment, Label, Form } from 'semantic-ui-react';

interface IProps {
    isReadOnly: boolean;
}

const RecoveryAssessment: React.FC<IProps> = ({ isReadOnly }) => {

    return (
        <Fragment>
            <Segment raised color='red'>
                <Label content='Recovery Assessment' ribbon color='blue' />
                <Form.Group widths='equal' style={{ marginTop: 20 }}>
                    <Field
                        component={RatingInput}
                        name='recoveryAssessment.moodQuality'
                        label='Mood Quality'
                        maxRating={5}
                        disabled={isReadOnly}
                    />
                    <Field
                        component={RatingInput}
                        name='recoveryAssessment.appetite'
                        label='Appetite (0 - No Appetite / 5 - Very Hungry)'
                        maxRating={5}
                        disabled={isReadOnly}
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Field
                        component={RatingInput}
                        name='recoveryAssessment.sleepQuality'
                        label='Sleep Quality (0 - Poor Sleep / 5 - Very good sleep)'
                        maxRating={5}
                        disabled={isReadOnly}
                    />
                    <Field
                        component={RatingInput}
                        name='recoveryAssessment.tiredness'
                        label='Tiredness (0 - Not Tired / 5 - Very tired)'
                        maxRating={5}
                        disabled={isReadOnly}
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Field
                        component={RatingInput}
                        name='recoveryAssessment.willingnessToTrain'
                        label='Willingness to Train (0 - Not willing / 5 - Very excited to train)'
                        maxRating={5}
                        disabled={isReadOnly}
                    />
                </Form.Group>

            </Segment>
        </Fragment>
    )
}

export default observer(RecoveryAssessment);
