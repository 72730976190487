import React, { useEffect, useState, Fragment, useContext } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import agent from '../../../api/agent'
import LoadingComponent from '../../../layout/LoadingComponent';
import ClientProfile from './ClientProfile';
import { IClientHistory } from '../../../models/client-history.model';
import DietHistory from './DietHistory';
import { Segment, Label, Message, Button } from 'semantic-ui-react';
import { RootStoreContext } from '../../../stores/rootStore';
import CompareProgressPage from '../compare/CompareProgressPage';
import { IProgressTrackBasicData } from '../../../models/progress-tracker.model';

interface HistoryParams {
    id: string
}

const HistoryPage: React.FC<RouteComponentProps<HistoryParams>> = ({ match }) => {

    const [clientHistory, setClientHistory] = useState({} as IClientHistory);
    const [loadingHistory, setLoadingHistory] = useState(false);

    const rootStoreContext = useContext(RootStoreContext);
    const { openModal } = rootStoreContext.modalStore;

    useEffect(() => {
        setLoadingHistory(true);
        if (match.params.id) {
            agent.Client.history(match.params.id)
                .then(resp => {
                    setClientHistory(resp);
                })
                .finally(() => setLoadingHistory(false));
        }
    }, [match.params.id])

    const showCompareProgress = (progressTracks: IProgressTrackBasicData[]) => {
        openModal(<CompareProgressPage progressTracks={progressTracks} />, 'large');
    }

    if (loadingHistory) return <LoadingComponent content='Loading Client History ...' />

    return (
        <Fragment>
            <ClientProfile clientInfo={clientHistory.userInfo} clientProfile={clientHistory.userProfile} />

            <Segment raised>
                <Label content='Diet History' ribbon color='blue' />
                {
                    clientHistory.progressDates?.length > 0 && <Button
                        onClick={() => showCompareProgress(clientHistory.progressDates)}
                        content='Progress History'
                        color='instagram'
                        floated='right'
                        size='tiny'
                    />
                }

                <br /><br />
                {
                    clientHistory.diets?.length > 0 && clientHistory.diets.map((diet, index) => <DietHistory key={index} diet={diet} />)
                }
                {
                    clientHistory.diets?.length === 0 && <Message
                        icon='clock outline'
                        header="There is no diet history available"
                    />
                }
            </Segment>
        </Fragment>
    )
}

export default HistoryPage;
