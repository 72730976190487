import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Button, Form, Header, Label, Segment } from "semantic-ui-react";
import { Form as FinalForm, Field } from 'react-final-form';
import TextInput from "../../common/form/TextInput";
import { SelectInput } from "../../common/form/SelectInput";
import { SecurityQuestions } from "../../common/data/security-questions";
import { combineValidators, isRequired } from "revalidate";
import { useMediaQuery } from 'react-responsive';
import { Desktop, Mobile } from '../../layout/Responsive';
import { RootStoreContext } from '../../stores/rootStore';
import agent from '../../api/agent';
import LoadingComponent from '../../layout/LoadingComponent';
import { observer } from 'mobx-react-lite';
import { MAX_DEVICE_WIDTH } from '../../common/util/util';


const validate = combineValidators({
    securityQuestionId: isRequired('Question'),
    answer: isRequired('Answer')
});

const SecurityQuestionsPage = () => {

    const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: MAX_DEVICE_WIDTH });
    const rootStore = useContext(RootStoreContext);
    
    const { setSecurityQuestion, submittingSecQuestionRequest } = rootStore.userStore;
    const { toggleSideMenu } = rootStore.responsiveStore;
    const { closeModal } = rootStore.modalStore;
    const [fetchingQuestion, setFetchingQuestion] = useState(false);
    const [securityQuestionInit, setSecurityQuestionInit] = useState({ securityQuestionId: null, answer: null });

    const submitForm = () => <FinalForm
        validate={validate}
        initialValues={securityQuestionInit}
        onSubmit={(values) => { setSecurityQuestion(values).finally(closeModal) }}
        render={({ handleSubmit, submitSucceeded, submitting }) => (
            <Form onSubmit={handleSubmit} error>
                <br />
                <Field
                    name='securityQuestionId'
                    component={SelectInput}
                    placeholder='Security Question'
                    options={SecurityQuestions.map(ques => ({ text: ques.text, value: ques.value }))}
                    label='Choose a Security question'
                    disabled={submitSucceeded}
                />
                <Field
                    name='answer'
                    component={TextInput}
                    placeholder='Enter Security Answer'
                    type='password'
                    label='Security Answer'
                    disabled={submitSucceeded}
                />
                <Button color='blue' fluid={isTabletOrMobileDevice ? true : false} loading={submittingSecQuestionRequest} content='Submit' disabled={submitSucceeded} />
                {!isTabletOrMobileDevice && <Button secondary content='Close' type='button' onClick={closeModal} />}
            </Form>
        )}
    />

    const getSecurityQuestion = () => {
        setFetchingQuestion(true)
        agent.User.getSecurityQuestion()
            .then((resp: any) => {
                setSecurityQuestionInit(resp);
            })
            .finally(() => setFetchingQuestion(false));
    }

    useEffect(() => {
        if (isTabletOrMobileDevice) {
            toggleSideMenu(false);
        }

        getSecurityQuestion();

    }, [isTabletOrMobileDevice, toggleSideMenu])

    if (fetchingQuestion) return <div style={{ padding: 30 }}><LoadingComponent content='Loading ...' /></div>

    return (
        <Fragment>
            <Desktop>
                <Segment raised>
                    <Label ribbon content='Set Security Question' color='black' />
                    {submitForm()}
                </Segment>
            </Desktop>
            <Mobile>
                <Header as='h3' dividing content='Set Security Question' />
                {submitForm()}
            </Mobile>
        </Fragment>

    )
}

export default observer(SecurityQuestionsPage);