import React, { Fragment } from 'react'
import { Grid } from 'semantic-ui-react';
import { Field } from 'react-final-form';
import RichMealInput from './RichMealInput';


interface IProps {
    day: string;
    meal: string;
}

const Condition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (value === is ? children : null)}
    </Field>
)

const DietTab: React.FC<IProps> = ({ day, meal }) => {

    const dayMeal = `${day.toLowerCase()}.${meal.toLowerCase()}`;

    const mealKey = `${dayMeal}.mainMeal`;
    const snackNeededKey = `${dayMeal}.isSnackNeeded`;
    const snackKey = `${dayMeal}.snack`;

    return (
        <Fragment>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <RichMealInput mealKey={mealKey} label={meal} />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Condition when={snackNeededKey} is={true}>
                            <RichMealInput mealKey={snackKey} label='Snack' />
                        </Condition>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={2}>
                        <Field
                            name={snackNeededKey}
                            component='input'
                            type='checkbox'
                        >
                            {props => (
                                <div className="ui checkbox">
                                    <input {...props.input} />
                                    <label>Snack?</label>
                                </div>
                            )}
                        </Field>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        </Fragment>
    )
}

export default DietTab;