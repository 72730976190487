import React, { Fragment } from 'react'
import { Segment, Statistic, Label, Message, Grid } from 'semantic-ui-react'

interface IProps {
    bmi: string;
    standard: string;
    isClient: boolean;
}

const BodyMassIndex: React.FC<IProps> = ({ bmi, standard, isClient }) => {

    return (
        <Fragment>
            <Segment raised clearing>
                <Label content='Body Mass Index' ribbon color='black' />
                <br /><br />
                <Grid>
                    <Grid.Column width={8}>
                        <Message style={{ marginTop: 20 }}>
                            <Message.Header>
                                BMI Standard: {!isClient ? `${standard}` : 'Healthy Range 18.5 - 24.9'}
                            </Message.Header>
                        </Message>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Message.Content>
                            <Statistic style={{ marginRight: 30 }} floated='right'>
                                <Statistic.Label>{isClient ? 'Your Current BMI' : 'Calculated BMI'}</Statistic.Label>
                                <Statistic.Value>{bmi}</Statistic.Value>
                            </Statistic>
                        </Message.Content>
                    </Grid.Column>
                </Grid>
            </Segment>
        </Fragment>
    )
}

export default BodyMassIndex;
