import React, { Fragment, useContext } from 'react'
import { Form, Grid, Header } from 'semantic-ui-react';
import { Field } from 'react-final-form';
import RadioInput from '../../../common/form/RadioInput';
import { Condition } from '../details/AssignDiet';
import { TextAreaInput } from '../../../common/form/TextAreaInput';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { RootStoreContext } from '../../../stores/rootStore';
import { useMediaQuery } from 'react-responsive';
import { MAX_DEVICE_WIDTH } from '../../../common/util/util';
import { SelectInput } from '../../../common/form/SelectInput';

const required = isCheatMeal => value => {
    return value ? undefined : isCheatMeal ? 'Please select' : 'Please enter the reason';
};

interface IProps {
    mealType: string;
    mealName: string;
    validateComment: boolean;
    displayCheatMeal: boolean;
    validateCheatMeal: boolean;
    clearCheatMeal?: (e) => any;
}

const MealStatus: React.FC<IProps> = ({ mealType, mealName, validateComment, displayCheatMeal, validateCheatMeal, clearCheatMeal }) => {
    const rootStoreContext = useContext(RootStoreContext);
    const { isUserClient } = rootStoreContext.userStore;

    const mealStatusKey = `${mealType}.status`;
    const mealStatusComment = `${mealType}.comment`;
    const mealStatusIsCheatMeal = `${mealType}.isCheatMeal`;

    const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: MAX_DEVICE_WIDTH });

    return (
        <Fragment>
            <Grid>
                <Grid.Column width={isTabletOrMobileDevice ? 8 : 3}>
                    <Header content={_.startCase(mealName)} as='h4' icon='food' />
                </Grid.Column>
                <Grid.Column width={isTabletOrMobileDevice ? 16 : 13}>
                    <Form.Group inline widths='equal'>
                        <Field name={mealStatusKey} component={RadioInput} value="compliant" label='Compliant' type='radio' disabled={!isUserClient} inputOnChange={clearCheatMeal} />
                        <Field name={mealStatusKey} component={RadioInput} value="noncompliant" label='Noncompliant' type='radio' disabled={!isUserClient} inputOnChange={clearCheatMeal}/>
                        <Field name={mealStatusKey} component={RadioInput} value="missed" label='Missed meal' type='radio' disabled={!isUserClient} inputOnChange={clearCheatMeal} />
                    </Form.Group>
                    <Condition when={mealStatusKey} is={'noncompliant'}>
                        <Fragment>
                            {displayCheatMeal &&
                                <Field
                                    name={mealStatusIsCheatMeal}
                                    component={SelectInput}
                                    label='Was this a cheat meal?'
                                    placeholder='Choose Yes or No'
                                    width={8}
                                    validate={validateCheatMeal ? required(true) : undefined}
                                    options={[{ text: 'No', value: 'No' }, { text: 'Yes', value: 'Yes' }]}
                                    disabled={!isUserClient}
                                    
                                />
                            }
                            <Field
                                name={mealStatusComment}
                                label='Reason for being noncompliant? (Max 200 characters)'
                                component={TextAreaInput}
                                maxlength={200}
                                rows={2}
                                validate={validateComment ? required(false) : undefined}
                                readOnly={!isUserClient}
                            />
                        </Fragment>
                    </Condition>
                </Grid.Column>
            </Grid>
        </Fragment >
    )
}

export default observer(MealStatus);
