import React, { Fragment } from 'react'
import queryString from 'query-string';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Form as FinalForm, Field } from 'react-final-form';
import { combineValidators, isRequired, composeValidators, matchesField, hasLengthBetween } from 'revalidate';
import { passwordComplexity } from '../../common/util/validators';
import { Button, Form, Grid, Header } from 'semantic-ui-react';
import TextInput from '../../common/form/TextInput';
import { Desktop, Mobile } from '../../layout/Responsive';
import { IPasswordResetVerification } from '../../models/user.model';
import agent from '../../api/agent';
import alertify from '../../common/util/alertify';
import { ErrorMessage } from '../../common/form/ErrorMessage';
import { FORM_ERROR } from 'final-form';

const validate = combineValidators({
    newPassword: composeValidators(isRequired, hasLengthBetween(8, 20), passwordComplexity)('Password'),
    confirmPassword: composeValidators(isRequired, matchesField('newPassword', 'Password')({ message: 'Should match with Password' }))('Confirm Password')
});

const ResetPasswordPage: React.FC<RouteComponentProps> = ({ location, history }) => {

    const { token, email } = queryString.parse(location.search);

    const resetPassword = (newPassword, confirmPassword) => {
        const request: IPasswordResetVerification = { token: token as string, email: email as string, newPassword, confirmPassword };
        return agent.User.resetPasswordVerification(request);
    }

    const passwordSubmitForm = () => {
        return <FinalForm
            validate={validate}
            onSubmit={(values) => resetPassword(values.newPassword, values.confirmPassword).then(() => { alertify.success('Password has been reset'); history.push('/') }).catch(error => ({
                [FORM_ERROR]: error
            }))}
            render={({ handleSubmit, pristine, submitError, submitting, submitFailed }) => (
                <Form onSubmit={handleSubmit} error autoComplete='off'>
                    <Header as='h2' content='Reset Password' color='blue' textAlign='center' />
                    <Field
                        name='newPassword'
                        component={TextInput}
                        placeholder='New Password'
                        type='password'
                        maxlength={20}
                        label='New Password'
                    />
                    <Field
                        name='confirmPassword'
                        component={TextInput}
                        placeholder='Confirm Password'
                        type='password'
                        maxlength={20}
                        label='Confirm Password'
                    />

                    <br />
                    {submitError && <ErrorMessage error={submitError} text='Uh-oh!' />}
                    <Button primary content='Submit' loading={submitting} disabled={pristine || submitFailed} />
                    <Button secondary content='Cancel' as={Link} to='/' />
                </Form>
            )}
        />
    }

    return (
        <Fragment>
            <Desktop>
                <Grid>
                    <Grid.Column width={5} />
                    <Grid.Column width={6}>
                        {passwordSubmitForm()}
                    </Grid.Column>
                </Grid>
            </Desktop>
            <Mobile>
                {passwordSubmitForm()}
            </Mobile>
        </Fragment>
    )
}

export default ResetPasswordPage;
