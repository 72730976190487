import React, { Fragment, useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/rootStore';
import FullCalendar, { DateSelectArg, EventContentArg, EventClickArg } from '@fullcalendar/react';
import TrackStatus from './TrackStatus';
import { Header, Segment, Label } from 'semantic-ui-react';
import { addDays, differenceInCalendarDays } from 'date-fns';
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import ProgressTrackerPage from '../progress/ProgressTrackerPage';
import alertify from '../../../common/util/alertify';
import { formatToTimeZone } from 'date-fns-timezone';
import { DEFAULT_TIME_ZONE } from '../../../common/util/util';


const Tracker = () => {

    const rootStoreContext = useContext(RootStoreContext);
    const { assignedDiet, loadingDietAssignment } = rootStoreContext.clientStore;
    const { openModal } = rootStoreContext.modalStore;
    const { setDateSelected, events, getEventsForDate, eventsCleanUp } = rootStoreContext.eventStore;
    const { isUserClient } = rootStoreContext.userStore;


    const handleDateSelect = (selectInfo: DateSelectArg) => {
        if (selectInfo.start <= new Date()) {
            setDateSelected(selectInfo.start!);
            getEventsForDate(selectInfo.start!);
            const weekDay = selectInfo.start!.toLocaleString('en-US', { weekday: 'long' });
            openModal(<TrackStatus day={weekDay} assignedDietInfoId={assignedDiet.id!} noOfCheatMeals={assignedDiet.noOfCheatMeals!} />, 'small');
        } else {
            alertify.error(`You cannot ${isUserClient ? 'record' : 'view'} diet status for a future date`);
        }

    }

    const renderEventContent = (eventContent: EventContentArg) => {
        return <Header size='tiny' style={{ textAlign: 'center', color: 'white', cursor: 'pointer' }}>
            {eventContent.event.title}
        </Header>
    }

    const handleEventClick = (clickInfo: EventClickArg) => {
        if (clickInfo.event.title !== 'Progress') {
            setDateSelected(clickInfo.event.start!);
            getEventsForDate(clickInfo.event.start!);
            const weekDay = clickInfo.event.start!.toLocaleString('en-US', { weekday: 'long' });
            openModal(<TrackStatus day={weekDay} assignedDietInfoId={assignedDiet.id!} noOfCheatMeals={assignedDiet.noOfCheatMeals!} />, 'small');
        } else {
            openModal(<ProgressTrackerPage forDate={clickInfo.event.start!} />, 'small');
        }
    }

    useEffect(() => {
        return () => {
            eventsCleanUp()
        }
    }, [eventsCleanUp])


    return (
        <Fragment>
            <Segment color='red' raised loading={loadingDietAssignment}>
                <Label color='blue' ribbon>
                    Diet Tracker
                    </Label>
                {
                    assignedDiet.startDate && assignedDiet.endDate && <Label color='violet' style={{ float: 'right' }}>
                        Number of Days
                         <Label.Detail>{differenceInCalendarDays(new Date(assignedDiet.endDate), new Date(assignedDiet.startDate)) + 1}</Label.Detail>
                    </Label>
                }
                <br /><br />
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "",
                    }}
                    initialView="dayGridMonth"
                    validRange={() => ({
                        start: new Date(formatToTimeZone(assignedDiet.startDate!, 'MM/DD/YYYY', { timeZone: DEFAULT_TIME_ZONE })),
                        end: new Date(addDays(new Date(formatToTimeZone(assignedDiet.endDate!, 'MM/DD/YYYY', { timeZone: DEFAULT_TIME_ZONE })), 1))
                    })}
                    events={events.slice()}
                    eventOrder={['start']}
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    weekends={true}
                    select={handleDateSelect}
                    eventContent={renderEventContent}
                    eventClick={handleEventClick}
                />
            </Segment>
        </Fragment>
    )
}

export default observer(Tracker);
