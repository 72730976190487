import React, { Fragment } from 'react'
import { Segment, Label, Message } from 'semantic-ui-react';
import { displayFullDate } from '../../../common/util/util';

interface IProps {
    reasons: { [key: string]: { name: string; comment: string, isCheatMeal: boolean }[] }
}

const NoncomplianceReasons: React.FC<IProps> = ({ reasons }) => {
    return (
        <Fragment>
            {
                Object.keys(reasons).map(key => (
                    <Segment key={key}>
                        <Label content={displayFullDate(new Date(key))} ribbon color='black' />
                        {
                            reasons[key].map((meal, index) => (
                                <Message key={`${key}_${index}`}>
                                    <Message.Content>
                                        <Message.Header>
                                            {meal.name}{meal.isCheatMeal && <Label color='red' size='mini' style={{ marginLeft: 10 }}>Cheat meal</Label> }                           
                                        </Message.Header>
                                        {meal.comment}
                                    </Message.Content>
                                </Message>
                            ))
                        }
                    </Segment>
                ))
            }
        </Fragment>
    );
}

export default NoncomplianceReasons;
