import React, { useContext, Fragment, useEffect, useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../stores/rootStore';
import LoadingComponent from '../../../layout/LoadingComponent';
import { Header, Button, Label, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import DataTable from 'react-data-table-component';

const ListDietsPage = () => {

    const rootStoreContext = useContext(RootStoreContext);
    const { dietList, getAllDiets, clearDietList, generateReport, isGeneratingReport } = rootStoreContext.dietInfoStore;
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = dietList && dietList!.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));

    const [activatingTarget, setActivatingTarget] = useState<string | undefined>(undefined);

    useEffect(() => {
        getAllDiets();

        return (() => {
            clearDietList();
        })
    }, [getAllDiets, clearDietList])

    const FilterComponent = ({ filterText, onFilter, onClear }) => (
        <>
            <Input placeholder='Search...' value={filterText} autoFocus onChange={onFilter} action={
                <Button content='Reset' onClick={onClear} />
            } />
        </>
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    const columns = [
        {
            name: <h4>Edit/View</h4>,
            cell: diet => <div>
                <Button circular icon='edit' primary as={Link} to={`/edit-diet/${diet.id}`} />
                <Button circular icon='eye' as={Link} to={`/diet/${diet.id}`} />
                <Button 
                    circular
                    secondary
                    name={diet.id}
                    icon='download' 
                    onClick={e => {
                        generateReport(diet.id);
                        setActivatingTarget(e.currentTarget.name);
                    }}
                    loading={isGeneratingReport && activatingTarget === diet.id}
                />
            </div>,
            sortable: false,
            center: true,
            width: '15%'
        },
        {
            name: <h4>Diet Details</h4>,
            selector: 'name',
            cell: diet => <Header as='h5' image>
                <Header.Content>
                    {diet.name}
                    <Header.Subheader>{diet.description}</Header.Subheader>
                </Header.Content>
            </Header>,
            sortable: true,
            right: false
        },
        {
            name: <h4>Updated Date</h4>,
            selector: 'dateUpdated',
            cell: diet => <Label basic>{format(new Date(diet.dateUpdated), 'MM/dd/yyyy h:mm a')}</Label>,
            sortable: true,
            center: true,
            width: '20%'
        },
    ];

    if (!dietList) {
        return <LoadingComponent content='Loading Diets...' />
    }

    return (
        <Fragment>
            <Header
                as='h2'
                content='Diets'
                subheader='Manage your Diet Information by choosing actions'
                dividing
                icon='food'
            />
            {dietList && <DataTable
                columns={columns}
                data={filteredItems!}
                noHeader={true}
                pagination={true}
                striped
                responsive={true}
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
            />}
        </Fragment>
    )
}

export default observer(ListDietsPage);
