import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormFieldProps, Form, Label, Rating } from 'semantic-ui-react'


interface IProps extends FieldRenderProps<string, HTMLElement>, FormFieldProps { }

const RatingInput: React.FC<IProps> = ({input, width, type, meta: { touched, error }, label, maxRating, icon, disabled }) => {
    return (
        <Form.Field error={touched && !!error} type={type} width={width}>
            {label && <label>{label}</label>}
            <Rating 
                icon={icon ? icon : 'star'}  
                defaultRating={input.value} 
                maxRating={maxRating} 
                onRate={(e, { rating, maxRating}) => input.onChange(rating)}
                clearable
                disabled={disabled}
            />
            {touched && error && (
                <Label basic color='red' pointing='above'>{error}</Label>
            )}
        </Form.Field>
    )
}

export default RatingInput;
