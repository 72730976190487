import { RootStore } from "./rootStore";
import { observable, action, reaction } from "mobx";

export default class CommonStore {
    rootStore: RootStore;

    constructor (rootStore: RootStore) {
        this.rootStore = rootStore;

        reaction(
            () => this.token,
            token => {
                if(token) {
                    window.sessionStorage.setItem('jwt', token);
                } else {
                    window.sessionStorage.removeItem('jwt');
                }
            }
        )

        reaction(
            () => this.refreshToken,
            refreshToken => {
                if(refreshToken) {
                    window.sessionStorage.setItem('refreshToken', refreshToken);
                } else {
                    window.sessionStorage.removeItem('refreshToken');
                }
            }
        )
    }

    @observable token: string | null = window.sessionStorage.getItem('jwt');
    @observable refreshToken: string | null = window.sessionStorage.getItem('refreshToken');
    @observable appLoaded = false;

    @action setToken = (token: string | null) => {
        this.token = token;
    }

    @action setRefreshToken = (refreshToken: string | null) => {
        this.refreshToken = refreshToken;
    }

    @action setAppLoaded = () => {
        this.appLoaded = true;
    }

    @action scrollToTop = () => {
        window.scrollTo(0, 0);
    }
}