import React, { Fragment, useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Label, Segment, Header, Divider, Confirm } from 'semantic-ui-react';
import { RootStoreContext } from '../../../stores/rootStore';
import { format } from 'date-fns';
import MealStatus from './MealStatus';
import { ITrackMeals } from '../../../models/track-meals.model';
import { combineValidators, isRequired } from 'revalidate';
import { SelectInput } from '../../../common/form/SelectInput';
import { calculateLiters } from '../../../common/util/util';


interface IProps {
    day: string;
    assignedDietInfoId: string;
    noOfCheatMeals: number;
}

const validate = combineValidators({
    waterIntake: isRequired({ message: 'Please choose the amount of water consumed' })
});

const glasses = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

const TrackStatus: React.FC<IProps> = ({ day, assignedDietInfoId, noOfCheatMeals }) => {

    const rootStore = useContext(RootStoreContext);

    const {
        selectedDate,
        addEvent,
        currentSelectedDiet,
        clearCurrentSelectedDiet,
        savingDietStatus,
        hasDietStatus,
        deleteLoggedDietStatus,
        deletingDietStatus
    } = rootStore.eventStore;

    const { closeModal } = rootStore.modalStore;
    const { mealsInDietForWeek } = rootStore.clientStore;
    const { isUserClient } = rootStore.userStore;

    const initMeals: string[] = [];
    const [mealsForDay, setMealsForDay] = useState(initMeals);
    const [openConfirm, setOpenConfirm] = useState(false);

    useEffect(() => {
        const meals = mealsInDietForWeek.filter(meal => meal.day === day.toLowerCase());

        if (meals.length > 0) {
            const aMeals = [...meals[0].mealNames];
            setMealsForDay(aMeals);
        }

        return () => {
            clearCurrentSelectedDiet();
        }
    }, [clearCurrentSelectedDiet, setMealsForDay, day, mealsInDietForWeek])

    const submitStatus = (values: ITrackMeals) => {
        addEvent(assignedDietInfoId, selectedDate!, values).finally(() => {
            closeModal();
        });
    }

    const clearCheatMeal = ([name], state, { changeValue }) => {
        const isCheatMealKey = `${name.key.split('.')[0]}.isCheatMeal`;
        const commentKey = `${name.key.split('.')[0]}.comment`;
        if (name.value !== 'noncompliant') {
            changeValue(state, isCheatMealKey, () => undefined)
            changeValue(state, commentKey, () => undefined)
        }
    }


    return (
        <Fragment>
            <Segment raised>
                <Label ribbon='right' content='Record Diet Compliance' color='violet' />
                <br />
                {selectedDate && <Header as='h3' content={`${format(selectedDate, 'MMM, dd yyyy')}`} subheader={selectedDate.toLocaleString('en-US', { weekday: 'long' })}></Header>}
                <Divider />
                <FinalForm
                    onSubmit={submitStatus}
                    validate={validate}
                    initialValues={currentSelectedDiet}
                    mutators={{ clearCheatMeal }}
                    render={({ handleSubmit, values, form }) => (
                        <Form onSubmit={handleSubmit} autoComplete='off'>
                            <Segment color='blue' raised>
                                <Form.Group inline>
                                    <Field
                                        name='waterIntake'
                                        component={SelectInput}
                                        label={isUserClient ? 'How many glasses of water did you have today?' : 'Total water intake'}
                                        maxlength={2}
                                        options={glasses.map(c => ({ key: c, text: c, value: c }))}
                                        placeholder='Select One'
                                        disabled={!isUserClient}
                                        inline
                                    />
                                    {
                                        +values.waterIntake! > 0 &&
                                        <Label
                                            tag
                                            content={calculateLiters(values.waterIntake!)}
                                            color={values.waterIntake! < 8 ? 'red' : 'green'}
                                            style={{ marginLeft: 0 }}
                                        />
                                    }
                                </Form.Group>
                            </Segment>
                            <br />
                            {
                                mealsForDay && mealsForDay.map(meal => (
                                    <MealStatus
                                        key={meal}
                                        mealType={meal.replace(' ', '').toLowerCase()}
                                        mealName={meal} validateComment={values[meal.replace(' ', '').toLowerCase()]?.status === 'noncompliant'}
                                        displayCheatMeal={noOfCheatMeals > 0}
                                        validateCheatMeal={values[meal.replace(' ', '').toLowerCase()]?.status === 'noncompliant' && values[meal.replace(' ', '').toLowerCase()]?.isCheatMeal === undefined}
                                        clearCheatMeal={form.mutators.clearCheatMeal}
                                    />
                                ))
                            }
                            <br />
                            {mealsForDay.length === 0 &&
                                <Fragment>
                                    <Label content='No diet assigned for this day!' color='red' size='medium' />
                                    <br /><br />
                                </Fragment>
                            }
                            {isUserClient &&
                                <Fragment>
                                    <Button positive content='Submit' loading={savingDietStatus} />
                                    <Button content='Close' type='button' onClick={closeModal} />
                                </Fragment>
                            }

                            {isUserClient && hasDietStatus &&
                                <Fragment>
                                    <Button negative content='Delete' type='button' loading={deletingDietStatus} onClick={() => setOpenConfirm(true)} />
                                    <Confirm
                                        header='Delete Diet Status'
                                        content='Are you sure you want to delete the diet status?'
                                        open={openConfirm}
                                        cancelButton='No'
                                        confirmButton="Yes"
                                        onCancel={() => setOpenConfirm(false)}
                                        onConfirm={() => { setOpenConfirm(false); deleteLoggedDietStatus() }}
                                        size='mini'
                                    />
                                </Fragment>
                            }
                        </Form>
                    )}
                />
            </Segment>
        </Fragment>
    )
}

export default observer(TrackStatus);
