import { RootStore } from "./rootStore";
import { observable, action, runInAction, computed, reaction } from "mobx";
import { subDays, addDays, eachDayOfInterval, format } from "date-fns";
import alertify from "../common/util/alertify";
import agent from "../api/agent";
import { IMealInDay } from "../models/meals-in-day.model";
import { ITrackDietStatus, IMealStatus } from "../models/track-diet-status.model";
import { ITrackMeals } from "../models/track-meals.model";
import uuid from 'react-uuid';
import { formatToTimeZone } from 'date-fns-timezone';
import { DEFAULT_TIME_ZONE } from "../common/util/util";

export default class ResponsiveStore {
  rootStore: RootStore;

  @observable isSideMenuVisible: boolean = false;
  @observable pastDate: Date = subDays(new Date(), 2);
  @observable futureDate: Date = addDays(new Date(), 2);
  @observable selectedDate: Date = new Date();
  @observable selectedIndex: number = 2;
  @observable fetchingDiet: boolean = false;
  @observable assignedDietInfoId: string | null = null;
  @observable mealsForWeek: IMealInDay[] = [];
  @observable trackDietStatus: ITrackDietStatus[] = [];
  @observable mealsForDay: string[] = [];
  @observable dietStartDate: Date | null = null;
  @observable dietEndDate: Date | null = null;
  @observable disableStepForward: boolean = false;
  @observable disableStepBackward: boolean = false;
  @observable disableLeapForward: boolean = false;
  @observable disableLeapBackward: boolean = false;
  @observable currentSelectedDiet: ITrackMeals = { id: uuid() };
  @observable statusRegistry: Map<string, {id: string, waterIntake: number, status: any}> = new Map();
  @observable savingDietStatus: boolean = false;
  @observable deletingDietStatus: boolean = false;
  @observable noOfCheatMeals: number = 0;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    reaction(
      () => this.hasDietStatus,
      hasDietStatus => {
          if(!hasDietStatus) {
              this.currentSelectedDiet = { id: uuid() };
          } 
      }
  )

  }

  @computed get hasDietStatus() {
    const hasDietStatus = this.statusRegistry.has(this.selectedDate!.toLocaleDateString('en-US'));
    return hasDietStatus;
  }

  @computed get datesSubmitted() {
    return Array.from(this.statusRegistry.keys());
  }

  @action toggleSideMenu = (visible?: boolean) => {
    this.isSideMenuVisible =
      visible !== undefined ? visible : !this.isSideMenuVisible;
  };

  @action moveForward = () => {
    this.pastDate = addDays(this.pastDate, 1);
    this.futureDate = addDays(this.futureDate, 1);
    this.setSelectedDate(this.selectedIndex);
  };

  @action moveBackward = () => {
    this.pastDate = subDays(this.pastDate, 1);
    this.futureDate = subDays(this.futureDate, 1);
    this.setSelectedDate(this.selectedIndex);
  };

  @action leapBackward = () => {
    this.pastDate = subDays(this.pastDate, 5);
    this.futureDate = subDays(this.futureDate, 5);
    this.setSelectedDate(this.selectedIndex);
  };

  @action leapForward = () => {
    const currentDate = new Date(new Date().toLocaleDateString('en-US'));

    if (this.futureDate < currentDate) {
      this.pastDate = addDays(this.pastDate, 5);
      this.futureDate = addDays(this.futureDate, 5);
    } else {
      this.futureDate = new Date();
      this.pastDate = subDays(this.futureDate, 4);
    }

    this.setSelectedDate(this.selectedIndex);
  };

  @action setTabIndex = (index: number) => {
    this.selectedIndex = index;
    this.setSelectedDate(index);
  };

  @action setSelectedDate = (index: number) => {
    if (this.assignedDietInfoId) {
      const dietStartDate = new Date(this.dietStartDate!.toLocaleDateString('en-US'));
      const dietEndDate = new Date(this.dietEndDate!.toLocaleDateString('en-US'));
      const localFutureDate = new Date(this.futureDate.toLocaleDateString('en-US'));
      const localPastDate = new Date(this.pastDate.toLocaleDateString('en-US'));
      
      const selectedDate = new Date(eachDayOfInterval({
        start: this.pastDate,
        end: this.futureDate,
      })[index].toLocaleDateString('en-US'));

      this.disableStepForward = this.disableLeapForward = dietEndDate <= localFutureDate || localFutureDate > new Date();
      this.disableStepBackward = this.disableLeapBackward = dietStartDate >= localPastDate;

      if (this.disableLeapBackward) {
        this.selectedDate = selectedDate < dietStartDate ? dietStartDate : selectedDate;
      }
      
      if (this.disableLeapForward) {
        this.selectedDate = selectedDate > dietEndDate ? dietEndDate : selectedDate; 
      }
      else {
        this.selectedDate = selectedDate > new Date() ? new Date() : selectedDate;
      }

      if (this.selectedDate > new Date(new Date().toLocaleDateString('en-US'))) {
        this.selectedDate = new Date(new Date().toLocaleDateString('en-US'));
      }

      if (this.selectedDate < new Date(this.dietStartDate!.toLocaleDateString('en-US'))) {
        this.selectedDate = this.dietStartDate!;
      }

      if (this.disableLeapBackward || this.disableLeapForward) {
        this.selectedIndex = (eachDayOfInterval({
          start: this.pastDate,
          end: this.futureDate,
        }).findIndex(date => date.toLocaleDateString('en-us') === this.selectedDate.toLocaleDateString('en-US')));
      }

      const day = format(this.selectedDate, "eeee");

      if (this.mealsForWeek.length > 0) {
        this.mealsForDay = this.mealsForWeek.filter(
          (days) => days.day === day.toLowerCase()
        )[0].mealNames;
      }

      const status = this.statusRegistry.get(this.selectedDate.toLocaleDateString('en-US'));
      this.currentSelectedDiet = this.getCurrentDiet(status);
    }
  };


  getCurrentDiet = (trackStatus) => {
    let trackMeals = { id: uuid() };

    if (trackStatus) {
      (trackStatus.status as Array<any>).forEach(track => {
        trackMeals[track.name] = { status: track.status, comment: track.comment, isCheatMeal: track.isCheatMeal };
      })

      trackMeals['waterIntake'] = trackStatus.waterIntake;
    }

    return trackMeals;
  }

  @action getClientDetail = async (id: string) => {
    try {
      this.fetchingDiet = true;
      const clientDetail = await agent.Responsive.clientDetail(id);

      runInAction(() => {
        this.fetchingDiet = false;

        if (clientDetail.assignedDiet) {
          this.assignedDietInfoId = clientDetail.assignedDiet.id;
          this.noOfCheatMeals = clientDetail.assignedDiet.noOfCheatMeals;
          this.dietStartDate = new Date(formatToTimeZone(clientDetail.assignedDiet.startDate, 'MM/DD/YYYY', { timeZone: DEFAULT_TIME_ZONE}));
          this.dietEndDate = new Date(formatToTimeZone(clientDetail.assignedDiet.endDate, 'MM/DD/YYYY', { timeZone: DEFAULT_TIME_ZONE }));
        }

        if (this.dietEndDate && new Date(this.dietEndDate!.toLocaleDateString('en-US')) < new Date()) {
          this.futureDate = this.dietEndDate!;
          this.pastDate = subDays(this.futureDate, 4);
        }

        if (clientDetail.trackDietStatus && clientDetail.trackDietStatus.length > 0) {
          clientDetail.trackDietStatus.forEach(status => {
            this.statusRegistry.set(status.forDate, { id: status.id!, waterIntake: status.waterIntake, status: status.mealStatus });
          });
        }

        this.mealsForWeek = clientDetail.mealsForWeek;
        this.trackDietStatus = clientDetail.trackDietStatus;
        this.setSelectedDate(2);
      });
    } catch (error) {
      alertify.error("Problem occurred while getting the diet detail");
      runInAction(() => {
        this.fetchingDiet = false;
      });
    }
  };

  @action saveDietStatus = async (waterIntake: number, mealStatuses: IMealStatus[]) => {
    try {
      this.savingDietStatus = true;

      const selectedDate = this.selectedDate.toLocaleDateString('en-US');
      const trackStatusId = this.statusRegistry.get(selectedDate);

      const dietStatus: ITrackDietStatus = {
        waterIntake: waterIntake,
        assignedDietInfoId: this.assignedDietInfoId!,
        mealStatus: mealStatuses,
        forDate: this.selectedDate.toLocaleDateString('en-US'),
      };

      if (trackStatusId) {
        dietStatus.id = trackStatusId.id;
      }

      const loggedDietStatus = await agent.Client.logDietStatus(dietStatus);

      if (loggedDietStatus && loggedDietStatus.trackDietStatus.id) {
        runInAction(() => {
          this.savingDietStatus = false;
          this.statusRegistry.set(dietStatus.forDate, { id: loggedDietStatus.trackDietStatus.id!, waterIntake: dietStatus.waterIntake, status: dietStatus.mealStatus });
          alertify.success('Meal status has been saved');
        }); // run action
      }

    } catch (error) {
      runInAction(() => {
        this.savingDietStatus = false;
      })
    }
  }

  @action deleteLoggedDietStatus = async () => {
    const registryKey = this.selectedDate?.toLocaleDateString('en-US');
    const dietStatus = this.statusRegistry.get(registryKey!);

    if (dietStatus) {
      try {
        this.deletingDietStatus = true;
        await agent.Client.deleteLoggedDietStatus(dietStatus.id);

        runInAction(() => {
          this.statusRegistry.delete(registryKey);
          this.deletingDietStatus = false;
          this.rootStore.commonStore.scrollToTop();
        });
      } catch (error) {
        alertify.error("Problem occurred while deleting the Diet Status");

        runInAction(() => {
          this.deletingDietStatus = false;
        });
      }
    }

  }

  @action cleanup = () => {
    this.assignedDietInfoId = null;
    this.pastDate = subDays(new Date(), 2);
    this.futureDate = addDays(new Date(), 2);
    this.isSideMenuVisible = false;
    this.setTabIndex(2);
    this.mealsForWeek = [];
    this.mealsForDay = [];
    this.dietStartDate = null;
    this.dietStartDate = null;
    this.disableLeapBackward = false;
    this.disableLeapForward = false;
    this.disableStepForward = false;
    this.disableStepBackward = false;
    this.statusRegistry.clear();
  };
}
