import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormFieldProps, Form, Label, Search } from 'semantic-ui-react'

interface IProps extends FieldRenderProps<string, HTMLElement>, FormFieldProps { }

const SearchInput: React.FC<IProps> = ({ input, width, type, placeholder, meta: { touched, error }, style, label, onSearchChange, results, isLoading, ...rest }) => {
    return (
        <Form.Field error={touched && !!error} type={type} width={width}>
            {label && <label>{label}</label>}
            <Search
                placeholder={placeholder}
                loading={isLoading}
                results={results}
                value={input.value['name']}
                onSearchChange={(e, value) => {
                    if (String(value.value).length !== 0) {
                        input.onChange(null);
                        onSearchChange(e, value);
                    } else {
                        input.onChange(null);
                    }
                }}
                onResultSelect={(e, { result }) => {
                    result.id ? input.onChange({id: result.id, name: result.title}) : input.onChange(null)
                }} {...rest} />
            {touched && error && (
                <Label basic color='red' pointing='above'>{error}</Label>
            )}
        </Form.Field>
    )
}

export default SearchInput;
