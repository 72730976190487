import React, { useContext, Fragment, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { Segment, Grid, Item, Header, Statistic, Button, Modal, Form, TextArea, Icon } from 'semantic-ui-react';
import { differenceInYears } from 'date-fns';
import { RootStoreContext } from '../../../stores/rootStore';



const ClientHeader = () => {

    const rootStoreContext = useContext(RootStoreContext);
    const { clientInfo, clientProfile, assignedDiet, deleteAssignedDiet, isDeletingAssignedDiet, mealStatistics } = rootStoreContext.clientStore;
    const { isUserClient } = rootStoreContext.userStore;
    const { events } = rootStoreContext.eventStore;

    const initConstraints = { open: false, header: '', content: '' };

    const [assignedModalState, setAssignedModalState] = useState(false);
    const [constraintsModal, setConstraintsModal] = useState(initConstraints);

    const showConstraints = (isHealthIssues: boolean) => {
        if (isHealthIssues) {
            setConstraintsModal({ open: true, header: 'Health Issues', content: clientProfile?.healthIssues! });
        } else {
            setConstraintsModal({ open: true, header: 'Food Allergies', content: clientProfile?.foodAllergies! });
        }

    }

    const closeConstraintsModal = () => {
        setConstraintsModal(initConstraints);
    }

    return (
        <Fragment>
            <Segment color='red' raised>
                <Grid>
                    <Grid.Column width={12}>
                        <Item.Group>
                            <Item>
                                <Item.Image
                                    avatar
                                    size='small'
                                    src={clientInfo?.imageUrl || '/assets/user.png'}
                                />
                                <Item.Content verticalAlign='middle'>
                                    <Header as='h1'>{clientInfo?.name}</Header>
                                    {clientProfile?.gender && !isUserClient && <Item.Meta>Gender: {clientProfile.gender === 'M' ? 'Male' : 'Female'}</Item.Meta>}
                                    <Item.Meta>Age: {clientProfile?.dateOfBirth && `${differenceInYears(new Date(), new Date(clientProfile?.dateOfBirth))} years`}</Item.Meta>
                                    <Item.Meta>Height: {clientProfile?.heightInCms && `${(clientProfile?.heightInCms! / 30.48).toFixed(2)} ft`}</Item.Meta>
                                    <Item.Meta>Current Weight: {clientProfile?.currentWeightInKgs && `${clientProfile?.currentWeightInKgs} kg`}</Item.Meta>
                                    <Item.Meta>Goal Weight: {clientProfile?.goalWeightInKgs && `${clientProfile?.goalWeightInKgs} kg`}</Item.Meta>
                                    {
                                        clientProfile?.healthIssues && <Button color='instagram' onClick={() => showConstraints(true)} content='Health Issues' size='mini' />
                                    }
                                    {
                                        clientProfile?.foodAllergies && <Button color='instagram' onClick={() => showConstraints(false)} content='Food Allergies' size='mini' />
                                    }
                                </Item.Content>
                            </Item>
                        </Item.Group>
                    </Grid.Column>
                    <Grid.Column width={4} verticalAlign='middle'>
                        <Statistic>
                            {!isUserClient && <Statistic label='Diet Adhereance Rate' value={`${mealStatistics?.adherenceRate ? mealStatistics?.adherenceRate! : 0} %`} />}
                            {!isUserClient && assignedDiet.id && <Button
                                size='tiny'
                                color='red'
                                onClick={() => setAssignedModalState(true)}
                                content='Unassign Diet'
                                loading={isDeletingAssignedDiet}
                            />}
                        </Statistic>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Modal closeOnDimmerClick={false} closeOnEscape={false} open={assignedModalState} size={events.length === 0 ? 'mini' : 'small'}>
                <Modal.Header>
                    Confirm Deletion
                </Modal.Header>
                <Modal.Content>
                    {events.length > 0 &&
                        <Fragment>
                            <p>Client has already started following the diet</p>
                            <p>This diet record would be available in Client's history after deletion. Are you sure you want to delete this diet assignment?</p>
                        </Fragment>
                    }
                    {events.length === 0 &&
                        <p>Delete diet assignment?</p>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={() => setAssignedModalState(false)} content='No' />
                    <Button positive onClick={() => { setAssignedModalState(false); deleteAssignedDiet(assignedDiet.id!) }} content='Yes' loading={isDeletingAssignedDiet} />
                </Modal.Actions>
            </Modal>
            <Modal size='small' open={constraintsModal.open} onClose={closeConstraintsModal} closeIcon>
                <Modal.Header>
                    <Icon name={constraintsModal.header === 'Health Issues' ? 'medkit' : 'food'} size='big' style={{ marginRight: 20 }} />{constraintsModal.header}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>{isUserClient ? 'You have' : 'Client has'} mentioned the following {constraintsModal.header} in {isUserClient ? 'your' : 'their'} profile:</label>
                            <TextArea value={constraintsModal.content} readOnly rows={8} />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={closeConstraintsModal} content='Close' />
                </Modal.Actions>
            </Modal>
        </Fragment >
    )
}

export default observer(ClientHeader);
