import React from 'react'
import { Segment, Label } from 'semantic-ui-react'
import { Field } from 'react-final-form';
import { TextAreaInput } from '../../../common/form/TextAreaInput';


interface IProps {
    isReadOnly: boolean;
}

const Comments: React.FC<IProps> = ({ isReadOnly }) => {
    
    return (
        <Segment raised color='red'>
            <Label content='Comments' ribbon color='blue'  style={{ marginBottom: 20 }}/>
            <Field
                component={TextAreaInput}
                name='comments.dietPlan'
                label='Diet Plan'
                placeholder='Enter Diet Plan comments'
                rows={3}
                readOnly={isReadOnly}
            />
            <Field
                component={TextAreaInput}
                name='comments.progress'
                label='Progress'
                placeholder='Enter Progress comments'
                rows={3}
                readOnly={isReadOnly}
            />
        </Segment>
    )
}

export default Comments;
