import React, { Fragment } from 'react'
import { IMealInfo } from '../../../models/diet-info.model';
import { observer } from 'mobx-react-lite';
import ReactHtmlParser from 'react-html-parser';

interface IProps {
    type: string;
    meal: IMealInfo | null;
}

const DietDetail: React.FC<IProps> = ({ type, meal }) => {
    return (
        <Fragment>
            <div className='ui card fluid'>
                <div className="image">
                    <span className="ui olive ribbon label">{type.toUpperCase()}</span>
                </div>
                <div className="content">
                    {
                        meal!.mainMeal && ReactHtmlParser(meal!.mainMeal)
                    }
                </div>
            </div>
            {
                meal!['isSnackNeeded'] &&
                <div className='ui card fluid'>
                    <div className="image">
                        <span className="ui red ribbon label">SNACK</span>
                    </div>
                    <div className="content">
                        {
                            meal!['snack']! && ReactHtmlParser(meal!.snack)
                        }
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default observer(DietDetail);
