import React, { useContext } from 'react'
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Header } from 'semantic-ui-react';
import { RootStoreContext } from '../../stores/rootStore';
import { FORM_ERROR } from 'final-form';
import { combineValidators, isRequired, composeValidators, matchesField, hasLengthBetween, hasLengthGreaterThan } from 'revalidate';
import { IUserFormValues } from '../../models/user.model';
import TextInput from '../../common/form/TextInput';
import { ErrorMessage } from '../../common/form/ErrorMessage';
import { isValidEmail, passwordComplexity } from '../../common/util/validators';

const validate = combineValidators({
    signUpCode: composeValidators(isRequired, hasLengthGreaterThan(7)({ message: 'Invalid Sign up Code' }))('Sign up Code'),
    firstName: isRequired('First Name'),
    lastName: isRequired('Last Name'),
    email: composeValidators(isRequired, isValidEmail)('Email'),
    password: composeValidators(isRequired, hasLengthBetween(8, 20), passwordComplexity)('Password'),
    confirmPassword: composeValidators(isRequired, matchesField('password', 'Password')({ message: 'Should match with Password' }))('Confirm Password')
});

export const RegisterForm = () => {
    const rootStore = useContext(RootStoreContext)
    const { register } = rootStore.userStore;

    return (
        <div>
            <FinalForm
                validate={validate}
                onSubmit={(values: IUserFormValues) => register(values).catch(error => ({
                    [FORM_ERROR]: error
                }))}
                render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                    <Form onSubmit={handleSubmit} error autoComplete='off'>
                        <Header as='h2' content='Sign up to MQ Nutrition' color='blue' textAlign='center' />
                        <Field
                            name='signUpCode'
                            component={TextInput}
                            placeholder='Sign up Code'
                            maxlength={8}
                        />
                        <Field
                            name='firstName'
                            component={TextInput}
                            placeholder='First Name'
                            maxlength={20}
                        />
                        <Field
                            name='lastName'
                            component={TextInput}
                            placeholder='Last Name'
                            maxlength={20}
                        />
                        <Field
                            name='email'
                            component={TextInput}
                            placeholder='Email'
                        />
                        <Field
                            name='password'
                            component={TextInput}
                            placeholder='Password'
                            type='password'
                            maxlength={20}
                        />
                        <Field
                            name='confirmPassword'
                            component={TextInput}
                            placeholder='Confirm Password'
                            type='password'
                            maxlength={20}
                        />
                        {submitError && <ErrorMessage error={submitError} text='Oops!' />}
                        <br />
                        <Button color='blue' content='Register' loading={submitting} disabled={(invalid && !dirtySinceLastSubmit) || pristine} fluid />
                    </Form>
                )}
            />
        </div>
    )
}
