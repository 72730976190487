import { RootStore } from "./rootStore";
import { observable, action, runInAction, computed } from "mobx";
import { EventInput } from "@fullcalendar/react";
import uuid from 'react-uuid';
import { ITrackMeals, INoncompliantMeal } from "../models/track-meals.model";
import {
  IMealStatus,
  ITrackDietStatus,
} from "../models/track-diet-status.model";
import agent from "../api/agent";
import alertify from "../common/util/alertify";
import { ITrackProgress } from "../models/client-detail.model";
import _ from 'lodash';
import { IProgressTrackBasicData } from "../models/progress-tracker.model";


export class EventStore {
  rootStore: RootStore;

  @observable selectedDate: Date | null = null;
  @observable events: EventInput[] = [];
  @observable currentSelectedDiet: ITrackMeals = {};
  @observable statusRegistry: Map<string, {id: string, waterIntake: number}> = new Map();
  @observable progressTrackRegistry = new Map();
  @observable savingDietStatus: boolean = false;
  @observable deletingDietStatus: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action setDateSelected = (date: Date) => {
    this.selectedDate = date;
  };

  @computed get hasDietStatus() {
    return this.statusRegistry.has(this.selectedDate!.toLocaleDateString('en-US'));
  }

  @computed get canCompareProgress() {
    return this.progressTrackRegistry.size > 1;
  }

  @action addEvent = async (assignedDietInfoId: string, eventDate: Date, trackMeals: ITrackMeals) => {
    try {
      this.savingDietStatus = true;
      const mealStatuses: IMealStatus[] = [];

      Object.keys(trackMeals).forEach((key) => {
        if (key && key !== 'id' && key !== 'waterIntake') {
          const mealStatus: IMealStatus = {
            id: uuid(),
            name: key,
            status: trackMeals[key]?.status,
            comment: trackMeals[key]?.comment ? trackMeals[key]?.comment : null,
            isCheatMeal: trackMeals[key]?.isCheatMeal ? trackMeals[key]?.isCheatMeal : null
          };
          mealStatuses.push(mealStatus);
        }
      });
      
      const dietStatus: ITrackDietStatus = {
        waterIntake: trackMeals.waterIntake!,
        assignedDietInfoId: assignedDietInfoId,
        mealStatus: mealStatuses,
        forDate: eventDate.toLocaleDateString('en-US'),
      };

      const trackStatusId = this.statusRegistry.get(dietStatus.forDate);

      if (trackStatusId) {
        dietStatus.id = trackStatusId.id;
      }

      const loggedDietStatus = await agent.Client.logDietStatus(dietStatus);

      if (loggedDietStatus && loggedDietStatus.trackDietStatus.id) {
        runInAction(() => {
          this.savingDietStatus = false;

          this.showDietStatusEvent(loggedDietStatus.trackDietStatus, new Date(loggedDietStatus.trackDietStatus.forDate));
        }); // run action
      }
    } catch (error) {
      alertify.error("Problem occurred while saving the Diet status");

      runInAction(() => {
        this.savingDietStatus = false;
      });
    }
  };

  @action getEventsForDate = (date: Date) => {
    const events = this.events.filter(
      (e) => Date.parse(e.start!.toString()) === Date.parse(date.toString())
    );

    let meals = {};

    events?.forEach((e) => {
      meals = Object.assign(meals, e.extendedProps!);
    });

    const status = this.statusRegistry.get(date.toLocaleDateString('en-US'));
    this.currentSelectedDiet = {...meals, waterIntake: status?.waterIntake};
  };

  @action deleteEvent = (id: string) => {
    this.events.splice(
      this.events.findIndex((e) => e.id === id),
      1
    );
  };

  @action clearCurrentSelectedDiet = () => {
    this.currentSelectedDiet = {};
  };

  @action deleteLoggedDietStatus = async () => {
    const registryKey = this.selectedDate?.toLocaleDateString('en-US');
    const dietStatusId = this.statusRegistry.get(registryKey!);

    if (dietStatusId) {
      try {
        this.deletingDietStatus = true;
        await agent.Client.deleteLoggedDietStatus(dietStatusId.id);

        runInAction(() => {
          this.deletingDietStatus = false;

          this.statusRegistry.delete(registryKey!);

          this.events = this.events.filter(
            event => Date.parse(event.start!.toString()) !== Date.parse(this.selectedDate!.toString()) || event.title === 'Progress'
          );

          this.clearCurrentSelectedDiet();
        });
      } catch (error) {
        alertify.error("Problem occurred while deleting the Diet Status");

        runInAction(() => {
          this.deletingDietStatus = false;
        });
      }
    }
  };

  @action logDietStatusEvents = (events: ITrackDietStatus[]) => {
    events.forEach(status => {
      this.showDietStatusEvent(status, new Date(status.forDate!));
    })
  };

  @action showDietStatusEvent = (trackStatus: ITrackDietStatus, eventDate: Date) => {
    this.statusRegistry.set(trackStatus.forDate, { id: trackStatus.id!, waterIntake: trackStatus.waterIntake });

    this.events = this.events.filter(
      (event) => Date.parse(event.start!.toString()) !== Date.parse(eventDate.toString()) || event.title === 'Progress'
    );

    const sortedEvents: {
      order: number;
      mealName: string;
      status: string;
      eProp: any;
    }[] = [];

    trackStatus.mealStatus.forEach((meal) => {
      let mealName: string = "";
      let order: number = 0;

      switch (meal.name.toUpperCase()) {
        case "BREAKFAST":
          mealName = "Breakfast";
          order = 1;
          break;
        case "SNACK1":
          mealName = "Snack 1";
          order = 2;
          break;
        case "LUNCH":
          mealName = "Lunch";
          order = 3;
          break;
        case "SNACK2":
          mealName = "Snack 2";
          order = 4;
          break;
        case "DINNER":
          mealName = "Dinner";
          order = 5;
          break;
        case "SNACK3":
          mealName = "Snack 3";
          order = 6;
          break;
      }

      const propKey = meal.name;
      const propObj = {};
      propObj[propKey] = { status: meal.status };

      if (meal.comment) {
        propObj[propKey].comment = meal.comment;
      }

      if (meal.isCheatMeal) {
        propObj[propKey].isCheatMeal = meal.isCheatMeal;
      }

      sortedEvents.push({
        order: order,
        mealName: mealName,
        status: meal.status,
        eProp: propObj,
      });
    }); // forEach

    sortedEvents
      .sort((a, b) => a.order - b.order)
      .forEach((e) => {
        this.events.push({
          id: uuid(),
          title: e.mealName,
          start: new Date(trackStatus.forDate),
          end: new Date(trackStatus.forDate),
          allDay: true,
          color:
            e.status === "compliant"
              ? "#3b9c9c"
              : e.status === "noncompliant"
              ? "#1b1c1d"
              : "#767676",
          extendedProps: e.eProp,
        });
      });
  };

  @action addProgressEvent = (progressData: ITrackProgress[]) => {
    progressData.forEach(data => {
      this.progressTrackRegistry.set(data.forDate, data.id);
      this.events.unshift({
        id: uuid(),
            title: 'Progress',
            start: new Date(data.forDate!),
            allDay: true,
            color: '#db2828',
            extendedProps: { id: data.id }
      })
    })
  }

  @action deleteProgressEvent = (date: string) => {
    const event = this.events.filter(event => 
      new Date(event.start!.toString()).toLocaleDateString('en-US') === new Date(date).toLocaleDateString('en-US') && 
      event.title === 'Progress'
    );

    if (event) {
      this.deleteEvent(event[0].id!);
    }
  }

  @action getProgressTrackId = (forDate: string): string | undefined => {
    return this.progressTrackRegistry?.has(forDate) ? this.progressTrackRegistry.get(forDate) : undefined;
  }

  @action getNoncomplianceReasons = (): {[key: string]: { name: string; comment: string, isCheatMeal: boolean }[]} => {
    const nonCompliantMeals: INoncompliantMeal[] = [];

    this.events.forEach(event => {
      Object.keys(event.extendedProps!).forEach(key => {
        if (key !== 'id') {
          const mealData = event.extendedProps![key];
          if (mealData && mealData.status === 'noncompliant') {
            const ncMeal: INoncompliantMeal = { date: new Date(event.start!.toString()).toLocaleDateString('en-US') };

            let mealName = '';

            switch(key) {
              case "snack1":
                mealName = "Snack 1";
                break;
              case "snack2":
                mealName = "Snack 2";
                break;
              case "snack3":
                mealName = "Snack 3";
                  break;
              default:
                mealName = _.startCase(key);
                break;
            }

            ncMeal.meal = { name: mealName, comment: mealData.comment, isCheatMeal: mealData.isCheatMeal! === 'Yes' };
            nonCompliantMeals.push(ncMeal);
          }
        }
      })
    });

    const ncMeals = nonCompliantMeals.reduce((dateMeals: any, meal) =>  {
      const date = meal.date;

      if(dateMeals[date]) {
        dateMeals[date] = [...dateMeals[date], meal.meal];
      } else {
        dateMeals[date] = [meal.meal];
      }

      return dateMeals;
      
    }, {} as { [key: string]: { name: string; comment: string}[] });

    return {...ncMeals};
  }

  @action getProgressTracks = (): IProgressTrackBasicData[] => {
    let progressTracks: IProgressTrackBasicData[] = [];

    this.progressTrackRegistry.forEach((value, key)  => {
      progressTracks.push({ date: key, id: value });
    })

    return progressTracks;
  }

  @action eventsCleanUp = () => {
    this.currentSelectedDiet = {};
    this.statusRegistry.clear();
    this.progressTrackRegistry.clear();
    this.events = [];
    this.selectedDate = null;
  };
}
