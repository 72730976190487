import React, { Fragment, useState } from 'react'
import { Header, Segment, Grid, Message, Button, Modal } from 'semantic-ui-react'
import { displayFullDate, MAX_DEVICE_WIDTH } from '../../common/util/util'
import DietDetail from '../diet/view/DietDetail'
import Instructions from '../diet/view/Instructions'
import { IDailyMeal } from '../../models/diet-info.model'
import { Desktop } from '../../layout/Responsive';
import { useMediaQuery } from 'react-responsive';
import ReactHtmlParser from 'react-html-parser';

interface IProps {
    dietForTheDay: IDailyMeal;
    instructions: string;
    generalInstructions: string;
}

const DietForTheDay: React.FC<IProps> = ({ dietForTheDay, instructions, generalInstructions }) => {

    const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: MAX_DEVICE_WIDTH })
    const [modalState, setModalState] = useState(false);
    const [instr, setInstr] = useState({ isPersonalized: false, instructions: ''});

    const showInstructions = (isPersonalized: boolean, instructions: string) => {
        setInstr({ isPersonalized, instructions });
        setModalState(true);
    }

    return (
        <Fragment>
            <Header content={`Today's Diet`} as='h1' subheader={displayFullDate()} floated='left' />
            <br /><br /><br />
            { isTabletOrMobileDevice &&
                <Button.Group fluid>
                    { generalInstructions && <Button content='Diet Instructions' basic color='blue' onClick={() => showInstructions(false, generalInstructions)} /> }
                    { instructions && <Button content='Personalized' basic color='blue' onClick={() => showInstructions(true, instructions)} /> }
                </Button.Group>
            }
            <Segment color='black'>
                <Grid>
                    <Grid.Column width={isTabletOrMobileDevice ? 16 : 8}>
                        {
                            dietForTheDay &&
                            Object.keys(dietForTheDay).map((meal, index) => dietForTheDay![meal] && <DietDetail key={`${meal}_${index}`} type={meal} meal={dietForTheDay![meal]} />)
                        }
                        {
                            !dietForTheDay && <Message content='No diet assigned for today' info />
                        }
                    </Grid.Column>
                    <Desktop>
                        <Grid.Column width={8}>
                            {
                                instructions && <Instructions type='Personalized Instructions' data={instructions!} />
                            }
                        </Grid.Column>
                    </Desktop>
                </Grid>
            </Segment>

            <Modal closeOnDimmerClick={false} closeOnEscape={false} open={modalState}>
                <Modal.Header content={`${instr.isPersonalized ? 'Personalized' : 'Diet'} instructions`} />
                <Modal.Content>
                    <Modal.Description content={ReactHtmlParser(instr.instructions)} />
                </Modal.Content>
                <Modal.Actions>
                    <Button content='Ok' onClick={() => setModalState(false)} secondary />
                </Modal.Actions>
            </Modal>
        </Fragment>
    )
}

export default DietForTheDay;
