import React, { useContext, useState, useEffect, Fragment } from 'react'
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/rootStore';
import { MAX_DEVICE_WIDTH, Roles } from '../../common/util/util';
import { IUserInfo } from '../../models/user-info.model';
import LoadingComponent from '../../layout/LoadingComponent';
import { Header, Button, Label, Image, Card, Grid, CardGroup } from 'semantic-ui-react';
import { format } from 'date-fns';
import alertify from '../../common/util/alertify';
import { Link } from 'react-router-dom';
import { Desktop, Mobile } from '../../layout/Responsive';
import { useMediaQuery } from 'react-responsive';
import AdminActionsPage from '../user/AdminActionsPage';
import DataTable from 'react-data-table-component';


const ViewClientsPage = () => {
    const rootStoreContext = useContext(RootStoreContext);
    const { getUsersByRole, activateUser, deactivateUser, isUserAdmin } = rootStoreContext.userStore;
    const { openModal } = rootStoreContext.modalStore;
    const userInfo: IUserInfo[] = [];

    const [loadingClients, setLoadingClients] = useState(true);
    const [activatingUser, setActivatingUser] = useState(false);
    const [users, setUsers] = useState(userInfo);
    const [activatingTarget, setActivatingTarget] = useState<string | undefined>(undefined);
    const { toggleSideMenu } = rootStoreContext.responsiveStore;

    const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: MAX_DEVICE_WIDTH });

    useEffect(() => {

        if (isTabletOrMobileDevice) {
            toggleSideMenu(false);
        }

        getUsersByRole(Roles.CLIENT)
            .then((users: any) => {
                setUsers(users);
            })
            .finally(() => setLoadingClients(false));
    }, [getUsersByRole, isTabletOrMobileDevice, toggleSideMenu]);

    const setActivateUser = (id: string) => {
        setActivatingUser(true);

        activateUser(id)
            .then((user) => {
                toggleActivation(user);
                alertify.success('User Account has been activated');
            })
            .finally(() => setActivatingUser(false));
    }

    const setDeactivateUser = (id: string) => {
        setActivatingUser(true);

        deactivateUser(id)
            .then((user) => {
                toggleActivation(user);
                alertify.info('User Account has been deactivated');
            })
            .finally(() => setActivatingUser(false));
    }

    const toggleActivation = (user: IUserInfo) => {
        const uIndex = users.findIndex(u => u.id === user.id);
        let tUsers = [...users];
        tUsers.splice(uIndex, 1, user);
        setUsers(tUsers);
    }

    const mobGridPadding = { paddingLeft: '1em', paddingTop: 2, paddingBottom: 5 };
    const columns = [
        {
            name: <h4>View/Activate/History</h4>,
            cell: client => <div>
                {client.isProfileCompleted && <Button circular icon='eye' as={Link} to={`/clients/${client.id}`} />}
                <Button
                    circular
                    name={client.id}
                    icon={client.isAccessGranted ? 'unlock' : 'lock'}
                    color={client.isAccessGranted ? 'green' : 'red'}
                    onClick={e => {
                        client.isAccessGranted ? setDeactivateUser(client.id) : setActivateUser(client.id);
                        setActivatingTarget(e.currentTarget.name);
                    }}
                    loading={activatingUser && activatingTarget === client.id}
                />
                <Button
                    circular
                    name={client.id}
                    icon='clock outline'
                    color='linkedin'
                    as={Link}
                    to={`/history/${client.id}`}
                />
                {isUserAdmin &&
                    <Button
                        circular
                        color='black'
                        icon='cog'
                        type='button'
                        onClick={() => openModal(<AdminActionsPage name={client.name} email={client.email} />, 'mini')}
                    />
                }
            </div>,
            sortable: false,
            width: '20%'

        },
        {
            name: <h4>Client</h4>,
            selector: 'name',
            cell: row => <Header as='h4' image>
                <Image src={row.imageUrl || '/assets/user.png'} rounded size='mini' circular />
                <Header.Content>
                    {row.name}
                    <Header.Subheader style={{fontSize: 11}}>{row.email}</Header.Subheader>
                </Header.Content>
            </Header>,
            sortable: true,
            right: false,
            width: '20%'
        },
        {
            name: <h4>Last Login Date</h4>,
            selector: 'lastLoginDate',
            cell: row => <Label basic>{format(new Date(row.lastLoginDate), 'dd MMM yyyy h:mm a')}</Label>,
            sortable: true,
            center: true,
            width: '20%'
        },
        {
            name: <h4>Active From</h4>,
            selector: 'activeFrom',
            cell: row => <Label basic>{format(new Date(row.activeFrom), 'dd MMM yyyy h:mm a')}</Label>,
            sortable: true,
            center: true,
            width: '20%'
        },
        {
            name: <h4>Profile</h4>,
            selector: 'isProfileCompleted',
            cell: row => <Label color={row.isProfileCompleted ? 'green' : 'red'} content={row.isProfileCompleted ? 'Completed' : 'Pending'} />,
            sortable: true,
            center: true,
            width: '10%'
        },
        {
            name: <h4>Diet</h4>,
            selector: 'isDietAssigned',
            cell: row => <Label color={row.isDietAssigned ? 'green' : 'red'} content={row.isDietAssigned ? 'Assigned' : 'Pending'} />,
            sortable: true,
            center: true,
            width: '10%'
        },
    ];

    if (loadingClients) return <LoadingComponent content='Loading Clients...' />

    return (
        <Fragment>
            <Header
                as={isTabletOrMobileDevice ? 'h3' : 'h1'}
                content='Clients'
                subheader={!isTabletOrMobileDevice && 'Manage your clients by choosing actions below'}
                dividing
                icon='users'
            />
            <Mobile>
                <CardGroup>
                    {
                        users && users.map((client, index) => (
                            <Card key={index} fluid color='red' raised>
                                <Card.Content>
                                    <Image
                                        floated='right'
                                        size='mini'
                                        src={client.imageUrl || '/assets/user.png'}
                                        circular
                                    />
                                    <Card.Header>{client.name}</Card.Header>
                                    <Card.Meta>{client.email}</Card.Meta>
                                    <Card.Description>
                                        <Grid>
                                            <Grid.Column width={7} style={mobGridPadding}>
                                                Last Login Date:
                                            </Grid.Column>
                                            <Grid.Column width={8} style={mobGridPadding}>
                                                {client.lastLoginDate && <Label basic size='tiny' content={format(new Date(client.lastLoginDate!), 'dd MMM yyyy h:mm a')} />}
                                            </Grid.Column>
                                            <Grid.Column width={7} style={mobGridPadding}>
                                                Profile:
                                            </Grid.Column>
                                            <Grid.Column width={8} style={mobGridPadding}>
                                                <Label color={client.isProfileCompleted ? 'green' : 'red'} content={client.isProfileCompleted ? 'Completed' : 'Not Completed'} size='tiny' />
                                            </Grid.Column>
                                            <Grid.Column width={7} style={mobGridPadding}>
                                                Diet?:
                                            </Grid.Column>
                                            <Grid.Column width={8} style={mobGridPadding}>
                                                <Label color={client.isDietAssigned ? 'green' : 'red'} content={client.isDietAssigned ? 'Assigned' : 'Not Assigned'} size='tiny' />
                                            </Grid.Column>
                                            {
                                                client.activeFrom &&
                                                <Fragment>
                                                    <Grid.Column width={7} style={mobGridPadding}>
                                                        Active From?:
                                                    </Grid.Column>
                                                    <Grid.Column width={8} style={mobGridPadding}>
                                                        <Label basic size='tiny' content={format(new Date(client.activeFrom), 'dd MMM yyyy h:mm a')} />
                                                    </Grid.Column>
                                                </Fragment>
                                            }
                                        </Grid>
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra>
                                    <Fragment>
                                        <Button.Group size='mini' floated='right'>
                                            {isUserAdmin && <Button
                                                circular
                                                color='black'
                                                icon='cog'
                                                content='Actions'
                                                type='button'
                                                size='mini'
                                                onClick={() => openModal(<AdminActionsPage name={client.name} email={client.email} />, 'mini')}
                                            />}
                                            <Button
                                                name={client.id}
                                                icon={client.isAccessGranted ? 'unlock' : 'lock'}
                                                color={client.isAccessGranted ? 'green' : 'red'}
                                                content={client.isAccessGranted ? 'Lock' : 'Unlock'}
                                                onClick={e => {
                                                    client.isAccessGranted ? setDeactivateUser(client.id) : setActivateUser(client.id);
                                                    setActivatingTarget(e.currentTarget.name);
                                                }}
                                                loading={activatingUser && activatingTarget === client.id}
                                                style={{ float: 'right' }}
                                                size='mini'
                                            />
                                        </Button.Group>
                                    </Fragment>
                                </Card.Content>
                            </Card>
                        ))
                    }
                </CardGroup>
                {
                    users.length === 0 && <Label color='red' content='No Registered Clients' />
                }
            </Mobile>
            <Desktop>
                {users && <DataTable
                    columns={columns}
                    data={users}
                    noHeader={true}
                    pagination={true}
                    striped
                    responsive={true}
                />}
            </Desktop>
        </Fragment >
    )
}

export default observer(ViewClientsPage);
