import React, { Fragment, useContext, useEffect } from 'react'
import { Tab, MenuItem, Statistic, Segment, Header, Grid, Icon, Message } from 'semantic-ui-react';
import { RootStoreContext } from '../../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
import { eachDayOfInterval } from 'date-fns/esm';
import { RouteComponentProps } from 'react-router-dom';
import LoadingComponent from '../../../layout/LoadingComponent';
import TrackStatus from './TrackStatus';


interface DetailParams {
    id: string
}

const ResponsiveTrackerPage: React.FC<RouteComponentProps<DetailParams>> = ({ match }) => {

    const rootStoreContext = useContext(RootStoreContext);
    const {
        pastDate,
        futureDate,
        selectedDate,
        selectedIndex,
        setTabIndex,
        cleanup,
        toggleSideMenu,
        getClientDetail,
        fetchingDiet,
        assignedDietInfoId,
        mealsForDay,
        leapForward,
        leapBackward,
        dietStartDate,
        dietEndDate,
        disableLeapForward,
        disableLeapBackward,
        datesSubmitted,
        currentSelectedDiet,
        noOfCheatMeals
    } = rootStoreContext.responsiveStore;

    const addTabPanes = (): any => {
        var result = eachDayOfInterval(
            { start: pastDate, end: futureDate }
        )

        const panes: any = [];

        result.forEach((date, index) => {
            const day = format(date, 'eee');
            const localDate = new Date(date.toLocaleDateString('en-US'));
            const color = datesSubmitted.indexOf(date.toLocaleDateString('en-US')) < 0 ? 'red' : 'black';

            const disableTab = (
                localDate < new Date(dietStartDate?.toLocaleDateString('en-US')!) ||
                localDate > new Date(dietEndDate?.toLocaleDateString('en-US')!) ||
                localDate > new Date()
            );

            panes.push({
                menuItem: <MenuItem key={index} disabled={disableTab}><Statistic value={date.getDate()} label={day} size='tiny' color={disableTab ? 'grey' : color}></Statistic></MenuItem>,
                render: () => 
                <Tab.Pane attached={false}>
                    <TrackStatus 
                        mealsForDay={mealsForDay!} 
                        currentSelectedDiet={currentSelectedDiet!}
                        noOfCheatMeals={noOfCheatMeals}
                    />
                </Tab.Pane>
            })
        })

        return panes;
    }

    const onTabChange = (e, data) => {
        setTabIndex(data.activeIndex);
    }

    useEffect(() => {
        toggleSideMenu(false);

        if (match.params.id) {
            getClientDetail(match.params.id);
        }

        return (() => {
            cleanup();
        })
    }, [cleanup, toggleSideMenu, getClientDetail, match.params.id])

    if (fetchingDiet) return <LoadingComponent content='Fetching Diet details...' />

    return (
        <Fragment>
            {!assignedDietInfoId &&
                <Message
                    icon='food'
                    header='You do not have any Diet assigned!'
                    content='Please check with your nutritionist for more details'
                />
            }
            {assignedDietInfoId &&
                <Fragment>
                    <Segment color='blue'>
                        <Grid>
                            <Grid.Column width={5} textAlign='left'>
                                <Icon name='backward' size='large' onClick={() => leapBackward()} disabled={disableLeapBackward} />
                            </Grid.Column>
                            <Grid.Column width={6} textAlign='center'>
                                <Header as='h4' content={format(selectedDate, 'MMM dd yyyy')} style={{ marginTop: 5 }}></Header>
                            </Grid.Column>
                            <Grid.Column width={5} textAlign='right'>
                                <Icon name='forward' size='large' onClick={() => leapForward()} disabled={disableLeapForward} />
                            </Grid.Column>
                        </Grid>
                    </Segment>
                    <Tab 
                        menu={{ pointing: true, className: 'wrapped', secondary: true, color: 'red' }} 
                        panes={addTabPanes()} onTabChange={onTabChange} 
                        activeIndex={selectedIndex} 
                    />
                </Fragment>
            }
        </Fragment>
    )
}

export default observer(ResponsiveTrackerPage);
