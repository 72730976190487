import React from 'react'
import { Table, Header, Label } from 'semantic-ui-react';

interface IProps {
    bodyPart: string;
    pre?: number;
    post?: number;
    measurement: string;
}

const PlaceHolder: React.FC<IProps> = ({ bodyPart, pre, post, measurement }) => {

    const diff = +(post! - pre!).toFixed(1);

    return (
        <Table.Row>
            <Table.Cell><Header size='tiny'>{bodyPart}</Header></Table.Cell>
            <Table.Cell>
                {!isNaN(pre!) && <Label content={`${pre} ${measurement}`} />}
            </Table.Cell>
            <Table.Cell>
                {!isNaN(post!) && <Label content={`${post} ${measurement}`} />}
            </Table.Cell>
            <Table.Cell>
                {!isNaN(diff) && <Label content={`${diff < 0 ? '-' : diff === 0 ? '' : '+'} ${diff < 0 ? diff * -1 : diff} ${measurement}`} color={diff <= 0 ? 'green' : 'red'} />}
            </Table.Cell>
        </Table.Row>
    )
}

export default PlaceHolder;
