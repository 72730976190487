import { createValidator } from "revalidate";

export const isValidEmail = createValidator(
  (message) => (value) => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return message;
    }
  },
  "Invalid email address"
);

export const isDecimalNumber = createValidator(
  (message) => (value) => {
    if (value && !/^\d*\.?\d*$/i.test(value)) {
      return message;
    }
  },
  "Invalid decimal value"
);

export const passwordComplexity = createValidator(
  (message) => (value) => {
    if (value && !value.match(/(?=^.{8,20}$)(?=.*\d)(?=.*\W+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/)) {
      return message;
    }
  },
  'Passwords should contain at least 1 upper case letter, 1 lower case letter, 1 number and a special character'
);
