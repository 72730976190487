import React, { Fragment, useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { Form, Header, Button, Divider, Confirm } from 'semantic-ui-react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import Result from './Result';
import SkinFoldMeasurements from './SkinFoldMeasurements';
import GirthMeasurements from './GirthMeasurements';
import { RootStoreContext } from '../../../stores/rootStore';
import { combineValidators, isNumeric } from 'revalidate';
import RecoveryAssessment from './RecoveryAssessment';
import { isDecimalNumber } from '../../../common/util/validators';
import Comments from './Comments';
import { IProgressTracker } from '../../../models/progress-tracker.model';
import LoadingComponent from '../../../layout/LoadingComponent';
import { format } from 'date-fns';

interface IProps {
    forDate: Date;
}

const ProgressTrackerPage: React.FC<IProps> = ({ forDate }) => {

    const rootStoreContext = useContext(RootStoreContext);

    const {
        skinfoldSum,
        updateSkinfoldSum,
        skinFoldParamsMale,
        skinFoldParamsFemale,
        updateBodyFatResult,
        progressCleanup,
        progressTracker,
        addProgress,
        clientAge,
        loadingTrackProgress,
        getProgressTrackData,
        postingProgressData,
        deleteProgress,
        deletingProgress
    } = rootStoreContext.progressStore;

    const { closeModal } = rootStoreContext.modalStore;
    const { assignedDiet, clientProfile } = rootStoreContext.clientStore;
    const { getProgressTrackId } = rootStoreContext.eventStore;
    const { isUserClient } = rootStoreContext.userStore;


    const girthErrMsg: string = 'Invalid value';
    const skinfoldErrMsg: string = 'Must be numeric';

    const [openConfirm, setOpenConfirm] = useState(false);
    const [progressTrackId, setProgressTrackId] = useState('');

    useEffect(() => {
        const trackId = getProgressTrackId(forDate.toLocaleDateString('en-US'));
        setProgressTrackId(trackId!);

        if (trackId) {
            getProgressTrackData(trackId);
        }

        return () => {
            progressCleanup();
        }
    }, [progressCleanup, getProgressTrackData, getProgressTrackId, forDate])

    const validate = combineValidators({
        'girthMeasurement.neck': isDecimalNumber({ message: girthErrMsg }),
        'girthMeasurement.shoulder': isDecimalNumber({ message: girthErrMsg }),
        'girthMeasurement.chest': isDecimalNumber({ message: girthErrMsg }),
        'girthMeasurement.upperArm': isDecimalNumber({ message: girthErrMsg }),
        'girthMeasurement.waist': isDecimalNumber({ message: girthErrMsg }),
        'girthMeasurement.hip': isDecimalNumber({ message: girthErrMsg }),
        'girthMeasurement.thigh': isDecimalNumber({ message: girthErrMsg }),
        'girthMeasurement.calf': isDecimalNumber({ message: girthErrMsg }),
        'skinfold.chest': isNumeric({ message: skinfoldErrMsg }),
        'skinfold.abdominals': isNumeric({ message: skinfoldErrMsg }),
        'skinfold.tricep': isNumeric({ message: skinfoldErrMsg }),
        'skinfold.midAxillary': isNumeric({ message: skinfoldErrMsg }),
        'skinfold.subscapular': isNumeric({ message: skinfoldErrMsg }),
        'skinfold.suprailiac': isNumeric({ message: skinfoldErrMsg }),
        'skinfold.thigh': isNumeric({ message: skinfoldErrMsg }),
        'result.weightInKg': isNumeric({ message: skinfoldErrMsg }),
        'result.restingHeartRate': isNumeric({ message: skinfoldErrMsg })
    });

    const submitProgress = (values: IProgressTracker) => {
        addProgress(assignedDiet.id!, assignedDiet.assignedToUserId!, forDate.toLocaleDateString('en-US'), clientAge!, values).then(() => closeModal());
    }

    const calculateMeasurements = (values) => {

        const age = clientAge ? clientAge : 0;

        let sum = 0;
        const params = clientProfile?.gender === 'M' ? skinFoldParamsMale : skinFoldParamsFemale;

        if (values && values.skinfold) {
            Object.keys(values.skinfold).forEach(key => {
                if (values.skinfold[key] && !isNaN(values.skinfold[key]) && params.indexOf(key) >= 0) {
                    sum = sum + parseInt(values.skinfold[key]);
                }
            })
        }

        updateSkinfoldSum(sum);

        if (sum > 0) {
            const A = clientProfile?.gender === 'F' ? (0.0009929 * sum) : (0.0008267 * sum);
            const B = clientProfile?.gender === 'F' ? (0.0000023 * (sum * sum)) : (0.0000016 * (sum * sum));
            const C = clientProfile?.gender === 'F' ? (0.0001392 * age) : (0.0002574 * age);

            const BD = clientProfile?.gender === 'F' ? (1.0994921 - A + B - C) : (1.10938 - A + B - C);

            const bfp = ((4.570 / BD) - 4.142) * 100;

            updateBodyFatResult(bfp.toFixed(2), '0', '0');

            if (values.result?.weightInKg) {
                const weight = values.result.weightInKg;

                if (!isNaN(weight) && !isNaN(BD)) {
                    const fbm = (weight * bfp) / 100;
                    const lbm = weight - fbm;

                    updateBodyFatResult(bfp.toFixed(2), lbm.toFixed(2), fbm.toFixed(2));
                }
            }
        } else {
            updateBodyFatResult('0', '0', '0');
        }
    }

    if (loadingTrackProgress) return <Fragment>
        <div style={{ padding: 30 }}>
            <LoadingComponent content='Loading Progress data...' />
        </div>
    </Fragment>

    const isReadOnly: boolean = isUserClient || (forDate.toLocaleDateString('en-US') < new Date().toLocaleDateString('en-US'));
    const showModButtons: boolean = !isUserClient && forDate.toLocaleDateString('en-US') === new Date().toLocaleDateString('en-US');

    return (
        <Fragment>
            <Header as='h1' content='Progress Tracker' icon='heartbeat' subheader={`${format(forDate, 'MMM, dd yyyy')}, ${forDate.toLocaleString('en-US', { weekday: 'long' })}`} />
            <Divider />
            <FinalForm
                onSubmit={submitProgress}
                validate={validate}
                initialValues={progressTracker}
                render={({ handleSubmit, invalid, form }) => {
                    return (
                        <Form onSubmit={handleSubmit} autoComplete='off'>
                            <FormSpy
                                subscription={{ values: true, valid: true }}
                                onChange={(state) => {
                                    if (form.getState().dirty) {
                                        calculateMeasurements(state.values);
                                    }
                                }}
                            />
                            <GirthMeasurements isReadOnly={isReadOnly} />
                            <SkinFoldMeasurements skinfoldSum={skinfoldSum} isReadOnly={isReadOnly} />
                            <Result isReadOnly={isReadOnly} />
                            <RecoveryAssessment isReadOnly={isReadOnly} />
                            {!isUserClient && <Comments isReadOnly={isReadOnly} />}
                            <Divider />
                            <Button.Group floated='right'>
                                {showModButtons && <Button positive content='Save' disabled={invalid} loading={postingProgressData} />}
                                <Button content='Close' type='button' onClick={closeModal} />
                                {progressTrackId && showModButtons &&
                                    <Fragment>
                                        <Button negative type='button' content='Delete' onClick={() => setOpenConfirm(true)} loading={deletingProgress} />
                                        <Confirm
                                            header='Delete Diet Status'
                                            content='Are you sure you want to delete the Track Progress?'
                                            open={openConfirm}
                                            cancelButton='No'
                                            confirmButton="Yes"
                                            onCancel={() => setOpenConfirm(false)}
                                            onConfirm={() => { setOpenConfirm(false); deleteProgress(new Date().toLocaleDateString('en-US')).then(() => closeModal()) }}
                                            size='mini'
                                        />
                                    </Fragment>
                                }
                            </Button.Group>
                        </Form>)
                }}
            />
            <br /><br />
        </Fragment>
    )
}

export default observer(ProgressTrackerPage);
