import React, { Fragment, useState, useEffect, useContext } from 'react'
import { Segment, Label, Form, Grid, Statistic, Divider } from 'semantic-ui-react'
import { Form as FinalForm, Field, FormSpy } from 'react-final-form';
import RadioInput from '../../common/form/RadioInput';
import TextInput from '../../common/form/TextInput';
import { combineValidators, isNumeric } from 'revalidate';
import { differenceInYears } from 'date-fns/esm';
import BodyMassIndex from './BodyMassIndex';
import { Desktop } from '../../layout/Responsive';
import { RootStoreContext } from '../../stores/rootStore';

interface IDCEParams {
    alf?: number;
    weightInKgs: number;
    heightInCms: number;
    age: number;
    gender: string;
}

interface IGoalSettingParams {
    foodConsumption?: number;
    activeCalorieExpenditure?: number;
    timeLineInWeeks?: number;
}

interface IProps {
    dateOfBirth: Date;
    heightInCms: number;
    weightInKgs: number;
    gender: string;
    isClient: boolean;
}

const validate = combineValidators({
    weightInKgs: isNumeric({ message: 'Must be numeric' }),
    heightInCms: isNumeric({ message: 'Must be numeric' }),
    age: isNumeric({ message: 'Must be numeric' })
})

const validateGoalSetting = combineValidators({
    foodConsumption: isNumeric({ message: 'Must be numeric' }),
    activeCalorieExpenditure: isNumeric({ message: 'Must be numeric' }),
    timeLineInWeeks: isNumeric({ message: 'Must be numeric' })
})

const DailyCalorieExpenditure: React.FC<IProps> = ({ dateOfBirth, heightInCms, weightInKgs, gender, isClient }) => {

    const rootStoreContext = useContext(RootStoreContext);
    const { toggleSideMenu } = rootStoreContext.responsiveStore;

    const onSubmit = values => { }

    const [DCE, setDCE] = useState(0);
    const [totalCalorieBurn, setTotalCalorieBurn] = useState(0);
    const [totalBurnout, setTotalBurnout] = useState(0);
    const [BMI, setBMI] = useState('0');
    const [goalSettingParams, setGoalSettingParams] = useState({} as IGoalSettingParams);
    const [bmiResult, setBmiResult] = useState('');
    const [initParams, setInitParams] = useState({} as IDCEParams);

    const calculateDCE = (values: IDCEParams) => {
        const gender = values.gender;
        let dce: number = 0;

        if (gender) {
            if (!isNaN(values.alf!) && !isNaN(values.heightInCms) && !isNaN(values.weightInKgs) && !isNaN(values.age)) {
                if (gender === 'M') {
                    dce = values.alf! * ((13.75 * values.weightInKgs) + (5 * values.heightInCms) - (6.76 * values.age) + 66);
                    setDCE(Math.round(dce));
                } else {
                    dce = values.alf! * ((9.56 * values.weightInKgs) + (1.85 * values.heightInCms) - (4.68 * values.age) + 655);
                    setDCE(Math.round(dce));
                }
            }

            if (!isClient) {
                calculateGoalSetting(goalSettingParams, dce);
            }
        }

        calculateBMI(values.weightInKgs, values.heightInCms);
    }

    const getBMIStandards = (bmi) => {
        if (+bmi < 18.5) {
            setBmiResult('Underweight (< 18.5)');
        } else if (+bmi >= 18.5 && +bmi <= 24.9) {
            setBmiResult('Normal (18.5 - 24.9)');
        } else if (+bmi >= 25.0 && +bmi <= 29.9) {
            setBmiResult('Overweight (25.0 - 29.9)');
        } else if (+bmi >= 30.0 && +bmi <= 34.9) {
            setBmiResult('Obesity, Class I (30.0 - 34.9)');
        } else if (+bmi >= 35.0 && +bmi <= 39.9) {
            setBmiResult('Obesity, Class II (35.0 - 39.9)');
        } else if (+bmi >= 40.0) {
            setBmiResult('Extreme Obesity, Class III (> 40)');
        }
    }

    const calculateBMI = (weightInKgs: number, heightInCms: number) => {
        if (!isNaN(weightInKgs) && !isNaN(heightInCms)) {
            const weightInLbs = (weightInKgs * 2.2046);
            const heightInInches = (heightInCms * 0.393701);
            const bmi = ((weightInLbs / (heightInInches * heightInInches)) * 703).toFixed(1);
            setBMI(bmi);
            getBMIStandards(bmi);
        }
    }

    const calculateGoalSetting = (values: IGoalSettingParams, dce: number) => {
        let totalCalorieBurn = 0;
        if (!isNaN(dce) && dce > 0 && !isNaN(values.activeCalorieExpenditure!) && !isNaN(values.foodConsumption!)) {
            totalCalorieBurn = +dce + +values.activeCalorieExpenditure! - +values.foodConsumption!;
            setTotalCalorieBurn(Math.round(totalCalorieBurn));

            if (!isNaN(values.timeLineInWeeks!)) {
                const goalWeight = (+totalCalorieBurn * (+values.timeLineInWeeks! * 7)) / 7000;
                setTotalBurnout(Math.round(goalWeight));
            } else {
                setTotalBurnout(0);
            }
        } else {
            setTotalCalorieBurn(0);
        }
    }

    useEffect(() => {
        const age = differenceInYears(new Date(), new Date(dateOfBirth));

        if (isClient) {
            toggleSideMenu(false);
            const params: IDCEParams = { age: age, weightInKgs: weightInKgs, heightInCms: heightInCms, gender: gender };
            setInitParams(params);
            calculateBMI(weightInKgs, heightInCms);
        }
    }, [weightInKgs, heightInCms, gender, dateOfBirth, isClient, toggleSideMenu])


    return (
        <Fragment>
            <Segment raised clearing>
                <Label ribbon content='Daily Calorie Expenditure' color='black' />
                <br /><br />
                <FinalForm
                    onSubmit={onSubmit}
                    validate={validate}
                    initialValues={initParams}
                    render={() => (
                        <Form autoComplete='off'>
                            <FormSpy
                                subscription={{ values: true, valid: true }}
                                onChange={(state) => {
                                    calculateDCE(state.values as IDCEParams);
                                }}
                            />
                            <Grid>
                                <Grid.Column width={9}>
                                    <Form.Group widths='equal'>
                                        <Field
                                            name='weightInKgs'
                                            label='Weight (in kgs)'
                                            component={TextInput}
                                            maxlength={3}
                                            placeholder='In kgs'
                                            readOnly={isClient}
                                        />
                                        <Field
                                            name='heightInCms'
                                            label='Height (in cms)'
                                            component={TextInput}
                                            maxlength={3}
                                            placeholder='In cms'
                                            readOnly={isClient}
                                        />
                                        <Field
                                            name='age'
                                            label='Age (In years)'
                                            component={TextInput}
                                            maxlength={2}
                                            placeholder='In years'
                                            readOnly={isClient}
                                        />
                                    </Form.Group>
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    {!isClient &&
                                        <Form.Group inline widths='equal' style={{ marginTop: 30 }}>
                                            <Field name='gender' component={RadioInput} value="M" label='Male' type='radio' />
                                            <Field name='gender' component={RadioInput} value="F" label='Female' type='radio' />
                                        </Form.Group>
                                    }
                                </Grid.Column>
                            </Grid>
                            <Form.Group inline widths='equal' style={{ marginTop: 20 }}>
                                <Field name='alf' component={RadioInput} value="1.2" label='Sedentary' type='radio' />
                                <Field name='alf' component={RadioInput} value="1.375" label='Lightly Active' type='radio' />
                                <Field name='alf' component={RadioInput} value="1.55" label='Moderately Active' type='radio' />
                                <Field name='alf' component={RadioInput} value="1.725" label='Very Active' type='radio' />
                                <Field name='alf' component={RadioInput} value="1.9" label='Extremely Active' type='radio' />
                            </Form.Group>
                            <Divider />
                            <Statistic horizontal floated='right' style={{ marginRight: 30 }}>
                                <Statistic.Value>{DCE}</Statistic.Value>
                                <Statistic.Label>Calories Per Day</Statistic.Label>
                            </Statistic>
                        </Form>
                    )}
                />
            </Segment>
            {!isClient &&
                <Segment raised clearing>
                    <Label ribbon color='black' content='Goal Setting' />
                    <br /><br />
                    <FinalForm
                        onSubmit={onSubmit}
                        validate={validateGoalSetting}
                        initialValues={goalSettingParams}
                        render={({ form }) => (
                            <Form autoComplete='off'>
                                <FormSpy
                                    subscription={{ values: true, valid: true }}
                                    onChange={(state) => {
                                        calculateGoalSetting(state.values as IGoalSettingParams, DCE);

                                        if (form.getState().dirty) {
                                            setGoalSettingParams(state.values as IGoalSettingParams);
                                        }
                                    }}
                                />
                                <Grid>
                                    <Grid.Column width={10}>
                                        <Form.Group widths='equal'>
                                            <Field
                                                name='foodConsumption'
                                                component={TextInput}
                                                label='Food Consumption'
                                                placeholder='In calories'
                                                maxlength={4}
                                            />
                                            <Field
                                                name='activeCalorieExpenditure'
                                                component={TextInput}
                                                label='Active Calorie Expenditure'
                                                placeholder='In calories'
                                                maxlength={4}
                                            />
                                            <Field
                                                name='timeLineInWeeks'
                                                component={TextInput}
                                                label='Timeline in Weeks'
                                                placeholder='In Weeks'
                                                maxlength={2}
                                            />
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                                <Divider />
                                <Grid>
                                    <Grid.Column width={5}>
                                        <Statistic floated='left' style={{ marginRight: 20 }}>
                                            <Statistic.Value>{totalCalorieBurn}</Statistic.Value>
                                            <Statistic.Label>Total Calorie Burn</Statistic.Label>
                                        </Statistic>
                                    </Grid.Column>
                                    <Grid.Column width={7} />
                                    <Grid.Column width={4}>
                                        <Statistic floated='right' style={{ marginRight: 20 }}>
                                            <Statistic.Value>{totalBurnout}</Statistic.Value>
                                            <Statistic.Label>Total Burnout in KG</Statistic.Label>
                                        </Statistic>
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        )}
                    />
                </Segment>}
            <Desktop>
                <BodyMassIndex bmi={BMI} standard={bmiResult} isClient={isClient} />
            </Desktop>
        </Fragment>
    )
}

export default DailyCalorieExpenditure;
