import { observable, computed, action, runInAction } from "mobx";
import { RootStore } from "./rootStore";
import { history } from "../..";
import agent from "../api/agent";
import { IUserFormValues, IUser, IPasswordReset } from "../models/user.model";
import jwt from "jsonwebtoken";
import { IUserProfile, IUserBasicProfile } from "../models/user-profile.model";
import alertify from "../common/util/alertify";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";

export default class UserStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable user: IUser | null = null;
  @observable role: string | null = null;
  @observable uId: string | null = null;
  @observable uploadingPhoto: boolean = false;
  @observable deletingPhoto: boolean = false;
  @observable deletingUser: boolean = false;
  @observable userProfile: IUserBasicProfile = {};
  @observable submittingSecQuestionRequest: boolean = false;
  @observable fetchingSecQuestion: boolean = false;
  @observable securityQuestionId: number | null = null;
  @observable loadingSecurityQuestion: boolean = false;
  @observable passwordReset: IPasswordReset = {};
  @observable.ref hubConnection: HubConnection | null = null;
  @observable appVersion: string = 'v1.3.12';

  @action createHubConnection = () => {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_API_CHAT_URL!, {
        accessTokenFactory: () => this.rootStore.commonStore.token!,
      })
      .configureLogging(LogLevel.Information)
      .build();

    this.hubConnection
      .start()
      .then(() => console.log(this.hubConnection?.state))
      .catch((error) => console.log("Error establishing connection: ", error));

    this.hubConnection.on("LockUser", (comment) => {
      if (comment === 'LOCK') {
        this.logout();
      }
    });
  };

  @action stopHubConnection = () => {
    this.hubConnection?.stop()
      .then(() => console.log(this.hubConnection?.state))
      .catch((error) => console.log("Error stopping connection: ", error));
  };

  @computed get isLoggedIn() {
    return !!this.user;
  }

  @computed get userRole(): string {
    return this.role!;
  }

  @computed get userId(): string {
    return this.uId!;
  }

  @computed get isUserAdmin(): boolean {
    return this.role?.toLowerCase() === "admin";
  }

  @computed get isUserTrainer(): boolean {
    return this.role?.toLowerCase() === "trainer";
  }

  @computed get isUserClient(): boolean {
    return this.role?.toLowerCase() === "client";
  }

  @action login = async (values: IUserFormValues) => {
    try {
      const user = await agent.User.login(values);

      runInAction(() => {
        this.user = user;
        this.userProfile = user.profile!;
        this.role = this.getUserRole(user.token);
        this.uId = this.getUserId(user.token);
      });

      this.rootStore.commonStore.setToken(user.token);
      this.rootStore.commonStore.setRefreshToken(user.refreshToken);
      this.rootStore.modalStore.closeModal();
      history.push("/home");
    } catch (error) {
      throw error;
    }
  };

  @action register = async (values: IUserFormValues) => {
    try {
      await agent.User.register(values);
      this.rootStore.modalStore.closeModal();
      history.push(`/user/registerSuccess?email=${values.email}`);
    } catch (error) {
      throw error;
    }
  };

  @action deleteUser = async (id: string) => {
    try {
      this.deletingUser = true;
      await agent.User.deleteUser(id);
      alertify.success("User has been deleted successfully!");

      runInAction(() => {
        this.deletingUser = false;
      });
    } catch (error) {
      alertify.error("Problem occurred while deleting the user");
      runInAction(() => {
        this.deletingUser = false;
      });
    }
  };

  @action getUser = async () => {
    try {
      const user = await agent.User.currentUser();

      runInAction(() => {
        this.user = user;
        this.userProfile = user.profile!;
        this.role = this.getUserRole(user.token);
        this.uId = this.getUserId(user.token);
      });
    } catch (error) {
      throw error;
    }
  };

  @action logout = () => {
    this.rootStore.commonStore.setToken(null);
    this.rootStore.commonStore.setRefreshToken(null);
    this.user = null;
    this.rootStore.dietInfoStore.clearCurrentDiet();
    this.rootStore.responsiveStore.isSideMenuVisible = false;
    history.push("/");
  };

  getUserRole = (token: string): string => {
    const decodedToken: any = jwt.decode(token);
    return decodedToken?.role;
  };

  getUserId = (token: string): string => {
    const decodedToken: any = jwt.decode(token);
    return decodedToken?.nameid;
  };

  @action uploadPhoto = async (file: Blob) => {
    this.uploadingPhoto = true;

    try {
      const photo = await agent.User.uploadPhoto(file);

      runInAction(() => {
        if (this.user) {
          this.user.imageUrl = photo.url;
          this.user.imageId = photo.id;
          this.uploadingPhoto = false;
        }
      });
    } catch (error) {
      const err = error.data?.errors?.File;
      console.log(err);
      alertify.error(err ? err : "Problem occurred while uploading photo");

      runInAction(() => {
        this.uploadingPhoto = false;
      });
    }
  };

  @action deletePhoto = async (id: string) => {
    this.deletingPhoto = true;
    try {
      await agent.User.deletePhoto(id);

      runInAction(() => {
        this.user!.imageUrl = null;
        this.user!.imageId = null;
        this.deletingPhoto = false;
      });
    } catch (error) {
      alertify.error("Problem occurred while deleting the photo");
      runInAction(() => {
        this.deletingPhoto = false;
      });
    }
  };

  @action saveUserProfile = async (profile: IUserProfile) => {
    try {
      await agent.User.setProfile(profile);

      runInAction(() => {
        this.user!.isProfileCompleted = true;
        this.userProfile = profile;
        alertify.success("Your profile data been saved");
      });
    } catch (error) {
      alertify.error("Error saving profile data!");
    }
  };

  @action getUserProfile = async () => {
    try {
      return await agent.User.getProfile(this.uId!);
    } catch (error) {
      if (error.status !== 400) {
        alertify.error("Error retreiving profile data!");
      }
    }
  };

  @action getUsersByRole = async (role: string) => {
    try {
      return await agent.User.getUsersByRole(role);
    } catch (error) {
      alertify.error(error);
    }
  };

  @action activateUser = async (id: string) => {
    try {
      return await agent.User.activateUser(id);
    } catch (error) {
      alertify.error(error);
    }
  };

  @action deactivateUser = async (id: string) => {
    try {
      return await agent.User.deactivateUser(id);
    } catch (error) {
      alertify.error(error);
    }
  };

  @action setSecurityQuestion = async (request: any) => {
    this.submittingSecQuestionRequest = true;
    try {
      await agent.User.setSecurityQuestion(request);

      alertify.success("Security Question saved");

      runInAction(() => {
        this.user!.isSecurityQuestionSet = true;
        this.submittingSecQuestionRequest = false;
      });
    } catch (error) {
      alertify.error(error);

      runInAction(() => {
        this.submittingSecQuestionRequest = false;
      });
    }
  };

  @action resetPassword = async (request: IPasswordReset) => {
    this.loadingSecurityQuestion = true;
    try {
      if (!this.passwordReset.securityQuestionId) {
        this.passwordReset.email = request.email;
        const quesId = await agent.User.getSecurityQuestionId(request.email!);

        runInAction(() => {
          this.loadingSecurityQuestion = false;
          this.passwordReset.securityQuestionId = quesId;
        });
      } else {
        this.passwordReset.answer = request.answer;
        await agent.User.resetPassword(this.passwordReset);
        alertify.info("Please check your email for reset link");
        this.rootStore.modalStore.closeModal();

        runInAction(() => {
          this.loadingSecurityQuestion = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.loadingSecurityQuestion = false;
      });
      throw error;
    }
  };

  @action cleanupPasswordReset = () => {
    this.passwordReset = {};
  };
}
