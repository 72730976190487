import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite';
import { Grid, Message } from 'semantic-ui-react';
import { RootStoreContext } from '../../../stores/rootStore';
import ClientHeader from './ClientHeader';
import LoadingComponent from '../../../layout/LoadingComponent';
import { RouteComponentProps } from 'react-router-dom';
import AssignDiet from './AssignDiet';
import Tracker from '../track/Tracker';


interface DetailParams {
    id: string
}

const ClientPage: React.FC<RouteComponentProps<DetailParams>> = ({ match }) => {

    const rootStoreContext = useContext(RootStoreContext);
    const {
        getClientDetail,
        loadingDetail,
        loadingDietAssignment,
        assignedDiet,
        assigningDiet,
        cleanup,
    } = rootStoreContext.clientStore;

    const { isUserClient } = rootStoreContext.userStore;

    useEffect(() => {
        if (match.params.id) {
            getClientDetail(match.params.id);
        }

        return () => {
            cleanup();
        }
    }, [getClientDetail, match.params.id, cleanup])

    if (loadingDetail || !match.params.id || (loadingDietAssignment && assigningDiet)) return <LoadingComponent content={`Fetching ${isUserClient ? 'your diet' : 'Client'} information...`} />

    if (isUserClient && !assignedDiet.id && !loadingDietAssignment) {
        return <Message
            icon='food'
            header={isUserClient ? 'You do not have any Diet assigned!' : 'Diet Information not found!'}
            content={isUserClient ? 'Please check with your nutritionist for more details' : 'Please verify if the Diet Id is valid'}
        />
    }

    return (
        <Grid>
            <Grid.Column width={16}>
                <ClientHeader />
                <AssignDiet />
                <Tracker />
            </Grid.Column>
        </Grid>
    )
}

export default observer(ClientPage);
