import { FORM_ERROR } from 'final-form';
import React, { Fragment, useContext, useEffect } from 'react'
import { Form as FinalForm, Field } from 'react-final-form';
import { combineValidators, composeValidators, isRequired, hasLengthBetween, matchesField } from 'revalidate'
import { Button, Form, Header, Label, Segment } from 'semantic-ui-react'
import agent from '../../api/agent';
import TextInput from '../../common/form/TextInput'
import { passwordComplexity } from '../../common/util/validators'
import { useMediaQuery } from 'react-responsive';
import { ErrorMessage } from '../../common/form/ErrorMessage';
import { RootStoreContext } from '../../stores/rootStore';
import alertify from '../../common/util/alertify';
import { Desktop, Mobile } from '../../layout/Responsive';
import { MAX_DEVICE_WIDTH } from '../../common/util/util';

const validate = combineValidators({
    currentPassword: isRequired('Current Password'),
    newPassword: composeValidators(isRequired, hasLengthBetween(8, 20), passwordComplexity)('Password'),
    confirmPassword: composeValidators(isRequired, matchesField('newPassword', 'Password')({ message: 'Should match with Password' }))('Confirm Password')
});


const ChangePasswordPage = () => {

    const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: MAX_DEVICE_WIDTH });
    const rootStoreContext = useContext(RootStoreContext);
    const { closeModal } = rootStoreContext.modalStore;
    const { toggleSideMenu } = rootStoreContext.responsiveStore;

    const submitForm = () => <FinalForm
        validate={validate}
        onSubmit={(values) => agent.User.changePassword(values).then(() => {
            alertify.success('Your password has been changed');
            closeModal();
        }).catch(error => ({
            [FORM_ERROR]: error
        }))}
        render={({ handleSubmit, submitting, submitError, submitSucceeded, form: { reset } }) => (
            <Form onSubmit={handleSubmit} error>
                <br />
                <Field
                    name='currentPassword'
                    component={TextInput}
                    placeholder='Enter Current Password'
                    type='password'
                    maxlength={20}
                    label='Current Password'
                    disabled={submitSucceeded}
                />
                <Field
                    name='newPassword'
                    component={TextInput}
                    placeholder='Enter New Password'
                    type='password'
                    maxlength={20}
                    label='New Password'
                    disabled={submitSucceeded}
                />
                <Field
                    name='confirmPassword'
                    component={TextInput}
                    placeholder='Enter Confirm Password'
                    type='password'
                    maxlength={20}
                    label='Confirm Password'
                    disabled={submitSucceeded}
                />
                {submitError && <ErrorMessage error={submitError} text='Oops!' />}
                <br />
                <Button color='blue' fluid={isTabletOrMobileDevice ? true : false} loading={submitting} content='Submit' disabled={submitSucceeded} />
                {!isTabletOrMobileDevice && <Button secondary content='Close' type='button' onClick={closeModal} />}
            </Form>
        )}
    />

    useEffect(() => {
        if (isTabletOrMobileDevice) {
            toggleSideMenu(false);
        }

    }, [isTabletOrMobileDevice, toggleSideMenu])

    return (
        <Fragment>
            <Desktop>
                <Segment raised>
                    <Label ribbon content='Change Password' color='black' />
                    {submitForm()}
                </Segment>
            </Desktop>
            <Mobile>
                <Header as='h3' dividing content='Change Password' />
                {submitForm()}
            </Mobile>
        </Fragment>
    )
}

export default ChangePasswordPage;
