import React from 'react'
import { Segment, Label, Form } from 'semantic-ui-react'
import { Field } from 'react-final-form'
import TextInput from '../../../common/form/TextInput'
import { observer } from 'mobx-react-lite'


interface IProps {
    skinfoldSum?: number;
    isReadOnly: boolean;
}

const SkinFoldMeasurements: React.FC<IProps> = ({ skinfoldSum, isReadOnly }) => {
    return (
        <Segment raised color='red'>
            <Label content='Skin Fold Measurements' ribbon color='blue' />
            <Form.Group widths='equal' style={{ marginTop: 20 }}>
                <Field
                    component={TextInput}
                    name='skinfold.abdominals'
                    label='Abdominals'
                    placeholder='In mm'
                    maxlength={3}
                    readOnly={isReadOnly}
                />
                <Field
                    component={TextInput}
                    name='skinfold.tricep'
                    label='Tricep'
                    placeholder='In mm'
                    maxlength={3}
                    readOnly={isReadOnly}
                />
                <Field
                    component={TextInput}
                    name='skinfold.chest'
                    label='Chest'
                    placeholder='In mm'
                    maxlength={3}
                    readOnly={isReadOnly}
                />
                <Field
                    component={TextInput}
                    name='skinfold.midAxillary'
                    label='Mid Axillary'
                    placeholder='In mm'
                    maxlength={3}
                    readOnly={isReadOnly}
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <Field
                    component={TextInput}
                    name='skinfold.subscapular'
                    label='Subscapular'
                    placeholder='In mm'
                    maxlength={3}
                    readOnly={isReadOnly}
                />
                <Field
                    component={TextInput}
                    name='skinfold.suprailiac'
                    label='Suprailiac'
                    placeholder='In mm'
                    maxlength={3}
                    readOnly={isReadOnly}
                />
                <Field
                    component={TextInput}
                    name='skinfold.thigh'
                    label='Thigh'
                    placeholder='In mm'
                    maxlength={3}
                    readOnly={isReadOnly}
                />
                <Form.Field>
                    <Label size='medium' style={{ marginTop: 30 }} color='black'>
                        Sum of Skinfold <Label.Detail>{!isNaN(skinfoldSum!) ? skinfoldSum : 0}</Label.Detail>
                    </Label>
                </Form.Field>
            </Form.Group>
        </Segment>
    )
}

export default observer(SkinFoldMeasurements);
