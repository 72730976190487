import React from 'react';
import { Table } from 'semantic-ui-react';

const TableHeader = () => {
    return (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>Progress 1</Table.HeaderCell>
                <Table.HeaderCell>Progress 2</Table.HeaderCell>
                <Table.HeaderCell>Result</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    )
}

export default TableHeader
