import axios, { AxiosResponse } from "axios";
import { history } from "../..";
import { IDietInfo } from "../models/diet-info.model";
import { IUserFormValues, IUser, IPasswordReset, IPasswordResetVerification } from "../models/user.model";
import { toast } from "react-toastify";
import { IPhoto } from "../models/photo.model";
import { IUserProfile } from "../models/user-profile.model";
import { IUserInfo } from "../models/user-info.model";
import { IRegistrationCode } from "../models/registration-code.model";
import alertify from "../common/util/alertify";
import { IClientDetail } from "../models/client-detail.model";
import { IAssignDiet } from "../models/assign-diet.model";
import { IDietBasicInfo } from "../models/diet-basic-info.model";
import { ITrackDietStatus, ILoggedDietStatus } from "../models/track-diet-status.model";
import { IProgressTracker } from "../models/progress-tracker.model";
import { IDashboard } from "../models/dashboard.model";
import { IClientHistory } from "../models/client-history.model";
import { IResponsiveClientDetail } from "../models/responsive-client-detail.model";
import { IAdminDashboard } from "../models/admin-dashboard.model";


axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(undefined, (error) => {
  const originalRequest = error.config;

  if (error.message === "Network Error" && !error.response) {
    toast.error("Network error - make sure API is running!");
  }

  const { status, data, config } = error.response;

  if (status === 404) {
    history.push("/not-found");
  }

  if (status === 401 && originalRequest.url.endsWith("refresh")) {
    window.sessionStorage.removeItem("jwt");
    window.sessionStorage.removeItem("refreshToken");
    history.push("/");
    alertify.info(data.errors.Message);
    return Promise.reject(error);
  }

  if (status === 401 && !originalRequest._retry && !originalRequest.url.endsWith("login")) {
    originalRequest._retry = true;

    return axios
      .post("user/refresh", {
        token: window.sessionStorage.getItem("jwt"),
        refreshToken: window.sessionStorage.getItem("refreshToken"),
      })
      .then((res) => {
        window.sessionStorage.setItem("jwt", res.data.token);
        window.sessionStorage.setItem("refreshToken", res.data.refreshToken);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.token}`;
        return axios(originalRequest);
      })
  }

  if (
    status === 400 &&
    config.method === "get" &&
    data.errors.hasOwnProperty("id")
  ) {
    history.push("/not-found");
  }

  if (status === 500) {
    toast.error("Server error - check the terminal for more info!");
  }

  throw error.response;
});

axios.interceptors.request.use(
  (config) => {
    const token = window.sessionStorage.getItem("jwt");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const sleep = (ms: number) => (response: AxiosResponse) =>
  new Promise<AxiosResponse>((resolve) =>
    setTimeout(() => resolve(response), ms)
  );

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
  get: (url: string) => axios.get(url).then(sleep(0)).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(sleep(0)).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(sleep(0)).then(responseBody),
  delete: (url: string) => axios.delete(url).then(sleep(0)).then(responseBody),
  postForm: (url: string, file: Blob) => {
    let formData = new FormData();
    formData.append("File", file);
    return axios
      .post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(responseBody);
  },
};

const User = {
  currentUser: (): Promise<IUser> => requests.get("/user"),
  login: (user: IUserFormValues): Promise<IUser> => requests.post(`/user/login`, user),
  register: (user: IUserFormValues): Promise<IUser> => requests.post(`/user/register`, user),
  changePassword: (request) => requests.post(`/user/changePassword`, request),
  resetPassword: (request: IPasswordReset) => requests.post(`/user/resetPassword`, request),
  resetPasswordVerification: (request: IPasswordResetVerification) => requests.post(`/user/resetPasswordVerification`, request),
  refreshToken: (token: string, refreshToken: string) => {
    return axios.post(`/user/refresh`, { token, refreshToken }).then((res) => {
      window.sessionStorage.setItem("jwt", res.data.token);
      window.sessionStorage.setItem("refreshToken", res.data.refreshToken);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${res.data.token}`;
      return res.data.token;
    });
  },
  uploadPhoto: (photo: Blob): Promise<IPhoto> => requests.postForm(`/photos`, photo),
  deletePhoto: (id: string) => requests.delete(`/photos/${id}`),
  setProfile: (profile: IUserProfile) => requests.post(`/user/profile`, profile),
  updateProfile: (profile: IUserProfile) => requests.put(`/user/profile`, profile),
  getProfile: (id: string): Promise<IUserProfile> => requests.get(`/user/profile/${id}`),
  getUsersByRole: (role: string): Promise<IUserInfo[]> => requests.get(`/user/role/${role}`),
  activateUser: (id: string): Promise<any> => requests.get(`/user/activate/${id}`),
  deactivateUser: (id: string): Promise<any> => requests.delete(`/user/activate/${id}`),
  deleteUser: (id: string): Promise<IUserProfile> => requests.delete(`/user/${id}`),
  verifyEmail: (token: string, email: string): Promise<void> => requests.post('user/verifyEmail', { token, email }),
  resendVerifyEmailConfirm: (email: string): Promise<void> => requests.post(`user/resendEmailVerification`, { email }),
  setSecurityQuestion: (request: any): Promise<void> => requests.post(`/user/securityQuestion`, request),
  getSecurityQuestion: (): Promise<{ securityQuestionId: number, answer: string }> => requests.get(`/user/securityQuestion`),
  getSecurityQuestionId: (email: string): Promise<number> => requests.get(`/user/securityQuestionId?email=${email}`)
};


const DietInfo = {
  list: () => requests.get('/dietinfo'),
  details: (id: string): Promise<IDietInfo> => requests.get(`/dietinfo/${id}`),
  create: (dietInfo: IDietInfo) => requests.post("/dietinfo", dietInfo),
  update: (dietInfo: IDietInfo) => requests.put(`/dietinfo`, dietInfo),
  delete: (id: string) => requests.delete(`/dietinfo/${id}`),
  search: (name: string): Promise<IDietBasicInfo[]> => requests.post('/dietinfo/search', {name: name}),
  forClient: (id: string): Promise<IDietInfo> => requests.get(`/client/assignedDiet/${id}?requestDate=${new Date().toLocaleDateString('en-US')}`),
  generateReport: (id: string): Promise<string> => requests.get(`/dietinfo/report/${id}`)
};

const Code = {
  list: (): Promise<IRegistrationCode[]> => requests.get('/client/codes'),
  generate: (length: number, expiryInMins: number): Promise<IRegistrationCode> => requests.get(`/client/code?length=${length}&expiryMins=${expiryInMins}`),
  delete: (id: string) => requests.delete(`/client/code/${id}`)
}

const Client = {
  detail: (id: string): Promise<IClientDetail> => requests.get(`/client/${id}?requestDate=${new Date().toLocaleDateString('en-US')}`),
  history: (id: string): Promise<IClientHistory> => requests.get(`/client/history/${id}?requestDate=${new Date().toLocaleDateString('en-US')}`),
  assignDiet: (assignDiet: IAssignDiet): Promise<IAssignDiet> => requests.post('/client/assignDiet', assignDiet),
  deleteAssignedDiet: (id: string) => requests.delete(`/client/assignDiet/${id}?endDate=${new Date().toLocaleDateString('en-US')}`),
  getDietAssignment: (id: string): Promise<IAssignDiet> => requests.get(`/client/dietAssignment/${id}`),
  logDietStatus: (status: ITrackDietStatus): Promise<ILoggedDietStatus> => requests.post('/client/logDietStatus', status),
  deleteLoggedDietStatus: (id: string) => requests.delete(`/client/logDietStatus/${id}`),
  getLoggedDietStatus: (assignedDietInfoId: string, assignedToUserId: string): Promise<ITrackDietStatus[]> => requests.post('/client/loggedDietStatus', { assignedDietInfoId: assignedDietInfoId, assignedToUserId: assignedToUserId}),
  trackProgress: (progress: IProgressTracker): Promise<string> => requests.post('/client/trackProgress', progress),
  getProgressTrack: (id: string): Promise<IProgressTracker> => requests.get(`/client/trackProgress/${id}`),
  deleteProgressTrack: (id: string) => requests.delete(`/client/trackProgress/${id}`),
  dashboard: (date: string): Promise<IDashboard> => requests.post(`/client/dashboard`, { date: new Date(date) }),
  adminDashboard: (): Promise<IAdminDashboard[]> => requests.get(`/client/adminDashboard?requestDate=${new Date().toLocaleDateString('en-US')}`),
  getProgressTracks: (request: { progress1: string, progress2: string}): Promise<{progressTrack1: IProgressTracker, progressTrack2: IProgressTracker}> => requests.post('client/progressTracks', request),
  notify: (request): Promise<void> => requests.post(`/client/notify`, request)
}

const Responsive = {
  clientDetail: (id: string): Promise<IResponsiveClientDetail> => requests.get(`/responsive/clientDetail/${id}?requestDate=${new Date().toLocaleDateString('en-US')}`)
}

export default {
  DietInfo,
  User,
  Code,
  Client,
  Responsive
};
