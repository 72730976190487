import React, { useContext } from 'react'
import { Modal } from 'semantic-ui-react'
import { RootStoreContext } from '../../stores/rootStore'
import { observer } from 'mobx-react-lite';

const ModalContainer = () => {
    const rootStore = useContext(RootStoreContext);
    const { modal: { open, body, size }, closeModal } = rootStore.modalStore;


    return (
        <div>
            <Modal open={open} onClose={closeModal} size={size === 'small' ? 'small' : size === 'large' ? 'large' : size === 'mini' ? 'mini' : 'tiny'}>
                <Modal.Content>
                    {body}
                </Modal.Content>
            </Modal>
        </div>
    )
}

export default observer(ModalContainer);
