import React, { useContext, Fragment } from 'react'
import { Container, Segment, Header, Button, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { RootStoreContext } from '../../stores/rootStore';
import LoginForm from '../user/LoginForm';
import { RegisterForm } from '../user/RegisterForm';

const HomePage = () => {

    const token = window.sessionStorage.getItem('jwt');
    const rootStore = useContext(RootStoreContext);
    const { isLoggedIn, user } = rootStore.userStore;
    const { openModal } = rootStore.modalStore;

    return (
        <Segment inverted vertical className='masthead' textAlign='center'>
            <Container>
                <Header as='h1' inverted >
                    <Image src='/assets/MQ_01.png' alt='logo' style={{ marginBottom: 35, }} />
                </Header>
                {isLoggedIn && token && user ? (
                    <Fragment>
                        <Header as='h2' inverted content={`Welcome back ${user.displayName}`} />
                        <Button as={Link} to='/home' size='huge' inverted>Go to Dashboard!</Button>
                    </Fragment>
                ) : (
                        <Fragment>
                            <div style={{ textAlign: 'center' }}>
                                <Header as='h2' inverted content='Mob Queen Nutrition' />
                                <Button onClick={() => openModal(<LoginForm />)} size='huge' inverted>Login</Button>
                                <Button onClick={() => openModal(<RegisterForm />)} size='huge' inverted>Register</Button>
                            </div>
                        </Fragment>
                    )
                }
            </Container>
        </Segment >
    )


}

export default HomePage;
