import _ from 'lodash';
import React, { Fragment, useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Tab, Form, Divider, Grid, Button, Icon, Confirm, Header, Search } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import DietTab from './DietTab';
import TextInput from '../../../common/form/TextInput';
import { TextAreaInput } from '../../../common/form/TextAreaInput';
import { RootStoreContext } from '../../../stores/rootStore';
import { IDietInfo } from '../../../models/diet-info.model';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import LoadingComponent from '../../../layout/LoadingComponent';
import RichTextInput from '../../../common/form/RichTextInput';


const addTabPanes = (): any => {
    const panes: any = [];
    const days: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const meals: string[] = ['Breakfast', 'Lunch', 'Dinner'];

    (days.forEach((day, index) => {
        panes.push({
            menuItem: day,
            render: () => <Tab.Pane attached={false} key={day}>
                {
                    meals.map((meal) => (
                        <Fragment key={`${day}_${meal}_${index}`}>
                            <DietTab day={day} meal={meal} />
                            <Divider />
                        </Fragment>
                    ))
                }
            </Tab.Pane>
        })
    }));

    panes.push({
        menuItem: 'Market List',
        render: () => <Tab.Pane attached={false} key='list'>
            <Field
                name='marketList'
                component={RichTextInput}
                label='Market List'
            />
        </Tab.Pane>
    })

    panes.push({
        menuItem: 'Instructions',
        render: () => <Tab.Pane attached={false} key='instruction'>
            <Field
                name='instructions'
                component={RichTextInput}
                label='Instructions'
            />
        </Tab.Pane>
    })

    return panes;
}

interface DetailParams {
    id: string
}

const AddDietPage: React.FC<RouteComponentProps<DetailParams>> = ({ match, history }) => {

    const rootStore = useContext(RootStoreContext);

    const {
        createDiet,
        isSubmittingDiet,
        currentDietInfo,
        currentDietInfoId,
        isDeletingDiet,
        deleteDiet,
        getDietInfo,
        clearCurrentDiet,
        searchDiets,
        searchingDiets,
        searchDietsResult,
    } = rootStore.dietInfoStore;

    const { isUserClient } = rootStore.userStore;

    const dietInfoInitState = { id: '', name: '' };
    const [openConfirm, setOpenConfirm] = useState(false);
    const [loadingSearchedDiet, setLoadingSearchedDiet] = useState(false);
    const [dietInfo, setDietInfo] = useState(dietInfoInitState);

    const submittingValue = (dietInfo: IDietInfo) => {
        createDiet(dietInfo);
    }

    const deleteDietInfo = (dietInfoId: string) => {
        setOpenConfirm(false);
        deleteDiet(dietInfoId!).then(() => history.push('/diets'));
    }

    const handleResultSelect = (e, { result }) => {
        setDietInfo({ id: result.id, name: result.name });
    }

    const handleSearchChange = (e, { value }) => {
        setDietInfo({ id: '', name: value });

        setTimeout(() => {
            searchDiets(value);
        }, 300);
    }

    const clearSearchResult = () => {
        setDietInfo(dietInfoInitState);
        clearCurrentDiet();
    }

    const loadSearchedDiet = (id: string) => {
        if (dietInfo.id) {
            setLoadingSearchedDiet(true);
            getDietInfo(dietInfo.id, true).finally(() => setLoadingSearchedDiet(false));
        }
    }

    useEffect(() => {
        if (match.params.id) {
            getDietInfo(match.params.id);
        }

        return (() => {
            clearCurrentDiet();
        })
    }, [getDietInfo, clearCurrentDiet, match.params.id])

    if (match.params.id && Object.keys(currentDietInfo).length === 0) {
        return <LoadingComponent content='Loading Diet Information...' />
    }

    return (
        <Fragment>
            <Header
                as='h2'
                content={!currentDietInfo.name || dietInfo.id ? 'Add Diet' : 'Edit Diet'}
                dividing
                icon='food'
            />
            {!match.params.id &&
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={9}>
                            <div className='ui form'>
                                <div className="two fields">
                                    <div className="field">
                                        <label>Search Diets</label>
                                        <Search
                                            loading={searchingDiets}
                                            onResultSelect={handleResultSelect}
                                            onSearchChange={_.debounce(handleSearchChange, 500, {
                                                leading: true,
                                            })}
                                            results={searchDietsResult}
                                            value={dietInfo.name}
                                            placeholder='Type to search...'
                                        />
                                    </div>
                                    {
                                        dietInfo.id && <div className="field">
                                            <Button primary content='Load Diet' style={{ marginTop: 25 }} onClick={() => loadSearchedDiet(dietInfo.id)} loading={loadingSearchedDiet} />
                                            <Button secondary content='Clear Diet' style={{ marginTop: 25 }} onClick={clearSearchResult} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
            <FinalForm
                onSubmit={submittingValue}
                initialValues={currentDietInfo}
                render={({ handleSubmit, pristine }) => (
                    <Form onSubmit={handleSubmit} autoComplete='off'>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Field
                                        name='name'
                                        label='Diet Name'
                                        component={TextInput}
                                        placeholder='Enter a name for the diet'
                                        maxlength={30}
                                    />

                                    <Field
                                        name='description'
                                        label='Diet description'
                                        component={TextAreaInput}
                                        placeholder='Enter diet description'
                                        rows={3}
                                        maxlength={100}
                                    />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Button.Group style={{ marginTop: 23 }}>
                                        <Button style={{ width: 100 }} type='button' as={NavLink} to={`/diets/`}>Cancel</Button>
                                        <Button.Or />
                                        <Button positive style={{ width: 100 }} loading={isSubmittingDiet} disabled={currentDietInfoId === null && pristine}><Icon name='save' />Save</Button>
                                        {
                                            currentDietInfoId && <Fragment>
                                                <Button.Or />
                                                <Button primary style={{ width: 140 }} type='button' disabled={currentDietInfoId === null} as={NavLink} to={`/diet/${currentDietInfoId}`}><Icon name='eye' />Client View</Button>
                                            </Fragment>
                                        }
                                    </Button.Group>
                                    {
                                        currentDietInfoId && !isUserClient &&
                                        <Fragment>
                                            <Button
                                                type='button'
                                                negative
                                                style={{ width: 110, marginLeft: 8 }}
                                                loading={isDeletingDiet}
                                                onClick={() => setOpenConfirm(true)}>
                                                <Icon name='trash' />Delete
                                            </Button>
                                            <Confirm
                                                header='Delete Diet'
                                                content='Are you sure you want to delete the diet?'
                                                open={openConfirm}
                                                cancelButton='No'
                                                confirmButton="Yes"
                                                onCancel={() => setOpenConfirm(false)}
                                                onConfirm={() => deleteDietInfo(currentDietInfoId)}
                                                size='mini'
                                            />
                                        </Fragment>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <br />
                        <Tab menu={{ pointing: true, color: 'black', inverted: true }} panes={addTabPanes()} />
                        <br />
                        {/* <pre>{JSON.stringify(values, undefined, 2)}</pre> */}
                    </Form>
                )}
            />
            <br /><br /><br />
        </Fragment>
    )
}

export default observer(AddDietPage);