import React, { Fragment, useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react-lite';
import { Header, Button, Table, Label, Statistic, Form, Grid, Item, Divider } from 'semantic-ui-react';
import { IRegistrationCode } from '../../models/registration-code.model';
import agent from '../../api/agent';
import { format } from 'date-fns';
import LoadingComponent from '../../layout/LoadingComponent';
import { Form as FinalForm, Field } from 'react-final-form';
import { isNumeric, combineValidators, composeValidators, isRequired } from 'revalidate';
import TextInput from '../../common/form/TextInput';
import { useMediaQuery } from 'react-responsive';
import { Desktop, Mobile } from '../../layout/Responsive';
import { RootStoreContext } from '../../stores/rootStore';
import { MAX_DEVICE_WIDTH } from '../../common/util/util';

const RegistrationCodesPage = () => {
    const registationCodes: IRegistrationCode[] = [];

    const rootStoreContext = useContext(RootStoreContext);
    const { toggleSideMenu } = rootStoreContext.responsiveStore;

    const [codes, setCodes] = useState(registationCodes);
    const [generatingCode, setGeneratingCode] = useState(false);
    const [deletingTarget, setDeletingTarget] = useState<string | undefined>(undefined);
    const [loadingCodes, setLoadingCodes] = useState(true);
    const [deletingCode, setDeletingCode] = useState(false);
    const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: MAX_DEVICE_WIDTH });

    const validate = combineValidators({
        expiryInMins: composeValidators(isRequired, isNumeric)('Expiry Time')
    });

    useEffect(() => {

        if (isTabletOrMobileDevice) {
            toggleSideMenu(false);
        }

        agent.Code.list()
            .then(codes => {
                setCodes(codes);
            })
            .finally(() => setLoadingCodes(false));
    }, [isTabletOrMobileDevice, toggleSideMenu])

    const generateCode = (values) => {
        setGeneratingCode(true);
        agent.Code.generate(8, values.expiryInMins)
            .then(code => {
                let tCodes = [...codes];
                tCodes.push(code);
                setCodes(tCodes);
            })
            .finally(() => { setGeneratingCode(false); });
    }

    const deleteCode = (id: string) => {
        setDeletingCode(true);

        agent.Code.delete(id)
            .then(code => {
                const tCodes = codes.filter(code => code.id !== id);
                setCodes(tCodes);
            })
            .finally(() => setDeletingCode(false));
    }

    if (loadingCodes) return <LoadingComponent content='Loading OTPs...' />

    return (
        <Fragment>
            <Header
                as={isTabletOrMobileDevice ? 'h3' : 'h2'}
                content='One Time Passwords'
                subheader={isTabletOrMobileDevice ? '' : 'Generate OTP for your clients'}
                dividing
                icon='code'
            />
            <FinalForm
                validate={validate}
                onSubmit={generateCode}
                render={({ handleSubmit, invalid, form: { reset }, pristine }) => (
                    <Form onSubmit={e => {
                        handleSubmit(e);
                        reset();
                    }} autoComplete='off'>
                        <Grid divided>
                            <Grid.Row>
                                <Grid.Column width={isTabletOrMobileDevice ? 8 : 3}>
                                    <Field
                                        name='expiryInMins'
                                        component={TextInput}
                                        placeholder='Time in minutes'
                                        maxlength={3}
                                    />
                                </Grid.Column>
                                <Grid.Column width={isTabletOrMobileDevice ? 8 : 3}>
                                    <Button primary content={isTabletOrMobileDevice ? 'Generate' : 'Generate New OTP'} loading={generatingCode} disabled={invalid || pristine}></Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                )}
            />
            <Mobile>
                <Divider />
                {
                    codes && codes.map((code, index) => (
                        <Item.Group key={index}>
                            <Item>
                                <Item.Content>
                                    <Item.Header>
                                        <Statistic size='mini'>
                                            <Statistic.Value>{code.code}</Statistic.Value>
                                        </Statistic>
                                    </Item.Header>
                                    <Item.Meta>Expires on:<Label basic style={{ marginLeft: 20 }}>
                                        {format(new Date(code.expiresOn!), 'dd MMM yyyy h:mm ss a')}
                                    </Label></Item.Meta>
                                    <Item.Meta>Is Expired?:<Label color={code.isExpired ? 'red' : 'blue'} content={code.isExpired ? 'Expired' : 'Not Expired'} style={{ marginLeft: 20 }} />
                                        <Button
                                            circular
                                            size='mini'
                                            style={{ marginBottom: 20 }}
                                            name={code.id}
                                            icon='trash'
                                            color='red'
                                            onClick={e => {
                                                setDeletingTarget(e.currentTarget.name);
                                                deleteCode(code.id);
                                            }}
                                            loading={deletingCode && deletingTarget === code.id}
                                            floated='right'
                                        />
                                    </Item.Meta>
                                </Item.Content>
                            </Item>
                            <Divider />
                        </Item.Group>
                    ))
                }
                {
                    codes.length === 0 && <Label color='red' content='No Codes found' />
                }
            </Mobile>
            <Desktop>
                <Table celled>
                    <Table.Header>
                        <Table.Row textAlign='center'>
                            <Table.HeaderCell width={3}>Delete</Table.HeaderCell>
                            <Table.HeaderCell>Code</Table.HeaderCell>
                            <Table.HeaderCell width={3}>Expires On</Table.HeaderCell>
                            <Table.HeaderCell width={3}>Is Expired?</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            codes && codes.map((code, index) => (
                                <Table.Row key={index} textAlign='center'>
                                    <Table.Cell>
                                        <Button
                                            circular
                                            name={code.id}
                                            icon='trash'
                                            color='red'
                                            onClick={e => {
                                                setDeletingTarget(e.currentTarget.name);
                                                deleteCode(code.id);
                                            }}
                                            loading={deletingCode && deletingTarget === code.id}
                                        />
                                    </Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        <Statistic size='tiny'>
                                            <Statistic.Value>{code.code}</Statistic.Value>
                                        </Statistic>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Label basic>
                                            {format(new Date(code.expiresOn!), 'dd MMM yyyy h:mm ss a')}
                                        </Label>
                                    </Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        <Label color={code.isExpired ? 'red' : 'blue'} content={code.isExpired ? 'Expired' : 'Not Expired'} />
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        }
                        {
                            codes.length === 0 &&
                            <Table.Row>
                                <Table.Cell colSpan={5} textAlign='center'>
                                    <Label color='red' content='No  Codes found' />
                                </Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>
                </Table>
            </Desktop>
        </Fragment>
    )
}

export default observer(RegistrationCodesPage);
