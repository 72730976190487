import React, { useContext } from 'react'
import { Grid, Table, Header, Label, Segment, Button } from 'semantic-ui-react';
import { IDietHistory } from '../../../models/client-history.model';
import { displayFullDate } from '../../../common/util/util';
import NoncomplianceReasons from '../details/NoncomplianceReasons';
import { RootStoreContext } from '../../../stores/rootStore';

interface IProps {
    diet: IDietHistory;
}

const DietHistory: React.FC<IProps> = ({ diet }) => {

    const rootStoreContext = useContext(RootStoreContext);
    const { openModal } = rootStoreContext.modalStore;

    const showNoncomplianceReasons = (ncReasons) => {

        if (ncReasons) {
            openModal(<NoncomplianceReasons reasons={ncReasons} />, 'small');
        }
    }

    return (
        <Segment raised>
            <Grid>
                <Grid.Column width={7}>
                    <Table basic='very'>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell><Header size='tiny' content='Diet Name' /></Table.Cell>
                                <Table.Cell><Label content={diet.name} /></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><Header size='tiny' content='Start Date' /></Table.Cell>
                                <Table.Cell><Label content={displayFullDate(new Date(diet.startDate))} /></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><Header size='tiny' content='End Date' /></Table.Cell>
                                <Table.Cell>{diet.endDate && <Label content={displayFullDate(new Date(diet.endDate))} />}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><Header size='tiny' content='Actual End Date' /></Table.Cell>
                                <Table.Cell>{diet.actualEndDate && <Label content={displayFullDate(new Date(diet.actualEndDate))} />}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Grid.Column>
                <Grid.Column width={2} />
                <Grid.Column width={7}>
                    <Table basic='very'>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell><Header size='tiny' content='Status' /></Table.Cell>
                                <Table.Cell><Label content={diet.isActive ? 'Active' : 'Inactive'} color={diet.isActive ? 'green' : 'teal'} /></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><Header size='tiny' content='Adherence Rate' /></Table.Cell>
                                <Table.Cell>{diet.adherenceRate && <Label content={`${diet.adherenceRate} %`} />}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell><Header size='tiny' content='Water Intake' /></Table.Cell>
                                <Table.Cell>{diet.averageWaterIntake && <Label content={`${diet.averageWaterIntake} glasses`} />}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell><Header size='tiny' content='Noncompliance Reasons' /></Table.Cell>
                                <Table.Cell>{diet.noncomplainceReasons && Object.keys(diet.noncomplainceReasons).length > 0 && <Button secondary content='View Reasons' size='mini' type='button' onClick={() => showNoncomplianceReasons(diet.noncomplainceReasons)} />}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid>
        </Segment>

    )
}

export default DietHistory;
