import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormFieldProps, Form, Label, Select } from 'semantic-ui-react'

interface IProps extends FieldRenderProps<string, HTMLElement>, FormFieldProps { }

export const SelectInput: React.FC<IProps> = ({ input, width, options, placeholder, meta: { touched, error }, label, style, disabled }) => {

    return (
        <Form.Field error={touched && !!error} width={width}>
            {label && <label>{label}</label>}
            <Select value={input.value} onChange={(e, data) => input.onChange(data.value)} placeholder={placeholder} options={options} style={style} disabled={disabled} />
            {touched && error && (
                <Label basic color='red' pointing='above'>{error}</Label>
            )}
        </Form.Field>
    )
}
