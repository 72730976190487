import React from 'react'
import { ISkinfoldMeasurements } from '../../../models/progress-tracker.model';
import { Segment, Label, Grid, Table } from 'semantic-ui-react';
import PlaceHolder from './PlaceHolder';
import TableHeader from './TableHeader';

interface IProps {
    before: ISkinfoldMeasurements;
    after: ISkinfoldMeasurements;
}

const SkinFoldCompare: React.FC<IProps> = ({ before, after }) => {
    return (
        <Segment raised>
            <Label content='Skinfold Measurements' ribbon color='blue' />
            <br /><br />
            <Grid>
                <Grid.Column width={8}>
                    <Table basic='very'>
                        <TableHeader />
                        <Table.Body>
                            <PlaceHolder bodyPart='Abdominals' pre={+before.abdominals!} post={+after.abdominals!} measurement='mm' />
                            <PlaceHolder bodyPart='Triceps' pre={+before.tricep!} post={+after.tricep!} measurement='mm' />
                            <PlaceHolder bodyPart='Chest' pre={+before.chest!} post={+after.chest!} measurement='mm' />
                            <PlaceHolder bodyPart='Mid Axillary' pre={+before.midAxillary!} post={+after.midAxillary!} measurement='mm' />
                        </Table.Body>
                    </Table>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Table basic='very'>
                        <TableHeader />
                        <Table.Body>
                            <PlaceHolder bodyPart='Subscapular' pre={+before.subscapular!} post={+after.subscapular!} measurement='mm' />
                            <PlaceHolder bodyPart='Suprailiac' pre={+before.suprailiac!} post={+after.suprailiac!} measurement='mm' />
                            <PlaceHolder bodyPart='Thigh' pre={+before.thigh!} post={+after.thigh!} measurement='mm' />
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid>
        </Segment>
    )
}

export default SkinFoldCompare;