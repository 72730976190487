import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite';
import { Segment, Label, Grid, GridColumn } from 'semantic-ui-react';
import { Field } from 'react-final-form';
import TextInput from '../../../common/form/TextInput';
import { RootStoreContext } from '../../../stores/rootStore';

interface IProps {
    isReadOnly: boolean;
}

const Result: React.FC<IProps> = ({ isReadOnly }) => {

    const rootStoreContext = useContext(RootStoreContext);
    const { result } = rootStoreContext.progressStore;

    return (
        <Segment raised color='red'>
            <Label content='Result' ribbon color='blue' />
            <Grid style={{ marginTop: 20 }}>
                <Grid.Column width={4}>
                    <Field
                        component={TextInput}
                        name='result.weightInKg'
                        label='Weight (in kg)'
                        placeholder='Enter in KG'
                        maxlength={3}
                        readOnly={isReadOnly}
                    />
                </Grid.Column>
                <Grid.Column width={4}>
                    <Field
                        component={TextInput}
                        name='result.restingHeartRate'
                        label='Resting Heart Rate'
                        placeholder='Enter in BPM'
                        maxlength={3}
                        readOnly={isReadOnly}
                    />
                </Grid.Column>
                <GridColumn width={4}></GridColumn>
                <GridColumn width={4}></GridColumn>
                <Grid.Column width={4}>
                    <Label size='small' style={{ marginTop: 0 }} color='black'>
                        Body Fat
                        <Label.Detail>{result.bodyFatPercentage} %</Label.Detail>
                    </Label>
                </Grid.Column>
                <Grid.Column width={5}>
                    <Label size='medium' style={{ marginTop: 0 }} color='black'>
                        Lean Body Mass
                        <Label.Detail>{result.leanBodyMass} kg</Label.Detail>
                    </Label>
                </Grid.Column>
                <Grid.Column width={5}>
                    <Label size='medium' style={{ marginTop: 0 }} color='black'>
                        Fat Body Mass
                        <Label.Detail>{result.fatBodyMass} kg</Label.Detail>
                    </Label>
                </Grid.Column>
            </Grid>
        </Segment>
    )
}

export default observer(Result);
