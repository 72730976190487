import React, { useContext, Fragment, useState, useEffect } from 'react'
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Header } from 'semantic-ui-react';
import { RootStoreContext } from '../../stores/rootStore';
import { FORM_ERROR } from 'final-form';
import { combineValidators, isRequired, composeValidators } from 'revalidate';
import TextInput from '../../common/form/TextInput';
import { ErrorMessage } from '../../common/form/ErrorMessage';
import { IUserFormValues, IPasswordReset } from '../../models/user.model';
import { isValidEmail } from '../../common/util/validators';
import { Link } from 'react-router-dom';
import { SecurityQuestions } from '../../common/data/security-questions';
import { observer } from 'mobx-react-lite';


const validate = combineValidators({
    email: composeValidators(isRequired, isValidEmail)('Email'),
    password: isRequired('Password')
});

const resetValidate = combineValidators({
    email: composeValidators(isRequired, isValidEmail)('Email'),
});

const LoginForm = () => {
    const rootStore = useContext(RootStoreContext)
    const { login, resetPassword, passwordReset, loadingSecurityQuestion, cleanupPasswordReset } = rootStore.userStore;

    const [resetPasswordState, setResetPasswordState] = useState(false);

    useEffect(() => {
        return () => {
            cleanupPasswordReset();
        }
    }, [cleanupPasswordReset])


    return (
        <Fragment>
            {!resetPasswordState &&
                <FinalForm
                    validate={validate}
                    onSubmit={(values: IUserFormValues) => login(values).catch(error => ({
                        [FORM_ERROR]: error
                    }))}
                    render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                        <Form onSubmit={handleSubmit} error>
                            <Header as='h2' content='Login to MQ Nutrition' color='blue' textAlign='center' />
                            <Field
                                name='email'
                                component={TextInput}
                                placeholder='Email'
                            />
                            <Field
                                name='password'
                                component={TextInput}
                                placeholder='Password'
                                type='password'
                            />
                            <div style={{ float: 'right' }}>
                                Forgot Password? <Link to='/' className="notDisabled" onClick={() => setResetPasswordState(true)}>Click here to Reset</Link>
                            </div>
                            <br />
                            {submitError && <ErrorMessage error={submitError} text='Uh-oh!' />}
                            <br />
                            <Button color='blue' loading={submitting} disabled={(invalid && !dirtySinceLastSubmit) || pristine} fluid icon='sign in' content='Sign-in' />
                        </Form>
                    )}
                />
            }
            {resetPasswordState &&
                <FinalForm
                    initialValues={passwordReset}
                    validate={resetValidate}
                    onSubmit={(values: IPasswordReset) => resetPassword(values).catch(error => ({
                        [FORM_ERROR]: error
                    }))}
                    render={({ handleSubmit, submitError, pristine }) => (
                        <Form onSubmit={handleSubmit} error autoComplete='off'>
                            <Header as='h2' content='Reset your Password' color='blue' textAlign='center' />
                            <Field
                                name='email'
                                component={TextInput}
                                placeholder='Email'
                            />
                            {passwordReset.securityQuestionId &&
                                <Fragment>
                                    {passwordReset.securityQuestionId && <Header as='h4' content={SecurityQuestions.find(ques => ques.value === passwordReset.securityQuestionId)?.text} />}

                                    <Field
                                        name='answer'
                                        component={TextInput}
                                        placeholder='Answer'
                                        type='password'
                                        validate={value => value ? undefined : 'Answer is required'}
                                    />
                                </Fragment>
                            }
                            {submitError && <ErrorMessage error={submitError} text='Uh-oh!' />}
                            <br />
                            <Button.Group widths={2}>
                                <Button color='blue' content='Submit' loading={loadingSecurityQuestion} disabled={pristine} />
                                <Button content='Cancel' type='button' onClick={() => setResetPasswordState(false)} />
                            </Button.Group>
                        </Form>
                    )}
                />
            }
        </Fragment>

    )
}

export default observer(LoginForm);
