import React, { Fragment } from "react";
import { Field } from "react-final-form";
import { Header } from "semantic-ui-react";
import RichTextInput from "../../../common/form/RichTextInput";

interface IProps {
    mealKey: string,
    label: string
}

const RichMealInput: React.FC<IProps> = ({ mealKey, label }) => {
    return (
        <Fragment>
            <Header size='medium' content={label}></Header>
            <Field
                name={mealKey}
                component={RichTextInput}>
            </Field>
        </Fragment>
    );
}

export default RichMealInput;