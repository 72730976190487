import React, { useContext } from 'react'
import { RouteProps, RouteComponentProps, Route, Redirect } from 'react-router-dom'

import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../stores/rootStore';

interface IProps extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>>,
    roles?: string[]
}

const PrivateRoute: React.FC<IProps> = ({ component: Component, roles, ...rest }) => {

    const rootStore = useContext(RootStoreContext);
    const { isLoggedIn, userRole } = rootStore.userStore;

    const validateRole = (): boolean => {
        if (roles) {
            return (roles.indexOf(userRole) >= 0)
        }

        return true;
    }

    return (
        validateRole() ? (
            <Route
                {...rest}
                render={(props) => isLoggedIn ? <Component {...props} /> : <Redirect to={'/'} />}
            />) : <Redirect to={'/unauthorized'} />
    )
}

export default observer(PrivateRoute);
