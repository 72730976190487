import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormFieldProps, Form, Label, Checkbox } from 'semantic-ui-react'

interface IProps extends FieldRenderProps<string, HTMLElement>, FormFieldProps { }

export const CheckboxInput: React.FC<IProps> = ({ input, inputOnChange, width, meta: { touched, error }, label, style, disabled }) => {

    const onChange = (e, data) => {
        input.onChange(data.checked);
        inputOnChange && inputOnChange({ key: input.name, value: data.checked});
    }

    return (
        <Form.Field error={touched && !!error} width={width}>
            <Checkbox 
                checked={input.checked}
                onChange={onChange}
                style={style} 
                disabled={disabled}
                label={label}
            />
            {touched && error && (
                <Label basic color='red' pointing='above'>{error}</Label>
            )}
        </Form.Field>
    )
}
