import React, { useContext, useEffect, Fragment, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { RootStoreContext } from '../../../stores/rootStore'
import { RouteComponentProps, Link } from 'react-router-dom';
import LoadingComponent from '../../../layout/LoadingComponent';
import { Tab, Grid, Message, Header, Button } from 'semantic-ui-react';
import DietDetail from './DietDetail';
import Instructions from './Instructions';
import { Desktop } from '../../../layout/Responsive';
import { useMediaQuery } from 'react-responsive';
import { format } from 'date-fns';
import { MAX_DEVICE_WIDTH } from '../../../common/util/util';

interface DetailParams {
    id: string
}

const ViewDietPage: React.FC<RouteComponentProps<DetailParams>> = ({ match, history }) => {

    const rootStore = useContext(RootStoreContext);
    const { getDietInfo, isLoadingDiet, currentDietInfo, clearCurrentDiet, getDietInfoForClient } = rootStore.dietInfoStore;
    const { isUserClient } = rootStore.userStore;
    const { toggleSideMenu } = rootStore.responsiveStore;

    const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: MAX_DEVICE_WIDTH });

    const [selectDay, setSelectDay] = useState(0);
    const days: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const addTabPanes = (): any => {
        const panes: any = [];

        (days.forEach((day, index) => {
            panes.push({
                menuItem: isTabletOrMobileDevice ? day.substring(0, 2) : day,
                render: () => <Tab.Pane attached={false} key={day}>
                    {
                        <Fragment key={`${day}_${index}`}>
                            {
                                currentDietInfo![day.toLowerCase()] &&
                                Object.keys(currentDietInfo![day.toLowerCase()]).map((meal: any, index) => currentDietInfo![day.toLowerCase()]![meal] && <DietDetail key={`${day}_${meal}_${index}`} type={meal} meal={currentDietInfo![day.toLowerCase()]![meal]} />)
                            }
                            {
                                currentDietInfo![day.toLowerCase()] == null && <Message
                                    info
                                    header='No Diet prescribed!'
                                    content='Please check with your Nutritionist.'
                                />
                            }

                        </Fragment>
                    }
                </Tab.Pane>
            })
        }));

        if (!isTabletOrMobileDevice) {
            panes.push({
                menuItem: 'Market List',
                render: () =>
                    <Tab.Pane attached={false} key='list'>
                        <Instructions type='Market List' data={currentDietInfo!.marketList!} />
                    </Tab.Pane>
            })
        }

        return panes;
    }

    const onTabChange = (e, data) => {
        setSelectDay(data.activeIndex);
    }

    useEffect(() => {
        setSelectDay(days.indexOf(format(new Date(), 'eeee')));

        if (isUserClient) {
            toggleSideMenu(false);
            getDietInfoForClient(match.params.id);
        } else {
            getDietInfo(match.params.id);
        }

        return (() => {
            clearCurrentDiet();
        });
    }, [getDietInfo, clearCurrentDiet, getDietInfoForClient, isUserClient, toggleSideMenu, match.params.id])

    if (isLoadingDiet) {
        return <LoadingComponent content='Loading Diet...' />
    }

    if (!currentDietInfo && !isLoadingDiet) {
        return <Fragment>
            <Message
                icon='food'
                header={isUserClient ? 'You do not have any Diet assigned!' : 'Diet Information not found!'}
                content={isUserClient ? 'Please check with your nutritionist for more details' : 'Please verify if the Diet Id is valid'}
            />
        </Fragment>
    }

    return (
        <Fragment>
            {
                !isUserClient &&
                <Header
                    as='h2'
                    content={currentDietInfo.name}
                    subheader={currentDietInfo.description}
                    dividing
                />
            }
            <Grid>
                <Grid.Column width={isTabletOrMobileDevice ? 16 : 10}>
                    <Tab 
                        menu={isTabletOrMobileDevice ? { pointing: true, className: 'wrapped', secondary: true, color: 'red' } : { pointing: true, color: 'black', inverted: true }} 
                        panes={addTabPanes()}
                        activeIndex={selectDay}
                        onTabChange={onTabChange}
                    />
                </Grid.Column>
                <Desktop>
                    <Grid.Column width={6}>
                        {
                            isUserClient && <Button as={Link} content='Home' primary to={'/home'} icon='home' floated='right' style={{ marginBottom: 20 }} />
                        }
                        {
                            !isUserClient && <Button content='Back' primary icon='arrow left' onClick={history.goBack} floated='right' style={{ marginBottom: 20 }} />
                        }
                        <div style={{ marginTop: 55 }}>
                            <Instructions type='Instructions' data={currentDietInfo!.instructions!} />
                        </div>
                    </Grid.Column>
                </Desktop>
            </Grid>
        </Fragment>
    )
}

export default observer(ViewDietPage);