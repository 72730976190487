import React, { Fragment, useContext, useState } from 'react'
import { Form, Segment, Label, Button, Confirm, Grid } from 'semantic-ui-react';
import { SelectInput } from '../../../common/form/SelectInput';
import { calculateLiters } from '../../../common/util/util';
import MealStatus from '../../clients/track/MealStatus';
import { Form as FinalForm, Field } from 'react-final-form';
import { useMediaQuery } from 'react-responsive';
import { combineValidators, isRequired } from 'revalidate';
import { IMealStatus } from '../../../models/track-diet-status.model';
import uuid from 'react-uuid';
import alertify from '../../../common/util/alertify';
import { RootStoreContext } from '../../../stores/rootStore';
import { observer } from 'mobx-react-lite';
import { ITrackMeals } from '../../../models/track-meals.model';

const glasses = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

interface IProps {
    mealsForDay: string[];
    currentSelectedDiet: ITrackMeals;
    noOfCheatMeals: number;
}

const validate = combineValidators({
    waterIntake: isRequired({ message: 'Please choose the amount of water consumed' })
});

const TrackStatus: React.FC<IProps> = ({ mealsForDay, currentSelectedDiet, noOfCheatMeals }) => {

    const rootStoreContext = useContext(RootStoreContext);
    const { saveDietStatus, savingDietStatus, hasDietStatus, deletingDietStatus, deleteLoggedDietStatus } = rootStoreContext.responsiveStore;
    const { scrollToTop } = rootStoreContext.commonStore;
    const [openConfirm, setOpenConfirm] = useState(false);
    
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

    const submitStatus = (values) => {
        const mealStatuses: IMealStatus[] = [];

        Object.keys(values).forEach((key) => {
            if (key && key !== 'id' && key !== 'waterIntake') {
                const mealStatus: IMealStatus = {
                    id: uuid(),
                    name: key,
                    status: values[key]?.status,
                    comment: values[key]?.comment ? values[key]?.comment : undefined,
                    isCheatMeal: values[key]?.isCheatMeal ? values[key]?.isCheatMeal : undefined
                };
                mealStatuses.push(mealStatus);
            }
        });

        saveDietStatus(+values.waterIntake, mealStatuses).finally(scrollToTop);
    }

    const clearCheatMeal = ([name], state, { changeValue }) => {
        const isCheatMealKey = `${name.key.split('.')[0]}.isCheatMeal`;
        const commentKey = `${name.key.split('.')[0]}.comment`;
        if (name.value !== 'noncompliant') {
            changeValue(state, isCheatMealKey, () => undefined)
            changeValue(state, commentKey, () => undefined)
        }
    }

    return (
        <Fragment>
            <FinalForm
                onSubmit={submitStatus}
                validate={validate}
                initialValues={currentSelectedDiet}
                mutators={{ clearCheatMeal }}
                render={({ handleSubmit, values, invalid, form }) => (
                    <Form
                        onSubmit={() => { handleSubmit(values); if (invalid) { alertify.error('Please enter all required fields') } }}
                        autoComplete='off'>

                        <Fragment>
                            <Button.Group fluid>
                                <Button positive content='Submit' loading={savingDietStatus} />
                                {hasDietStatus && <Button content='Delete' type='button' negative loading={deletingDietStatus} onClick={() => setOpenConfirm(true)} />}
                            </Button.Group>
                        </Fragment>

                        <Segment color='blue' raised>
                            <Grid>
                                <Grid.Column width={isPortrait ? 16 : 8} style={isPortrait ? { paddingBottom: 0 } : { paddingBottom: 20, paddingRight: 0 }}>
                                    <Field
                                        name='waterIntake'
                                        component={SelectInput}
                                        label='How many glasses of water did you have today?'
                                        maxlength={2}
                                        options={glasses.map(c => ({ key: c, text: c, value: c }))}
                                        placeholder='Select One'
                                    />
                                </Grid.Column>
                                <Grid.Column width={6} style={isPortrait ? { paddingTop: 6, paddingLeft: 5 } : { paddingLeft: 6 }}>
                                    {
                                        values && +values.waterIntake! > 0 &&
                                        <Label
                                            pointing={isPortrait ? 'above' : 'left'}
                                            content={calculateLiters(values.waterIntake!)}
                                            color={values.waterIntake! < 8 ? 'red' : 'green'}
                                            style={isPortrait ? { marginLeft: 10, marginTop: 0 } : { marginLeft: 0, marginTop: 30 }}
                                        />
                                    }
                                </Grid.Column>
                            </Grid>
                        </Segment>
                        <br />
                        {
                            mealsForDay && mealsForDay.map(meal => (
                                <MealStatus
                                    key={meal}
                                    mealType={meal.replace(' ', '').toLowerCase()} mealName={meal} validateComment={values[meal.replace(' ', '').toLowerCase()]?.status === 'noncompliant'}
                                    displayCheatMeal={noOfCheatMeals > 0}
                                    validateCheatMeal={values[meal.replace(' ', '').toLowerCase()]?.status === 'noncompliant' && values[meal.replace(' ', '').toLowerCase()]?.isCheatMeal === undefined}
                                    clearCheatMeal={form.mutators.clearCheatMeal}
                                />
                            ))
                        }
                        <br />
                        {mealsForDay.length === 0 &&
                            <Fragment>
                                <Label content='No diet assigned for this day!' color='red' size='medium' />
                                <br /><br />
                            </Fragment>
                        }

                        <Fragment>
                            <Button.Group fluid>
                                <Button positive content='Submit' loading={savingDietStatus} />
                                {hasDietStatus && <Button content='Delete' type='button' negative loading={deletingDietStatus} onClick={() => setOpenConfirm(true)} />}
                            </Button.Group>
                        </Fragment>

                        {hasDietStatus &&
                            <Fragment>
                                <Confirm
                                    header='Delete Diet Status'
                                    content='Are you sure you want to delete the diet status?'
                                    open={openConfirm}
                                    cancelButton='No'
                                    confirmButton="Yes"
                                    onCancel={() => setOpenConfirm(false)}
                                    onConfirm={() => { setOpenConfirm(false); deleteLoggedDietStatus() }}
                                    size='mini'
                                />
                            </Fragment>
                        }
                    </Form>
                )}
            />
        </Fragment>
    )
}

export default observer(TrackStatus);
