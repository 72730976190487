import { toast } from "react-semantic-toasts";

const alertify = {
  success: (description: string) => {
    toast({
      type: "success",
      icon: 'check',
      title: 'Success!',
      description: description,
      animation: 'fade up',
      time: 5000,
    });
  },

  warning: (description: string) => {
    toast({
      type: 'warning',
      icon: 'warning sign',
      title: 'Attention!',
      description: description,
      animation: "fade up",
      time: 5000,
    });
  },

  error: (description: string) => {
    toast({
      type: 'error',
      icon: 'ban',
      title: 'Oops!',
      description: description,
      animation: "fade up",
      time: 5000
    });
  },

  info: (description: string) => {
    toast({
      type: 'info',
      icon: 'info',
      title: 'Info!',
      description: description,
      animation: "fade up",
      time: 5000,
    });
  },
};

export default alertify;
