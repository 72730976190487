import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FormFieldProps, Form, Label } from 'semantic-ui-react';

interface IProps extends FieldRenderProps<string, HTMLElement>, FormFieldProps { }

const RadioInput: React.FC<IProps> = ({ input, inputOnChange, label, meta: { touched, error }, disabled, style }) => {

    const inputProps = {
        ...input,
        onChange: e => {
            input.onChange(e);
            inputOnChange && inputOnChange({ key: input.name, value: input.value});
        }
    };

    const labelProps = (input) => {
        input.onChange(input.value);
        inputOnChange && inputOnChange({ key: input.name, value: input.name});
    }

    return (
        <Form.Field error={touched && !!error}>
            <div className="field" style={style}>
                <div className="ui checked radio checkbox">
                    <input type='radio' {...inputProps} disabled={disabled} />
                    <label onClick={() => disabled ? null : labelProps(input)} style={{ cursor: 'pointer' }}>{label}</label>
                </div>
            </div>
            {touched && error && (
                <Label basic color='red' pointing='above'>{error}</Label>
            )}
        </Form.Field>
    )
}

export default RadioInput;
