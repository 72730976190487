import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Header, Form, Button, Grid, Label } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/rootStore';
import { combineValidators, isRequired, composeValidators, isNumeric } from 'revalidate';
import TextInput from '../../common/form/TextInput';
import { ErrorMessage } from '../../common/form/ErrorMessage';
import { IUserProfile } from '../../models/user-profile.model';
import { TextAreaInput } from '../../common/form/TextAreaInput';
import { FORM_ERROR } from 'final-form';
import LoadingComponent from '../../layout/LoadingComponent';
import DateInput from '../../common/form/DateInput';
import { differenceInYears } from 'date-fns';
import RadioInput from '../../common/form/RadioInput';
import { useMediaQuery } from 'react-responsive';
import { MAX_DEVICE_WIDTH } from '../../common/util/util';



const validate = combineValidators({
    dateOfBirth: isRequired('Date of Birth'),
    gender: isRequired('Gender'),
    heightInCms: composeValidators(isRequired, isNumeric({ message: 'Height should be numeric' }))('Height'),
    currentWeightInKgs: composeValidators(isRequired, isNumeric({ message: 'Weight should be numeric' }))('Current Weight'),
    goalWeightInKgs: composeValidators(isRequired, isNumeric({ message: 'Weight should be numeric' }))('Goal Weight')
});


const ProfilePage = () => {

    const mUserProfile: IUserProfile = {};
    const rootStoreContext = useContext(RootStoreContext);
    const { user, isUserClient, saveUserProfile, getUserProfile } = rootStoreContext.userStore;
    const { toggleSideMenu } = rootStoreContext.responsiveStore;
    const { scrollToTop } = rootStoreContext.commonStore;

    const labelStyle = { float: 'left', marginTop: 30 };
    const gridStyle = { paddingRight: 0, paddingTop: 0 };
    const labelGridStyle = { paddingLeft: 2, paddingTop: 0 };

    const [userProfile, setUserProfile] = useState(mUserProfile);
    const [loadingProfile, setLoadingProfile] = useState(true);

    const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: MAX_DEVICE_WIDTH });


    useEffect(() => {
        let mounted = true;
        toggleSideMenu(false);

        getUserProfile()
            .then(userProfile => {
                if (userProfile) {
                    const tUserProfile = { ...userProfile, dateOfBirth: new Date(userProfile.dateOfBirth!) };
                    if (mounted) {
                        setUserProfile(tUserProfile);
                    }
                }
            }).finally(() => { if (mounted) { setLoadingProfile(false) } });

        return () => {
            mounted = false;
        }

    }, [getUserProfile, toggleSideMenu]);

    if (loadingProfile) return <LoadingComponent content='Loading User Profile...' />

    return (
        <Fragment>
            <Header
                as={isTabletOrMobileDevice ? 'h3' : 'h1'}
                content={`${user?.displayName}! enter your stats`}
                subheader={isUserClient && !isTabletOrMobileDevice && 'It would help your Nutritionist to tailor your diet based of the details provided'}
                dividing
            />
            <br />
            <Grid>
                <Grid.Column width={16}>
                    <FinalForm
                        validate={validate}
                        initialValues={userProfile}
                        onSubmit={(values: IUserProfile) => saveUserProfile(values).catch(error => ({
                            [FORM_ERROR]: error
                        })).finally(scrollToTop)}
                        render={({ handleSubmit, submitting, submitError, invalid, pristine, dirtySinceLastSubmit, values }) => (
                            <Form onSubmit={handleSubmit} error autoComplete='off'>
                                <Grid>
                                    <Grid.Column width={isTabletOrMobileDevice ? 10 : 3} style={gridStyle}>
                                        <Form.Group inline widths='equal'>
                                            <label>Gender</label>
                                            <Field name='gender' component={RadioInput} value="M" label='Male' type='radio' />
                                            <Field name='gender' component={RadioInput} value="F" label='Female' type='radio' />
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column width={isTabletOrMobileDevice ? 8 : 3} style={gridStyle}>
                                        <Field
                                            component={DateInput}
                                            name='dateOfBirth'
                                            label='Date of Birth'
                                            date={true}
                                            placeholder='Date of Birth'
                                        />
                                    </Grid.Column>

                                    <Grid.Column width={isTabletOrMobileDevice ? 6 : 4} style={labelGridStyle}>
                                        {
                                            values.dateOfBirth && <Label tag color='red' style={labelStyle}>
                                                {differenceInYears(new Date(), new Date(values.dateOfBirth))} years</Label>
                                        }
                                    </Grid.Column>

                                </Grid>
                                <Grid>
                                    <Grid.Column width={isTabletOrMobileDevice ? 8 : 3} style={gridStyle}>
                                        <Field
                                            component={TextInput}
                                            name='heightInCms'
                                            placeholder='In cms'
                                            label='Height (cms)'
                                            maxlength={4}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={isTabletOrMobileDevice ? 6 : 4} style={labelGridStyle}>
                                        {
                                            !isNaN(values.heightInCms!) && <Label tag color='red' style={labelStyle}>
                                                {(values.heightInCms! / 30.48).toFixed(2)} ft</Label>
                                        }
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column width={isTabletOrMobileDevice ? 8 : 3} style={gridStyle}>
                                        <Field
                                            name='currentWeightInKgs'
                                            component={TextInput}
                                            placeholder='In kg'
                                            label='Current Weight (kg)'
                                            maxlength={3}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={isTabletOrMobileDevice ? 6 : 4} style={labelGridStyle}>
                                        {
                                            !isNaN(values.currentWeightInKgs!) && <Label tag color={values.goalWeightInKgs! === values.currentWeightInKgs ? 'green' : 'red'} style={labelStyle}>
                                                {(values.currentWeightInKgs! * 2.2046).toFixed(2)} lb</Label>
                                        }
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column width={isTabletOrMobileDevice ? 8 : 3} style={gridStyle}>
                                        <Field
                                            name='goalWeightInKgs'
                                            component={TextInput}
                                            placeholder='In kg'
                                            label='Goal Weight (kg)'
                                            maxlength={3}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={isTabletOrMobileDevice ? 6 : 4} style={labelGridStyle}>
                                        {
                                            !isNaN(values.goalWeightInKgs!) && <Label tag color={values.goalWeightInKgs! === values.currentWeightInKgs ? 'green' : 'red'} style={labelStyle}>
                                                {(values.goalWeightInKgs! * 2.2046).toFixed(2)} lb</Label>
                                        }
                                    </Grid.Column>
                                </Grid>

                                <Grid columns={16}>
                                    <Grid.Column width={isTabletOrMobileDevice ? 15 : 8} style={gridStyle}>
                                        <Field
                                            name='healthIssues'
                                            component={TextAreaInput}
                                            placeholder='Enter issues'
                                            label={'Health issues (if any)'}
                                            maxlength={500}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={isTabletOrMobileDevice ? 15 : 8} style={gridStyle}>
                                        <Field
                                            name='foodAllergies'
                                            component={TextAreaInput}
                                            placeholder='Enter Food Allergies'
                                            label={'Enter food items you are allergic to (if any)'}
                                            maxlength={500}
                                        />
                                    </Grid.Column>
                                </Grid>
                                {submitError && <ErrorMessage error={submitError} text='Invalid data!' />}
                                <br />
                                <Button primary loading={submitting} disabled={(invalid && !dirtySinceLastSubmit) || pristine} content='Submit' fluid={isTabletOrMobileDevice} />
                                {/* <pre>{JSON.stringify(values, undefined, 2)}</pre> */}
                            </Form>
                        )}
                    />
                </Grid.Column>
            </Grid>

        </Fragment>
    )
}

export default observer(ProfilePage);
