import { RootStore } from "./rootStore";
import { action, observable, runInAction } from "mobx";
import agent from "../api/agent";
import { IUserInfo } from "../models/user-info.model";
import { IUserProfile } from "../models/user-profile.model";
import alertify from "../common/util/alertify";
import { IAssignDiet } from "../models/assign-diet.model";
import { IMealInDay } from "../models/meals-in-day.model";
import { ITrackDietStatus } from "../models/track-diet-status.model";
import { IMealStatistics } from "../models/client-detail.model";

export default class ClientStore {
  rootStore: RootStore;

  @observable loadingDetail: boolean = false;
  @observable clientInfo: IUserInfo | null = null;
  @observable clientProfile: IUserProfile | null = null;
  @observable isAssignDietOpen: boolean = false;
  @observable assignedDiet: IAssignDiet = {diet: undefined};
  @observable isDeletingAssignedDiet: boolean = false;
  @observable assigningDiet: boolean = false;
  @observable loadingDietAssignment: boolean = false;
  @observable mealsInDietForWeek: IMealInDay[] = [];
  @observable mealStatistics: IMealStatistics = {};


  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action getClientDetail = async (id) => {
    try {
      this.loadingDetail = true;
      const clientDetail = await agent.Client.detail(id);

      runInAction(() => {
        this.loadingDetail = false;
        this.clientInfo = clientDetail.userInfo;
        this.clientProfile = clientDetail.userProfile;

        if (clientDetail.assignedDiet) {
          this.mealStatistics = clientDetail.mealStatistics;
          this.isAssignDietOpen = true;
          this.assignedDiet = clientDetail.assignedDiet;
          this.mealsInDietForWeek = clientDetail.mealsForWeek;
          
          if (clientDetail.trackDietStatus) {
            this.rootStore.eventStore.logDietStatusEvents(clientDetail.trackDietStatus);
          }

          if (clientDetail.progressTrack) {
            this.rootStore.eventStore.addProgressEvent(clientDetail.progressTrack);
          }

          this.assignedDiet.diet = clientDetail.diet;
          this.assignedDiet.startDate = new Date(
            clientDetail.assignedDiet.startDate!
          );
          this.assignedDiet.endDate = new Date(
            clientDetail.assignedDiet.endDate!
          );
        }
      });
    } catch (error) {
      alertify.error("Problem occurred while getting the client details");

      runInAction(() => {
        this.loadingDetail = false;
      });
    }
  };

  @action cleanup = () => {
    this.clientProfile = null;
    this.clientInfo = null;
    this.isAssignDietOpen = false;
    this.mealsInDietForWeek = [];
    this.mealStatistics = {};
    this.assignedDiet = { diet: undefined };
  };

  @action assignDiet = async (assignDiet: IAssignDiet) => {
    try {
      this.assigningDiet = true;
      assignDiet.assignedToUserId = this.clientInfo ? this.clientInfo.id : null;
      const assignedDiet = await agent.Client.assignDiet(assignDiet);

      if (assignedDiet.id) {
        this.getDietAssignment(assignedDiet.assignedToUserId!);
      }

      alertify.success("Diet has been assigned to the client");

      runInAction(() => {
        this.assigningDiet = false;
        this.assignedDiet = assignedDiet;
        this.assignedDiet.diet = assignedDiet.diet;
        this.assignedDiet.startDate = new Date(assignedDiet.startDate!);
        this.assignedDiet.endDate = new Date(assignedDiet.endDate!);
      });
    } catch (error) {
      alertify.error("Problem occurred while assigning the diet to the client");
      runInAction(() => {
        this.assigningDiet = false;
      });
    }
  };

  @action deleteAssignedDiet = async (id: string) => {
    try {
      this.isDeletingAssignedDiet = true;
      await agent.Client.deleteAssignedDiet(id);

      runInAction(() => {
        this.assignedDiet = {diet: undefined};
        this.isDeletingAssignedDiet = false;
        this.isAssignDietOpen = false;
        this.mealsInDietForWeek = [];
        this.rootStore.eventStore.events = [];
        this.mealStatistics = {};
      });
    } catch (error) {
      runInAction(() => {
        this.isDeletingAssignedDiet = false;
      });
      alertify.error(
        "Problem occurred while deleting the diet assigned to the client"
      );
    }
  };

  @action getDietAssignment = async (id: string) => {
    try {
      
      let dietStatus: ITrackDietStatus[] = [];
      this.loadingDietAssignment = true;
      const dietAssignment = await agent.Client.getDietAssignment(id);

      if (dietAssignment.id) {
        dietStatus = await agent.Client.getLoggedDietStatus(dietAssignment.id!, id);
      }

      runInAction(() => {
        this.loadingDietAssignment = false;
        this.mealsInDietForWeek = dietAssignment.mealsForWeek!;
        this.rootStore.eventStore.logDietStatusEvents(dietStatus);
      });
    } catch (error) {
      if (error.status === 204) {
        runInAction(() => {
          this.loadingDietAssignment = false;
          this.assignedDiet = {};
        });
      } else {
        runInAction(() => {
          this.loadingDietAssignment = false;
        });
        alertify.error('Problem occurred while getting the Diet Information');
      }
    }
  }

  @action setClientWeight = (weight: number) => {
    if (this.clientProfile) {
      this.clientProfile.currentWeightInKgs = weight;
    }
  }
}
