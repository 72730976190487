import React from 'react';
import { Segment, Header, Icon, Button } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';

const Unauthorized: React.FC<RouteComponentProps> = ({ history }) => {
    return (
        <Segment placeholder>
            <Header icon>
                <Icon name='lock' />
                You are unauthorized to access this page.
            </Header>
            <Segment.Inline>
                <Button onClick={history.goBack} primary content='Go Back' />
            </Segment.Inline>
        </Segment>
    )
}

export default Unauthorized;
