import React from 'react'
import { Message } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import ReactHtmlParser from 'react-html-parser';

interface IProps {
    type: string;
    data: string;
}

const Instructions: React.FC<IProps> = ({ type, data }) => {
    if (!data) {
        return (
            <Message
                info
                header={`No ${type}`}
                content='Please check with your Nutritionist.'
            />
        );
    } else {
        return (
            <div className='ui card fluid'>
                <div className="image">
                    <span className={`ui ${type.toUpperCase() === 'MARKET LIST' ? 'olive ribbon label' : 'black right ribbon label'}`}>{type.toUpperCase()}</span>
                </div>
                <div className="content">
                    {
                        data && ReactHtmlParser(data)
                        // <List style={{ marginTop: 35 }}>
                        //     {
                        //         data!.split('\n')!.map((val: any, index) => (val && <List.Item key={index} icon='arrow circle right' content={val} />))
                        //     }
                        // </List>
                    }
                </div>
            </div>
        );
    }
}

export default observer(Instructions);
