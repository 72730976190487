import React from 'react'
import { IGirthMeasurement } from '../../../models/progress-tracker.model'
import { Segment, Label, Grid, Table } from 'semantic-ui-react'
import PlaceHolder from './PlaceHolder';
import TableHeader from './TableHeader';


interface IProps {
    before: IGirthMeasurement;
    after: IGirthMeasurement;
}

const GirthCompare: React.FC<IProps> = ({ before, after }) => {

    return (
        <Segment raised>
            <Label content='Girth Measurements' ribbon color='blue' />
            <br /><br />
            <Grid>
                <Grid.Column width={8}>
                    <Table basic='very'>
                        <TableHeader />
                        <Table.Body>
                            <PlaceHolder bodyPart='Neck' pre={+before.neck!} post={+after.neck!} measurement='cm' />
                            <PlaceHolder bodyPart='Shoulder' pre={+before.shoulder!} post={+after.shoulder!} measurement='cm' />
                            <PlaceHolder bodyPart='Chest' pre={+before.chest!} post={+after.chest!} measurement='cm' />
                            <PlaceHolder bodyPart='Upper Arm' pre={+before.upperArm!} post={+after.upperArm!} measurement='cm' />
                        </Table.Body>
                    </Table>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Table basic='very'>
                        <TableHeader />
                        <Table.Body>
                            <PlaceHolder bodyPart='Waist' pre={+before.waist!} post={+after.waist!} measurement='cm' />
                            <PlaceHolder bodyPart='Hip' pre={+before.hip!} post={+after.hip!} measurement='cm' />
                            <PlaceHolder bodyPart='Thigh' pre={+before.thigh!} post={+after.thigh!} measurement='cm' />
                            <PlaceHolder bodyPart='Calf' pre={+before.calf!} post={+after.calf!} measurement='cm' />
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid>
        </Segment>
    )
}

export default GirthCompare
