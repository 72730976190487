import './styles.css';
import React, { Fragment, useContext, useEffect } from 'react';
import { Container, Sidebar, Segment, Menu, Icon } from 'semantic-ui-react';
import { Route, Switch, withRouter, RouteComponentProps, Link } from 'react-router-dom';
import NavBar from '../features/nav/NavBar';
import NotFound from './NotFound';
import { observer } from 'mobx-react-lite';
import ModalContainer from '../common/modals/ModalContainer';
import 'mobx-react-lite/batchingForReactDom';
import HomePage from '../features/home/HomePage';
import PrivateRoute from './PrivateRoute';
import { RootStoreContext } from '../stores/rootStore';
import LoadingComponent from './LoadingComponent';
import { MAX_DEVICE_WIDTH, Roles } from '../common/util/util';
import Unauthorized from './Unauthorized';
import DashboardPage from '../features/dashboard/DashboardPage';
import ProfilePage from '../features/user/ProfilePage';
import AddDietPage from '../features/diet/add/AddDietPage';
import ListDietsPage from '../features/diet/list/ListDietsPage';
import ViewDietPage from '../features/diet/view/ViewDietPage';
import ViewClientsPage from '../features/clients/ViewClientsPage';
import RegistrationCodesPage from '../features/clients/RegistrationCodesPage';
import { SemanticToastContainer } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import ClientPage from '../features/clients/details/ClientPage';
import HistoryPage from '../features/clients/history/HistoryPage';
import { Desktop, Mobile } from './Responsive';
import ResponsiveTracker from '../features/responsive/track/ResponsiveTrackerPage';
import CalculatorsPage from '../features/calculators/CalculatorsPage';
import { useMediaQuery } from 'react-responsive';
import RegisterSuccess from '../features/user/RegisterSuccess';
import VerifyEmail from '../features/user/VerifyEmail';
import SecurityQuestionsPage from '../features/user/SecurityQuestionsPage';
import ResetPasswordPage from '../features/user/ResetPasswordPage';
import ChangePasswordPage from '../features/user/ChangePasswordPage';



const App: React.FC<RouteComponentProps> = ({ location }) => {

  const rootStore = useContext(RootStoreContext);
  const { setAppLoaded, token, appLoaded } = rootStore.commonStore;
  const { appVersion, getUser, logout, isUserClient, userId, userProfile, isLoggedIn, createHubConnection, stopHubConnection } = rootStore.userStore;
  const { isSideMenuVisible, toggleSideMenu } = rootStore.responsiveStore;
  const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: MAX_DEVICE_WIDTH })

  useEffect(() => {
    if (token) {
      getUser().finally(() => setAppLoaded())
      createHubConnection();
    } else {
      setAppLoaded();
    }

    return () => {
      stopHubConnection();
    }

  }, [getUser, setAppLoaded, token, createHubConnection, stopHubConnection])

  if (!appLoaded) return <LoadingComponent content='Loading app ...' />

  return (
    <Fragment>
      <ModalContainer />
      <SemanticToastContainer position={isTabletOrMobileDevice ? 'top-center' : 'bottom-right'} />
      <Route exact path='/' component={HomePage} />
      <Route path={'/(.+)'} render={() =>
        <Fragment>
          <NavBar />

          <Mobile>
            <Sidebar.Pushable as={Segment}>
              {isLoggedIn && <Sidebar as={Menu} animation='overlay' icon='labeled' inverted vertical visible={isSideMenuVisible} width='thin'>
                <div style={{ marginTop: 55 }}>
                  <Icon name={isSideMenuVisible ? 'close' : 'window minimize'} size='large' style={{ position:'absolute', top: '10px', right: '10px', color: 'white' }} onClick={() => toggleSideMenu(false)} />
                  <Menu.Item as={Link} icon='home' content='Home' to='/home' />
                  {!isUserClient && <Menu.Item as={Link} icon='users' content='Clients' to='/clients' />}
                  {!isUserClient && <Menu.Item as={Link} icon='code' content='OTP' to='/registration-codes' />}
                  {isUserClient && <Menu.Item as={Link} icon='calendar check' content='Track Diet' to={`/track/${userId}`} />}
                  {isUserClient && <Menu.Item as={Link} icon='food' content='View Diet' to={`/diet/${userId}`} />}
                  {isUserClient && userProfile && <Menu.Item as={Link} icon='calculator' content='Calculator' to='/calculator' />}
                  {<Menu.Item as={Link} icon='lock' content='Password' to={`/change-password`} />}
                  {<Menu.Item as={Link} icon='question' content='Security' to={`/security-question/${userId}`} />}
                  {isUserClient && <Menu.Item as={Link} icon='user' content='Profile' to='/profile' />}
                  {!isUserClient && <Menu.Item content={appVersion} icon='copy outline' />}
                  <Menu.Item as='a' icon='log out' content='Logout' onClick={logout} />
                </div>
              </Sidebar>}
              <Sidebar.Pusher>
                <Segment basic style={{ marginTop: 30, padding: 20, minHeight: '100vh' }} className='responsive'>
                  <Switch>
                    <PrivateRoute exact path='/home' component={DashboardPage} />
                    <PrivateRoute exact path='/profile' component={ProfilePage} />
                    <PrivateRoute exact path='/clients' component={ViewClientsPage} roles={[Roles.ADMIN, Roles.TRAINER]} />
                    <PrivateRoute exact path='/registration-codes' component={RegistrationCodesPage} roles={[Roles.ADMIN, Roles.TRAINER]} />
                    <PrivateRoute exact path='/calculator' component={CalculatorsPage} />
                    <PrivateRoute exact path='/track/:id' component={ResponsiveTracker} />
                    <PrivateRoute exact path='/diet/:id' component={ViewDietPage} />
                    <PrivateRoute exact path='/change-password' component={ChangePasswordPage} />
                    <PrivateRoute exact path='/security-question/:id' component={SecurityQuestionsPage} />
                    <Route exact path='/user/registerSuccess' component={RegisterSuccess} />
                    <Route exact path='/user/verifyEmail' component={VerifyEmail} />
                    <Route exact path='/user/resetPassword' component={ResetPasswordPage} />
                    <Route component={NotFound} />
                  </Switch>
                </Segment>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Mobile>

          <Desktop>
            <Container style={{ marginTop: '7em' }}>
              <Switch>
                <PrivateRoute exact path='/home' component={DashboardPage} />
                <PrivateRoute exact path='/registration-codes' component={RegistrationCodesPage} roles={[Roles.ADMIN, Roles.TRAINER]} />
                <PrivateRoute exact path='/clients' component={ViewClientsPage} roles={[Roles.ADMIN, Roles.TRAINER]} />
                <PrivateRoute exact path='/clients/:id' component={ClientPage} roles={[Roles.ADMIN, Roles.TRAINER]} />
                <PrivateRoute exact path='/history/:id' component={HistoryPage} roles={[Roles.ADMIN, Roles.TRAINER]} />
                <PrivateRoute exact path='/new-diet' component={AddDietPage} roles={[Roles.ADMIN, Roles.TRAINER]} />
                <PrivateRoute exact path='/edit-diet/:id' component={AddDietPage} roles={[Roles.ADMIN, Roles.TRAINER]} />
                <PrivateRoute exact path='/diets' component={ListDietsPage} roles={[Roles.ADMIN, Roles.TRAINER]} />
                <PrivateRoute exact path='/diet/:id' component={ViewDietPage} />
                <PrivateRoute exact path='/profile' component={ProfilePage} />
                <PrivateRoute exact path='/track/:id' component={ClientPage} />
                <PrivateRoute exact path='/unauthorized' component={Unauthorized} />
                <Route exact path='/user/registerSuccess' component={RegisterSuccess} />
                <Route exact path='/user/verifyEmail' component={VerifyEmail} />
                <Route exact path='/user/resetPassword' component={ResetPasswordPage} />
                <Route component={NotFound} />
              </Switch>
            </Container>
          </Desktop>

        </Fragment>} />
    </Fragment>
  );
}

export default withRouter(observer(App));
