import { RootStore } from "./rootStore";
import { action, observable, runInAction } from "mobx";
import { IDietInfo } from "../models/diet-info.model";
import agent from "../api/agent";
import { IDietBasicInfo } from "../models/diet-basic-info.model";
import alertify from "../common/util/alertify";

export default class DietInfoStore {
  rootStore: RootStore;

  @observable isSubmittingDiet: boolean = false;
  @observable isDeletingDiet: boolean = false;
  @observable isLoadingDiet: boolean = false;
  @observable isGeneratingReport: boolean = false;
  @observable currentDietInfoId: string | null = null;
  @observable currentDietInfo: IDietInfo = {};
  @observable dietList: IDietBasicInfo[] | null = null;
  @observable searchingDiets: boolean = false;
  @observable searchDietsResult: {title: string, description: string, id: string}[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action createDiet = async (dietInfoData: IDietInfo) => {
    this.isSubmittingDiet = true;

    try {
      const dietInfo = await agent.DietInfo.create(dietInfoData);
      alertify.success(this.currentDietInfoId ? 'Updated diet successfully!' : 'Added new diet successfully!');

      runInAction(() => {
        this.currentDietInfo = dietInfo;
        this.currentDietInfoId = dietInfo.id;
        this.isSubmittingDiet = false;
      });
    } catch (error) {
      const message = error?.data?.errors?.Diet;      
      alertify.error(message ? message : 'Problem occurred while creating new Diet');
      runInAction(() => {
        this.isSubmittingDiet = false;
      });
    }
  }

  @action deleteDiet = async (dietInfoId: string) => {
    if (dietInfoId) {
      this.isDeletingDiet = true;

      try {
        await agent.DietInfo.delete(dietInfoId);
        alertify.success('Diet deleted successfully!');

        runInAction(() => {
          this.isDeletingDiet = false;
          this.currentDietInfo = {};
          this.currentDietInfoId = null;
        });
      } catch (error) {
        alertify.error('Problem occurred while deleting the Diet Information');
        runInAction(() => {
          this.isDeletingDiet = false;
        });
      }
    }
  }

  @action clearDietList = () => {
    this.dietList = null;
  }

  @action clearCurrentDiet = () => {
    this.currentDietInfo = {};
    this.currentDietInfoId = null;
  }

  @action getAllDiets = async () => {
    try {
      const diets = await agent.DietInfo.list();

      runInAction(() => {
        this.dietList = diets;
      })

    } catch (error) {
      alertify.error('Problem occurred while getting Diet Information');
    }
  }

  @action getDietInfo = async (dietInfoId: string, isSearchedDiet?: boolean) => {
    try {
      this.isLoadingDiet = true;
      const dietInfo = await agent.DietInfo.details(dietInfoId);

      runInAction(() => {
        this.isLoadingDiet = false;
        this.currentDietInfo = dietInfo;
        this.currentDietInfoId = dietInfoId;

        if (isSearchedDiet) {
          this.currentDietInfo.id = '';
          this.currentDietInfoId = null;
        }
      });
    } catch (error) {
      alertify.error('Problem occurred while getting the Diet Information');

      runInAction(() => {
        this.isLoadingDiet = false;
      });
    }
  }

  @action getDietInfoForClient = async (clientUserId: string) => {
    try {
      this.isLoadingDiet = true;
      const dietInfo = await agent.DietInfo.forClient(clientUserId);

      runInAction(() => {
        this.isLoadingDiet = false;
        this.currentDietInfo = dietInfo;
      });
    } catch (error) {
      if (error.status === 204) {
        runInAction(() => {
          this.currentDietInfo = {};
          this.isLoadingDiet = false;
        });
      } else {
        runInAction(() => {
          this.currentDietInfo = {};
          this.isLoadingDiet = false;
        });
        alertify.error('Problem occurred while getting the Diet Information');
      }
    } 
  }

  @action searchDiets = async (dietName: string) => {
    try {
      this.searchingDiets = true;

      if (dietName) {
        this.searchDietsResult = [];

        const diets = await agent.DietInfo.search(dietName);

        runInAction(() => {
          this.searchDietsResult = [];
          this.searchingDiets = false;
          this.searchDietsResult = diets.map(diet =>  { return { title: diet.name, description: diet.description, id: diet.id }});
        });
      } else {
        runInAction(() => {
          this.searchingDiets = false;
          this.searchDietsResult = [];
        });
      }
      

    } catch (error) {
      alertify.error('Problem occurred while searching for the diet');
      runInAction(() => {
        this.searchingDiets = false;
      });
    }
  }

  @action clearSearchedResults = () => {
    runInAction(() => {
      this.searchDietsResult = []; 
    });
  }

  @action generateReport = async (dietInfoId: string) => {
    this.isGeneratingReport = true;

    try {
      const reportName = await agent.DietInfo.generateReport(dietInfoId);

      runInAction(() => {
        this.isGeneratingReport = false;

        if (reportName) {
          const url = `${process.env.REACT_APP_URL}reports/${reportName}`;
          window.open(url, "_blank");
        }
      });
    } catch (error) {
      alertify.error('Problem while generating report');

      runInAction(() => {
        this.isGeneratingReport = false;
      });
    }
  }
}