import React, { Fragment } from 'react';
import { IDietStatistics } from '../../models/dashboard.model';
import { Segment, Statistic, Message, Label, Divider } from 'semantic-ui-react';
import { Desktop, Mobile } from '../../layout/Responsive';

interface IProps {
    dietStatistics: IDietStatistics | null;
}

const DietStatistics: React.FC<IProps> = ({ dietStatistics }) => {
    return (
        <Fragment>
            {dietStatistics!.dayOfTheDiet && !isNaN(dietStatistics!.daysLeft) &&
                <Fragment>
                    <Desktop>
                        <Segment raised color='black'>
                            <Statistic.Group widths={3} size='tiny'>
                                <Statistic>
                                    <Statistic.Label>You are on Day</Statistic.Label>
                                    <Statistic.Value>{dietStatistics?.dayOfTheDiet}</Statistic.Value>
                                </Statistic>
                                <Statistic>
                                    <Statistic.Label>Day's not tracked</Statistic.Label>
                                    <Statistic.Value>{dietStatistics?.daysNotTracked}</Statistic.Value>
                                </Statistic>
                                <Statistic>
                                    <Statistic.Label>Day's left</Statistic.Label>
                                    <Statistic.Value>{dietStatistics?.daysLeft}</Statistic.Value>
                                </Statistic>
                            </Statistic.Group>
                        </Segment>
                    </Desktop>
                    <Mobile>
                        <Divider />
                        <Label.Group size='small' style={{ textAlign: 'center' }}>
                            <Label content='Day' detail={dietStatistics?.dayOfTheDiet} color='black' />
                            <Label content={`Day's not tracked`} detail={dietStatistics?.daysNotTracked} color={dietStatistics?.daysNotTracked! > 0 ? 'red' : 'black'} />
                            <Label content={`Day's left`} detail={dietStatistics?.daysLeft} color='black' />
                        </Label.Group>
                        <Divider />
                    </Mobile>
                </Fragment>
            }
            {dietStatistics!.daysNotTracked > 0 && !dietStatistics!.dayOfTheDiet && !dietStatistics?.daysLeft &&
                <Message
                    icon='warning sign'
                    header={`The duration of your diet has been completed. You haven't tracked the status for ${dietStatistics!.daysNotTracked} day(s)`}
                    content={`Please click on "Track Diet" to enter the diet details`}
                />
            }
        </Fragment>
    )
}

export default DietStatistics;
