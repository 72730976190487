import React from 'react'
import { Segment, Label, Grid, Table } from 'semantic-ui-react';
import PlaceHolder from './PlaceHolder';
import { IResult } from '../../../models/progress-tracker.model';
import TableHeader from './TableHeader';

interface IProps {
    before: IResult;
    after: IResult;
}

const ResultCompare: React.FC<IProps> = ({ before, after }) => {
    return (
        <Segment raised>
            <Label content='Results' ribbon color='blue' />
            <br /><br />
            <Grid>
                <Grid.Column width={8}>
                    <Table basic='very'>
                        <TableHeader />
                        <Table.Body>
                            <PlaceHolder bodyPart='Weight' pre={+before.weightInKg!} post={+after.weightInKg!} measurement='kg' />
                            <PlaceHolder bodyPart='Resting Heart Rate' pre={+before.restingHeartRate!} post={+after.restingHeartRate!} measurement='BPM' />
                            <PlaceHolder bodyPart='Body Fat' pre={+before.bodyFatPercentage!} post={+after.bodyFatPercentage!} measurement='%' />
                        </Table.Body>
                    </Table>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Table basic='very'>
                        <TableHeader />
                        <Table.Body>
                            <PlaceHolder bodyPart='Lean Body Mass' pre={+before.leanBodyMass!} post={+after.leanBodyMass!} measurement='kg' />
                            <PlaceHolder bodyPart='Fat Body Mass' pre={+before.fatBodyMass!} post={+after.fatBodyMass!} measurement='kg' />
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid>
        </Segment>
    )
}

export default ResultCompare;
