import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormFieldProps, Form, Label } from 'semantic-ui-react'


interface IProps extends FieldRenderProps<string, HTMLElement>, FormFieldProps { }

const TextInput: React.FC<IProps> = ({ input, width, type, placeholder, meta: { touched, error }, style, label, maxlength, readOnly, inline, disabled }) => {

    return (
        <Form.Field error={touched && !!error} type={type} width={width} inline={inline}>
            {label && <label>{label}</label>}
            <input
                {...input}
                placeholder={placeholder}
                style={style}
                maxLength={maxlength}
                value={input.value}
                readOnly={readOnly}
                disabled={disabled}
            />
            {touched && error && (
                <Label basic color='red' pointing={inline ? 'left' : 'above'}>{error}</Label>
            )}
        </Form.Field>
    )
}

export default TextInput;
