import React, { useEffect, useState } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string';
import agent from '../../api/agent';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import alertify from '../../common/util/alertify';

const VerifyEmail: React.FC<RouteComponentProps> = ({ location }) => {

    const Status = {
        Verifying: 'Verifying',
        Failed: 'Failed',
        Success: 'Success',
        EmailConfirmed: 'EmailConfirmed'
    };

    const [status, setStatus] = useState(Status.Verifying);
    const { token, email } = queryString.parse(location.search);
    const [resending, setResending] = useState(false);

    useEffect(() => {
        agent.User.verifyEmail(token as string, email as string)
            .then(() => {
                setStatus(Status.Success);
            })
            .catch(() => {
                setStatus(Status.Failed);
            })
    }, [Status.Failed, Status.Success, Status.Verifying, token, email])

    const handleConfirmEmailResend = () => {
        setResending(true);
        agent.User.resendVerifyEmailConfirm(email as string)
            .then(() => {
                alertify.success('Verification email resent. Please check your email');
            })
            .catch(error => {
                if (error.status === 406) {
                    setStatus(Status.EmailConfirmed);
                } else {
                    alertify.error('Error occurred while resending email');
                }
            })
            .finally(() => setResending(false));
            
    }

    const getBody = () => {
        switch (status) {
            case Status.Verifying:
                return <p>Verifying...</p>
            case Status.Failed:
                return (
                    <div className="center">
                        <p>Verification failed - The confirmation link has expired. You can try resending the verification email.</p>
                        <Button 
                            primary 
                            size='large' 
                            content='Resend email' 
                            onClick={handleConfirmEmailResend}
                            loading={resending}
                        />
                    </div>
                )
            case Status.Success:
            case Status.EmailConfirmed:
                return (
                    <div className="center">
                        <p>{status === Status.EmailConfirmed ? 'Email has already been confirmed' : 'Email has been verified. We will activate your account after verifying your details.'}</p>
                        <Button
                            primary
                            size='large'
                            content='Got it!'
                            as={Link}
                            to='/' />
                    </div>
                )
        }
    }

    return (
        <div>
            <Segment placeholder>
                <Header icon>
                    <Icon name='envelope' />
                    Email verification
                </Header>

                <Segment.Inline>
                    {getBody()}
                </Segment.Inline>
            </Segment>
        </div>
    )
}

export default VerifyEmail
