import React, { Fragment, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Segment, Form, Button, Label, Grid, Header, Icon } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import DateInput from '../../../common/form/DateInput';
import { IAssignDiet } from '../../../models/assign-diet.model';
import { combineValidators, composeValidators, isNumeric, isRequired } from 'revalidate';
import { RootStoreContext } from '../../../stores/rootStore';
import { FORM_ERROR } from 'final-form';
import SearchInput from '../../../common/form/SearchInput';
import { format } from 'date-fns';
import ProgressTrackerPage from '../progress/ProgressTrackerPage';
import NoncomplianceReasons from './NoncomplianceReasons';
import ClientDietStats from './ClientDietStats';
import CompareProgressPage from '../compare/CompareProgressPage';
import RichTextInput from '../../../common/form/RichTextInput';
import TextInput from '../../../common/form/TextInput';
import { CheckboxInput } from '../../../common/form/CheckboxInput';
import { Link } from 'react-router-dom';


const validate = combineValidators({
    diet: isRequired({ message: 'Search for the Diet and choose the name' }),
    startDate: isRequired('Start Date'),
    endDate: isRequired('End Date'),
    noOfCheatMeals: composeValidators(
        isRequired,
        isNumeric
    )('Cheat meals'),
});

export const Condition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (value === is ? children : null)}
    </Field>
)

const required = value => {
    return value ? undefined : 'Please enter Instructions';
};

const AssignDiet = () => {

    const rootStoreContext = useContext(RootStoreContext);
    const { assignDiet, assignedDiet, assigningDiet, isDeletingAssignedDiet, mealStatistics } = rootStoreContext.clientStore;

    const { openModal } = rootStoreContext.modalStore;
    const { events, getNoncomplianceReasons, getProgressTracks, canCompareProgress } = rootStoreContext.eventStore;
    const { isUserClient } = rootStoreContext.userStore;

    const {
        searchDiets,
        searchingDiets,
        searchDietsResult,
        clearSearchedResults,
    } = rootStoreContext.dietInfoStore;

    const handleSearchChange = (e, { value }) => {
        setTimeout(() => {
            searchDiets(value);
        }, 300);
    }

    const triggerProgressTrack = () => {
        openModal(<ProgressTrackerPage forDate={new Date()} />, 'small');
    }

    const showNoncomplianceReasons = () => {
        const ncReasons = getNoncomplianceReasons();

        if (ncReasons) {
            openModal(<NoncomplianceReasons reasons={ncReasons} />, 'small');
        }
    }

    const showCompareProgress = () => {
        const progressTracks = getProgressTracks();
        openModal(<CompareProgressPage progressTracks={progressTracks} />, 'large');
    }

    const clearPersonalizedInstructions = ([name], state, { changeValue }) => {
        if (!name.value) {
            changeValue(state, 'personalizedInstr', () => undefined);
        }
    }

    useEffect(() => {
        return () => {
            clearSearchedResults();
        }
    }, [clearSearchedResults])


    let endDateToLocale;
    let currentDateToLocale = new Date(new Date().toLocaleDateString('en-US'));

    if (assignedDiet.endDate) {
        endDateToLocale = new Date(assignedDiet.endDate.toLocaleDateString('en-US'));
    }


    return (
        <Fragment>
            {(isUserClient || events.length > 0) &&
                <Segment color='red' raised>
                    <Label color='blue' ribbon>
                        Diet Detail
                    </Label>

                    {!isUserClient &&
                        <Fragment>
                            <Button.Group size='tiny' floated='right'>
                                {mealStatistics.noncompliantMealCount! > 0 &&
                                    <Button
                                        onClick={showNoncomplianceReasons}
                                        content='Noncompliance Reasons'
                                        secondary
                                    />
                                }
                                {assignedDiet.endDate && endDateToLocale >= currentDateToLocale &&
                                    <Button
                                        onClick={triggerProgressTrack}
                                        content='Track Progress'
                                        positive
                                    />
                                }
                                {canCompareProgress &&
                                    <Button
                                        content='Compare Progress'
                                        color='facebook'
                                        onClick={showCompareProgress}
                                    />
                                }
                            </Button.Group>
                        </Fragment>
                    }
                    <Grid style={{ marginTop: 20 }}>
                        {!isUserClient && <Grid.Column width={5}>
                            <Header as='h4'>
                                <Icon name='food' />
                                <Header.Content as={Link} to={`/diet/${assignedDiet.diet?.id}`}>
                                    {assignedDiet.diet?.name && assignedDiet.diet.name}
                                    <Header.Subheader>Diet Name</Header.Subheader>
                                </Header.Content>
                            </Header>
                        </Grid.Column>}
                        <Grid.Column width={isUserClient ? 8 : 5}>
                            <Header as='h4'>
                                <Icon name='calendar' />
                                <Header.Content>
                                    {assignedDiet.startDate && format(new Date(assignedDiet.startDate), 'MMMM dd yyyy')}
                                    <Header.Subheader>Diet Start Date</Header.Subheader>
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={isUserClient ? 8 : 5}>
                            <Header as='h4'>
                                <Icon name='calendar' />
                                <Header.Content>
                                    {assignedDiet.endDate && format(new Date(assignedDiet.endDate), 'MMMM dd yyyy')}
                                    <Header.Subheader>Diet End Date</Header.Subheader>
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                    </Grid>
                    {!isUserClient && <ClientDietStats mealStatistics={mealStatistics} />}
                </Segment>
            }
            {!isUserClient && events.length === 0 &&
                <Segment color='red' raised>
                    <Label color='blue' ribbon>
                        Assign Diet
                    </Label>
                    <br /><br />
                    <FinalForm
                        onSubmit={(values: IAssignDiet) => {
                            assignDiet(values).catch(error => ({
                                [FORM_ERROR]: error
                            }))
                        }}
                        mutators={{ clearPersonalizedInstructions }}
                        validate={validate}
                        initialValues={assignedDiet}
                        render={({ handleSubmit, values, form }) => (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group widths='equal'>
                                    <Field
                                        name='diet'
                                        component={SearchInput}
                                        label='Search Diet'
                                        loading={searchingDiets}
                                        onSearchChange={handleSearchChange}
                                        results={searchDietsResult}
                                        placeholder='Type to search...'
                                    />
                                    <Field
                                        component={DateInput}
                                        name='startDate'
                                        label='Start Date'
                                        date={true}
                                        minDate={new Date()}
                                        placeholder='Start Date of Diet'
                                    />
                                    <Field
                                        component={DateInput}
                                        name='endDate'
                                        label='End Date'
                                        date={true}
                                        minDate={new Date()}
                                        placeholder='End Date of Diet'
                                    />
                                    <Field
                                        component={TextInput}
                                        name='noOfCheatMeals'
                                        label='No. of Cheat meals'
                                        placeholder='Number of cheat meals'
                                    />
                                </Form.Group>
                                <Grid>
                                    <Grid.Row columns={1}>
                                        <Grid.Column width={4}>
                                            <Field
                                                name='needPersonalizedInstr'
                                                type='checkbox'
                                                component={CheckboxInput}
                                                label='Add Personalized Instructions?'
                                                inputOnChange={form.mutators.clearPersonalizedInstructions}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Condition when='needPersonalizedInstr' is={true}>
                                        <Grid.Row columns={1}>
                                            <Grid.Column width={11}>
                                                <Field
                                                    name='personalizedInstr'
                                                    component={RichTextInput}
                                                    rows={6}
                                                    validate={values.needPersonalizedInstr ? required : undefined}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Condition>
                                </Grid>
                                <br />
                                {
                                    !isDeletingAssignedDiet && <Button content='Save' positive loading={assigningDiet} />
                                }
                            </Form>
                        )} />

                </Segment>
            }
        </Fragment>
    )
}

export default observer(AssignDiet);
