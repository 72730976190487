import { FieldRenderProps } from "react-final-form";
import { FormFieldProps } from "semantic-ui-react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import React from "react";

interface IProps extends FieldRenderProps<string, HTMLElement>, FormFieldProps { }

const RichTextInput: React.FC<IProps> = ({ input: { value, onChange, onBlur, onFocus } }) => {
    return (
        <CKEditor
            editor={ClassicEditor}
            config={{
                toolbar: {
                    items: ['heading', '|',
                        'bold', 'italic', '|',
                        'bulletedList', 'numberedList',
                        'outdent', 'indent', '|',
                        'blockQuote', '|',
                        'undo', 'redo']
                }
            }}
            data={value}
            onChange={(event, editor) => {
                onChange(editor.getData());
            }}
            onBlur={(event, editor) => {
                onBlur(event);
            }}
            onFocus={(event, editor) => {
                onFocus(event);
            }}
        />
    )
}

export default RichTextInput;