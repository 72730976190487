import React, { useContext, Fragment } from 'react'
import { Menu, Container, Button, Dropdown, Image } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'

import { NavLink, Link } from 'react-router-dom';
import { RootStoreContext } from '../../stores/rootStore';
import CalculatorsPage from '../calculators/CalculatorsPage';
import { Desktop, Mobile } from '../../layout/Responsive';
import SecurityQuestionsPage from '../user/SecurityQuestionsPage';
import ChangePasswordPage from '../user/ChangePasswordPage';


const NavBar = () => {

    const rootStore = useContext(RootStoreContext);
    const { appVersion, user, logout, isUserAdmin, isUserTrainer, isUserClient, userId, userProfile, isLoggedIn } = rootStore.userStore;
    const { openModal } = rootStore.modalStore;
    const { toggleSideMenu } = rootStore.responsiveStore;

    const openCalculators = () => {
        openModal(<CalculatorsPage />, 'large');
    }

    const openBackgroundJobsPortal = () => {
        const accessToken = sessionStorage.getItem('jwt');
        const url = `${process.env.REACT_APP_URL}jobs?access_token=${accessToken}`;
        window.open(url, '_blank');
    }

    return (
        <div>
            
            <Menu fixed='top' inverted>
                <Container>
                    <Menu.Item header as={NavLink} to='/home' exact>
                        <img src="/assets/MG_LOGO.png" alt="logo" />
                    </Menu.Item>
                    <Mobile>
                        {isLoggedIn && <Menu.Item header icon='bars' onClick={() => toggleSideMenu()} />}
                    </Mobile>
                    <Desktop>
                        {
                            (isUserAdmin || isUserTrainer) && user &&
                            <Fragment>
                                <Menu.Item name='Clients' as={NavLink} to='/clients' icon='users' />
                                <Menu.Item name='Diets' as={NavLink} to='/diets' icon='food' />
                                <Menu.Item>
                                    <Button
                                        positive
                                        content='Add New Diet'
                                        as={NavLink} to='/new-diet'
                                        icon='add to cart'
                                    />
                                </Menu.Item>
                                <Menu.Item content={appVersion} icon='copy outline' />
                            </Fragment>
                        }
                        {
                            isUserClient &&
                            <Fragment>
                                <Menu.Item name='Track Diet' as={NavLink} to={`/track/${userId}`} icon='calendar check' />
                                <Menu.Item name='View Diet' as={NavLink} to={`/diet/${userId}`} icon='food' />
                            </Fragment>
                        }
                        {user &&
                            <Fragment>
                                {userProfile &&
                                    <Menu.Item>
                                        <Button
                                            type='button'
                                            secondary
                                            content='Calculator'
                                            onClick={openCalculators}
                                            icon='calculator'
                                        />
                                    </Menu.Item>
                                }
                                <Menu.Item position='right'>
                                    <Image avatar spaced='right' src={user.imageUrl || '/assets/user.png'} />
                                    <Dropdown pointing='top left' text={user.displayName}>
                                        <Dropdown.Menu>
                                            {(isUserAdmin || isUserTrainer) && <Dropdown.Item as={Link} to={'/registration-codes'} text='Manage OTP' icon='code' />}
                                            <Dropdown.Item as={Link} to={'/profile'} text='My profile' icon='user' />
                                            <Dropdown.Item onClick={() => openModal(<ChangePasswordPage />, 'tiny')} text='Change Password' icon='lock' />
                                            <Dropdown.Item onClick={() => openModal(<SecurityQuestionsPage />, 'small')} text='Security Question' icon='question' />
                                            {isUserAdmin && <Dropdown.Item onClick={(e) => {e.preventDefault(); openBackgroundJobsPortal();}} text='Jobs' icon='tasks' />}
                                            <Dropdown.Item text='Logout' icon='power' onClick={logout} />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Menu.Item>
                            </Fragment>
                        }
                    </Desktop>
                    <Mobile>
                        {user &&
                            <Fragment>
                                <Menu.Item position='right'>
                                    <Image avatar spaced='right' src={user.imageUrl || '/assets/user.png'} />
                                    {`Hello ${user.displayName}`}
                                </Menu.Item>
                            </Fragment>
                        }
                    </Mobile>
                </Container>
            </Menu>
        </div>
    )
}

export default observer(NavBar);
