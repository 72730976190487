import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import agent from '../../api/agent';
import alertify from '../../common/util/alertify';

const RegisterSuccess: React.FC<RouteComponentProps> = ({ location, history }) => {

    const { email } = queryString.parse(location.search);
    const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

    const handleConfirmEmailResend = () => {
        agent.User.resendVerifyEmailConfirm(email as string)
            .then(() => {
                alertify.success('Verification email resent. Please check your email');
            })
            .catch(error => {
                if (error.status === 406) {
                    setIsEmailConfirmed(true);
                } else {
                    alertify.error('Error occurred while resending email');
                }
            });
    }

    return (
        <Segment placeholder>
            <Header icon>
                <Icon name='check' />
                {!isEmailConfirmed ? 'Successfully registered!' : 'Email verification'}
            </Header>

            <Segment.Inline>
                <div className="center">
                    {!isEmailConfirmed && <p>Please check your email (including junk folder) for the verification email</p>}
                    {email &&
                        <>
                            <p>{!isEmailConfirmed ? "Didn't receive the email? Please click below button to resent" : 'Email is already confirmed'}</p>
                            <Button primary content={!isEmailConfirmed ? 'Resend email' : 'Got it!'} size='large' onClick={() => !isEmailConfirmed ? handleConfirmEmailResend() : history.push('/')} />
                        </>}
                </div>
            </Segment.Inline>
        </Segment>
    )
}

export default RegisterSuccess;
