import React, { Fragment } from 'react'
import { Label, Statistic } from 'semantic-ui-react';
import { IMealStatistics } from '../../../models/client-detail.model';
import { calculateLiters, MAX_DEVICE_WIDTH } from '../../../common/util/util';
import { useMediaQuery } from 'react-responsive';

interface IProps {
    mealStatistics: IMealStatistics;
    showAdherance?: boolean;
}

const ClientDietStats: React.FC<IProps> = ({ mealStatistics, showAdherance }) => {

    const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: MAX_DEVICE_WIDTH });

    return (
        <Fragment>
            { !isTabletOrMobileDevice &&
                <Statistic.Group widths={showAdherance ? 6 : 5} size='mini' style={{ marginTop: 30, fontSize: 12 }}>
                    <Statistic value={mealStatistics?.mealCountTillDate} label='Meals till Date' />
                    <Statistic value={mealStatistics?.compliantMealCount} label='Compliant meals' />
                    <Statistic value={mealStatistics?.noncompliantMealCount} label='Noncompliant meals' />
                    <Statistic value={`${mealStatistics?.noOfCheatMealsConsumed}/${mealStatistics?.noOfCheatMealsForDiet}`} label='Cheat Meals' />
                    <Statistic value={`${mealStatistics?.avgWaterIntake ? calculateLiters(mealStatistics?.avgWaterIntake) : '0 Lts'}`} label='Average Water Intake' />
                    {showAdherance && <Statistic value={`${mealStatistics?.adherenceRate} %`} label='Adherence Rate' />}
                </Statistic.Group>
            }
            {
                isTabletOrMobileDevice &&
                <Label.Group>
                    <br/>
                    <Label content='Meals till Date' detail={mealStatistics?.mealCountTillDate} />
                    <Label content='Compliant meals' detail={mealStatistics?.compliantMealCount} />
                    <Label content='Noncompliant meals' detail={mealStatistics?.noncompliantMealCount} />
                    <Label content='Cheat meals' detail={`${mealStatistics?.noOfCheatMealsConsumed}/${mealStatistics?.noOfCheatMealsForDiet}`} />
                    <Label content='Average Water Intake' detail={`${mealStatistics?.avgWaterIntake ? calculateLiters(mealStatistics?.avgWaterIntake) : '0 Lts'}`} />
                    <Label content='Adherence Rate' detail={`${mealStatistics?.adherenceRate} %`} color={mealStatistics?.adherenceRate! >= 75 ? 'green' : 'red'} />
                </Label.Group>
            }
        </Fragment>
    )
}

export default ClientDietStats;
