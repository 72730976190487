import React from 'react'
import { Segment, Grid, Item, Header } from 'semantic-ui-react';
import { differenceInYears } from 'date-fns';
import { IUserInfo } from '../../../models/user-info.model';
import { IUserProfile } from '../../../models/user-profile.model';

interface IProps {
    clientInfo: IUserInfo;
    clientProfile: IUserProfile;
}

const ClientProfile: React.FC<IProps> = ({ clientInfo, clientProfile }) => {
    return (
        <Segment color='red' raised>
        <Grid>
            <Grid.Column width={12}>
                <Item.Group>
                    <Item>
                        <Item.Image
                            avatar
                            size='small'
                            src={clientInfo?.imageUrl || '/assets/user.png'}
                        />
                        <Item.Content verticalAlign='middle'>
                            <Header as='h1'>{clientInfo?.name}</Header>
                            <Item.Meta>Gender: {clientProfile?.gender === 'M' ? 'Male' : 'Female'}</Item.Meta>
                            <Item.Meta>Age: {clientProfile?.dateOfBirth && `${differenceInYears(new Date(), new Date(clientProfile?.dateOfBirth))} years`}</Item.Meta>
                            <Item.Meta>Height: {clientProfile?.heightInCms && `${(clientProfile?.heightInCms! / 30.48).toFixed(2)} ft`}</Item.Meta>
                            <Item.Meta>Current Weight: {clientProfile?.currentWeightInKgs && `${clientProfile?.currentWeightInKgs} kg`}</Item.Meta>
                            <Item.Meta>Goal Weight: {clientProfile?.goalWeightInKgs && `${clientProfile?.goalWeightInKgs} kg`}</Item.Meta>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Grid.Column>
        </Grid>
    </Segment>
    )
}

export default ClientProfile;
