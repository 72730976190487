import React, { Fragment, useState } from 'react'
import GirthCompare from './GirthCompare';
import { Segment, Form, Button, Header, Divider } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import { SelectInput } from '../../../common/form/SelectInput';
import { IProgressTrackBasicData, IProgressTracker } from '../../../models/progress-tracker.model';
import { combineValidators, isRequired } from 'revalidate';
import agent from '../../../api/agent';
import alertify from '../../../common/util/alertify';
import SkinfoldCompare from './SkinFoldCompare';
import ResultCompare from './ResultCompare';


interface IProps {
    progressTracks: IProgressTrackBasicData[]
}

const validate = combineValidators({
    progress1: isRequired('Progress 1'),
    progress2: isRequired('Progress 2')
});

const CompareProgressPage: React.FC<IProps> = ({ progressTracks }) => {

    const [loadingProgress, setLoadingProgress] = useState(false);
    const [progress1, setProgress1] = useState({} as IProgressTracker);
    const [progress2, setProgress2] = useState({} as IProgressTracker);

    const onSubmit = values => {
        setLoadingProgress(true);
        agent.Client.getProgressTracks(values)
            .then(resp => {
                setProgress1(resp.progressTrack1);
                setProgress2(resp.progressTrack2);
            })
            .catch(err => alertify.error('Problem occurred while fetching progress data'))
            .finally(() => setLoadingProgress(false))
    }

    return (
        <Fragment>
            <Header content='Compare Progress' />
                <Divider />
            <Segment raised color='red'>
                
                <FinalForm
                    onSubmit={onSubmit}
                    validate={validate}
                    render={({ handleSubmit, values, invalid }) => (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group inline widths='1'>
                                <Field
                                    name='progress1'
                                    component={SelectInput}
                                    label='Progress 1'
                                    placeholder='Select Progress 1'
                                    inline
                                    options={progressTracks.map(pt => ({ text: pt.date, value: pt.id }))}
                                />
                                <Field
                                    name='progress2'
                                    component={SelectInput}
                                    label='Progress 2'
                                    placeholder='Select Progress 2'
                                    inline
                                    options={progressTracks.map(pt => ({ text: pt.date, value: pt.id }))}
                                />
                                <Button content='Submit' positive disabled={invalid} loading={loadingProgress} />
                            </Form.Group>
                        </Form>
                    )}
                />
            </Segment>
            {progress1.girthMeasurement && progress2.girthMeasurement && <GirthCompare
                before={progress1.girthMeasurement!}
                after={progress2.girthMeasurement!}
            />}
            {progress1.girthMeasurement && progress2.girthMeasurement && <SkinfoldCompare
                before={progress1.skinfold!}
                after={progress2.skinfold!}
            />}
            {progress1.result && progress2.result && <ResultCompare
                before={progress1.result!}
                after={progress2.result!}
            />}
        </Fragment>
    )
}

export default CompareProgressPage;
