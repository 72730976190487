import { format } from 'date-fns';
import React, { Fragment, useState } from 'react'
import { Button, Divider, Form, Header, Item, ItemGroup, Label, Modal, Segment } from 'semantic-ui-react';
import { IAdminDashboard } from '../../models/admin-dashboard.model';
import ClientDietStats from '../clients/details/ClientDietStats';
import { useMediaQuery } from 'react-responsive';
import { Form as FinalForm, Field } from 'react-final-form';
import { ErrorMessage } from '../../common/form/ErrorMessage';
import { TextAreaInput } from '../../common/form/TextAreaInput';
import { IMealStatistics } from '../../models/client-detail.model';
import { calculateLiters, MAX_DEVICE_WIDTH } from '../../common/util/util';
import { combineValidators, isRequired } from 'revalidate';
import agent from '../../api/agent';
import { FORM_ERROR } from 'final-form';
import alertify from '../../common/util/alertify';
import { Link } from 'react-router-dom';

interface IProps {
    dashboardData: IAdminDashboard[]
}

const validate = combineValidators({
    message: isRequired('Message'),
});

const AdminDashboard: React.FC<IProps> = ({ dashboardData }) => {

    const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: MAX_DEVICE_WIDTH });
    const [initNotify, setInitNotify] = useState({ name: '', email: '', message: '' });
    const [modalState, setModalState] = useState(false);

    const triggerNotify = (name, email, mealStats: IMealStatistics) => {
        const stats = `Diet Stats\n****************************************\nMeals Till Date: ${mealStats.mealCountTillDate}\nCompliant Meals: ${mealStats.compliantMealCount}\nNoncompliant Meals: ${mealStats.noncompliantMealCount}\nAverage Water Intake: ${mealStats?.avgWaterIntake ? calculateLiters(mealStats.avgWaterIntake) : '0 liters'}\nAdherence Rate: ${mealStats.adherenceRate}%\n****************************************\n`;
        setInitNotify({ name, email, message: stats });
        setModalState(true);

    }

    return (
        <Fragment>
            <Header as='h2' content='Dashboard' dividing />
            <ItemGroup divided>
                {
                    dashboardData && dashboardData.length > 0 && dashboardData.map((client, index) => (
                        <Segment clearing raised color='red' key={index}>
                            <Label ribbon content={client.clientName} color='black' />
                            <Item>
                                <Item.Image size={isTabletOrMobileDevice ? 'tiny' : 'small'} src={client.imageUrl || '/assets/user.png'} floated='right' circular />
                                <Item.Content>
                                    <br />
                                    <Item.Meta>Email: {!isTabletOrMobileDevice ? <Link to={`/clients/${client.clientId}`}>{client.clientEmail}</Link> : client.clientEmail}</Item.Meta>
                                    {!isTabletOrMobileDevice && <Item.Meta>Following: <Link to={`/diet/${client.dietId}`}>{client.dietName}</Link></Item.Meta>}
                                    <Item.Meta>Start Date: <i>{client.dietStartDate && format(new Date(client.dietStartDate), 'MMM dd, yyyy')}</i></Item.Meta>
                                    <Item.Meta>End Date: <i>{client.dietEndDate && format(new Date(client.dietEndDate), 'MMM dd, yyyy')}</i></Item.Meta>
                                    <Item.Description>
                                        <ClientDietStats mealStatistics={client.mealStatistics} showAdherance={true} />
                                    </Item.Description>
                                </Item.Content>
                                <Item.Extra>
                                    <Divider />
                                    <Button primary floated='right' icon='mail' content='Notify' onClick={() => triggerNotify(client.clientName, client.clientEmail, client.mealStatistics)} />
                                </Item.Extra>
                            </Item>
                        </Segment>
                    ))
                }
            </ItemGroup>
            <Modal closeOnDimmerClick={false} closeOnEscape={false} open={modalState} size='small'>
                <Modal.Header content={`To: ${initNotify.name}`} />
                <Modal.Content>
                    <Modal.Description>
                        <FinalForm
                            initialValues={initNotify}
                            validate={validate}
                            onSubmit={(values) => agent.Client.notify(values).then(() => { alertify.success('Notification Sent'); setModalState(false); }).catch(error => ({
                                [FORM_ERROR]: error
                            }))}
                            render={({ handleSubmit, submitError, invalid, submitting, submitSucceeded }) => (
                                <Form onSubmit={handleSubmit} error autoComplete='off'>
                                    <Header as='h3' content='Enter your message' color='blue' />
                                    <Field
                                        name='message'
                                        component={TextAreaInput}
                                        placeholder='Enter message'
                                    />
                                    {submitError && <ErrorMessage error={submitError} text='Uh-oh!' />}
                                    <Button.Group floated='right' style={{ marginBottom: 10 }}>
                                        <Button content='Send' primary icon='mail' disabled={invalid || submitSucceeded} loading={submitting} />
                                        <Button content='Cancel' onClick={() => setModalState(false)} secondary type='button' />
                                    </Button.Group>
                                </Form>
                            )}
                        />
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </Fragment>
    )
}

export default AdminDashboard;
