import React, { useContext, Fragment } from 'react'
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/rootStore';
import DailyCalorieExpenditure from './DailyCalorieExpenditure';

const CalculatorsPage = () => {
    
    const rootStoreContext = useContext(RootStoreContext);
    const { userProfile, isUserClient } = rootStoreContext.userStore;

    return (
        <Fragment>
            <DailyCalorieExpenditure 
                dateOfBirth={userProfile.dateOfBirth!}
                heightInCms={userProfile.heightInCms!}
                weightInKgs={userProfile.currentWeightInKgs!} 
                gender={userProfile.gender!}
                isClient={isUserClient}
            />
        </Fragment>
    )
}

export default observer(CalculatorsPage);
