import { format } from "date-fns";

export enum Roles {
    ADMIN = 'Admin',
    TRAINER = 'Trainer',
    CLIENT = 'Client'
}

export const ordinal_suffix_of = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

export const displayFullDate = (date?: Date): string => {
    const tDate = date ? date : new Date();
    return `${format(tDate, 'eeee, dd MMM yyyy')}`
}

export const calculateLiters = (waterIntake: number): string => {
    return waterIntake! < 4 ? `${waterIntake! * 250} ml` : `${(waterIntake! * 250) / 1000} ${waterIntake! > 4 ? 'liters' : 'liter'}`
}

export const DEFAULT_TIME_ZONE: string = "America/Mexico_City";
export const MAX_DEVICE_WIDTH: number = 1224;
