import React, { useState } from 'react'
import { Button, Container, Header, Label, Segment } from 'semantic-ui-react'
import agent from '../../api/agent';
import { ErrorMessage } from '../../common/form/ErrorMessage';
import alertify from '../../common/util/alertify';
import { IPasswordReset } from '../../models/user.model';

interface IProps {
    name: string;
    email: string;
}

const AdminActionsPage: React.FC<IProps> = ({ name, email }) => {

    const [resetPassword, setResetPassword] = useState(false);
    const [emailConfirm, setEmailConfirm] = useState(false);
    const [submitError, setSubmitError] = useState(undefined as any);

    const passwordReset = () => {
        setSubmitError(undefined);
        setResetPassword(true);
        const resetPasswordRequest: IPasswordReset = { email, securityQuestionId: 99, answer: 'admin' };
        agent.User.resetPassword(resetPasswordRequest)
            .then(() => alertify.success('Password reset mail sent'))
            .catch((err) => setSubmitError(err))
            .finally(() => setResetPassword(false));
    }

    const confirmEmail = () => {
        setSubmitError(undefined);
        setEmailConfirm(true);
        agent.User.resendVerifyEmailConfirm(email)
            .then(() => alertify.success('Email confirmation link sent'))
            .catch((err) => setSubmitError(err))
            .finally(() => setEmailConfirm(false));
    }

    return (
        <Segment raised>
            <Label ribbon content='Actions' color='black' />
            <Header as='h3' content={name} subheader={email} dividing />
            <Container textAlign='center'>
                <Button
                    primary
                    content='Send Password Reset Link'
                    type='button'
                    icon='lock'
                    style={{ marginBottom: 5, width: 250 }}
                    loading={resetPassword}
                    onClick={passwordReset}
                />
                <Button
                    color='google plus'
                    content='Send Email Confirm Link'
                    icon='mail'
                    type='button'
                    style={{ marginBottom: 5, width: 250 }}
                    loading={emailConfirm}
                    onClick={confirmEmail}
                />
            </Container>
            {submitError && <ErrorMessage error={submitError} text='Alert!' />}
            

        </Segment>
    )
}

export default AdminActionsPage;
