import React, { useEffect } from 'react';
import { Segment, Button, Header, Icon } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';


const NotFound: React.FC<RouteComponentProps> = ({ history }) => {
    useEffect(() => {
        const path = history.location.pathname;

        if (path === '/jobs') {
            window.location.reload();
        }
    }, [history.location.pathname])

    return (
        <Segment placeholder>
            <Header icon>
                <Icon name='search' />
                Oops - we've looked everywhere but couldn't find this.
            </Header>
            <Segment.Inline>
                <Button onClick={history.goBack} primary content='Go Back' />
            </Segment.Inline>
        </Segment>
    );
};

export default NotFound;